import React,
{
  useEffect,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import {
  AuthenticateUserToken,
} from '../apis/endpoints/access-login.endpoints';

import AlertMessage from '../components/Alerts';
import Loader from '../components/Loader';

import {
  ETokenAsync,
} from '../shared/enums';

import {
  clearLocalStorage,
  setLocalStorageItem,
} from '../shared/utils/local-storage.utils';

import {
  RootState,
} from '../store';

import {
  APP_DRAWER_ACTION,
  APP_MODAL_LOADING_ACTION,
} from '../store/reducers/app.store';

import {
  COMPANY_LOGOUT_STATE,
  USER_LOGGED,
  USER_SET,
  USER_TOKEN,
} from '../store/reducers/user.store';

import DrawerRoutes from './routes/drawer.routes';
import ScreenRoutes from './routes/screens.routes';



function AppRoutes() {
  const dispatch = useDispatch();


  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get('token');


  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const logout = useSelector((state: RootState) => state.user.logout);

  const modalLoadingState = useSelector((state: RootState) => state.app.modalLoading);


  const ScreensApp = [
    ...DrawerRoutes,
    ...ScreenRoutes,
  ];


  function clearValues(): void {
    try {
      clearLocalStorage();

      dispatch({ type: USER_LOGGED, payload: false });
      dispatch({ type: COMPANY_LOGOUT_STATE, payload: false });
      dispatch({ type: USER_SET, payload: {} });
      dispatch({ type: APP_DRAWER_ACTION, payload: false });
    }
    catch (error: any) {
      console.error(error);
    }
  }


  function logoffAccount() {
    clearValues();

    console.log('Logoff');
  }


  async function setAuthenticateUser() {
    if (!token) {
      return;
    }

    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await AuthenticateUserToken({ token });

      if (response) {
        setLocalStorageItem(ETokenAsync.USER_ID, response?.user?.id);
        setLocalStorageItem(ETokenAsync.USER_TOKEN, response?.token);
        setLocalStorageItem(ETokenAsync.USER_DATA, JSON.stringify(response?.user));
        dispatch({ type: USER_LOGGED, payload: true });
        dispatch({ type: USER_SET, payload: response?.user });

        const tokenObj = { id: response?.user?.id, token: response?.token };
        dispatch({ type: USER_TOKEN, payload: tokenObj });
      }
    }
    catch (error: any) {
      console.error(error);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  useEffect(() => {
    setAuthenticateUser();
  }, []);



  return (

    <BrowserRouter>
      <Switch>

        {ScreensApp.map(
          ({ path, page: Page, exact, isPublic }, index) => {
            return isPublic
              ? (
                <Route
                  key={index}
                  path={path}
                  exact={exact}>

                  <Page />

                </Route>
              )
              : (
                <Route
                  key={index}
                  path={path}
                  exact={exact}
                  render={({ location }) => {
                    if (loggedIn) {
                      return <Page />;
                    }

                    // Redirect to Login Page
                    return (
                      <Redirect
                        to={{
                          pathname: '/',
                          state: { from: location },
                        }}
                      />
                    );
                  }}
                />
              );
          },
        )}

      </Switch>



      <AlertMessage
        visible={logout}
        title={'Deseja sair'}
        cancelText={'Não'}
        cancelPress={() => {
          dispatch({ type: COMPANY_LOGOUT_STATE, payload: false });
        }}
        okText={'Sim'}
        okPress={() => {
          logoffAccount();
        }}
      />



      <Loader
        visible={modalLoadingState}
      />

    </BrowserRouter>

  );
}



export default AppRoutes;
