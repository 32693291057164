import React from 'react';

// import NotFoundScreen from '../../screens/Errors/404';

import LoginScreen from '../../screens/Acesso/Login';

import RecoveryPasswordScreen from '../../screens/Acesso/Senha/Recuperar';
import PasswordChangeScreen from '../../screens/Acesso/Senha/Trocar';

import HistoricoEdicaoClientesScreen from '../../screens/Cadastros/Clientes/Alteracoes';
import AreasClientesScreen from '../../screens/Cadastros/Clientes/Areas';
import AreasClientesFormsScreen from '../../screens/Cadastros/Clientes/Areas/Forms';
import ContratosClientesScreen from '../../screens/Cadastros/Clientes/Contratos';
import ContratosClientesFormsScreen from '../../screens/Cadastros/Clientes/Contratos/Forms';
import ClientesFormsScreen from '../../screens/Cadastros/Clientes/Lista/Forms';

import MotivoCancelamentoFormsScreen from '../../screens/Cadastros/MotivosCancelamento/Forms';
import MotivoParadaFormsScreen from '../../screens/Cadastros/MotivosParada/Forms';
import MotivoRecobrancaFormScreen from '../../screens/Cadastros/MotivosRecobranca/Forms';

import MateriaisCategoriasFormsScreen from '../../screens/Materiais/Categorias/Forms';
import MateriaisFormsScreen from '../../screens/Materiais/Itens/Forms';

import ColaboradoresFormsScreen from '../../screens/Pessoas/Colaboradores/Forms';
import FuncoesColaboradoresFormsScreen from '../../screens/Pessoas/FuncoesColaboradores/Forms';
import PerfilAcessoFormScreen from '../../screens/Pessoas/PerfilsDeAcesso/Forms';
import UsersPeopleFormsScreen from '../../screens/Pessoas/Usuarios/Forms';

import StatusMessageScreen from '../../screens/Status';


import NameRoutes from '../names';



type Route = {
  path: string;
  exact?: boolean;
  isPublic?: boolean;
  page: React.FC;
};



const ScreenRoutes: Route[] = [

  {
    path: NameRoutes.LoginScreen,
    page: LoginScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.StatusMessageScreen,
    page: StatusMessageScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.RecoveryPasswordScreen,
    page: RecoveryPasswordScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.PasswordChangeScreen,
    page: PasswordChangeScreen,
    isPublic: true,
    exact: true,
  },



  // Pessoas

  {
    path: NameRoutes.UsersPeopleFormsScreen,
    page: UsersPeopleFormsScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.PerfilAcessoFormScreen,
    page: PerfilAcessoFormScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ColaboradoresFormsScreen,
    page: ColaboradoresFormsScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.FuncoesColaboradoresFormsScreen,
    page: FuncoesColaboradoresFormsScreen,
    isPublic: false,
    exact: true,
  },



  // Registros

  {
    path: NameRoutes.ClientesFormsScreen,
    page: ClientesFormsScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ContratosClientesScreen,
    page: ContratosClientesScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ContratosClientesFormsScreen,
    page: ContratosClientesFormsScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.AreasClientesScreen,
    page: AreasClientesScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.AreasClientesFormsScreen,
    page: AreasClientesFormsScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.HistoricoEdicaoClientesScreen,
    page: HistoricoEdicaoClientesScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.MotivoCancelamentoFormsScreen,
    page: MotivoCancelamentoFormsScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.MotivoParadaFormsScreen,
    page: MotivoParadaFormsScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.MotivoRecobrancaFormScreen,
    page: MotivoRecobrancaFormScreen,
    isPublic: false,
    exact: true,
  },



  // Materiais

  {
    path: NameRoutes.MateriaisFormsScreen,
    page: MateriaisFormsScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.MateriaisCategoriasFormsScreen,
    page: MateriaisCategoriasFormsScreen,
    isPublic: false,
    exact: true,
  },



  // // 404
  // {
  //   path: null,
  //   page: NotFoundScreen,
  //   isPublic: true,
  //   exact: false,
  // },
];



export default ScreenRoutes;
