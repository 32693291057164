import ApiWS from '../index';



export const AccessAppByToken = async (payload: any) => {
  const url = '/app/login';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const AuthenticateUserToken = async (data: any) => {
  const url = '/app/login/token';
  const response = await ApiWS.post(url, data);

  if (response.data) {
    const newToken = response.data.token;

    const userData = response.data.user;

    return {
      token: newToken,
      user: userData,
    };
  }
};



export const TryRevalidateSession = async (tokenReceived: string | null) => {
  if (tokenReceived) {
    const url = '/app/login/revalidate';

    const response = await ApiWS.post(url, {
      token: tokenReceived,
    });
    return response?.data;
  }
  else {
    return null;
  }
};
