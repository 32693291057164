export function clearLocalStorage(): any {
  try {
    localStorage?.clear();
  }
  catch (error) {
    console.error(error);
  }
}


export function setLocalStorageItem(itemName: string, item: any): void {
  try {
    if (!itemName || !item) {
      return;
    }

    const itemValue = JSON.stringify(item);
    localStorage?.setItem(itemName, itemValue);
  }
  catch (error: any) {
    console.error(error);

    localStorage?.clear();
  }
}


export function getLocalStorageItem(itemName: string): any {
  try {
    const itemValue = localStorage?.getItem(itemName) || '';

    if (!itemValue) {
      return '';
    }

    return JSON.parse(itemValue) || '';
  }
  catch (error: any) {
    console.error(error);

    localStorage?.clear();
  }
}


export function removeLocalStorageItem(itemName: string): any {
  try {
    localStorage?.removeItem(itemName);
  }
  catch (error: any) {
    console.error(error);

    localStorage?.clear();
  }
}
