import Yup from '../base-validation.yup';



const ColaboradoresFiltroValidation = Yup.object().shape({
  nome: Yup.string()
    .nullable()
    .min(3, `Precisa conter pelo menos ${3} caracteres`),

  cpf: Yup.string()
    .nullable()
    .min(3, `Precisa conter pelo menos ${3} caracteres`),

  cliente_empresa: Yup.string()
    .nullable(),

  funcao: Yup.string()
    .nullable(),

  status: Yup.boolean()
    .nullable(),
});



export default ColaboradoresFiltroValidation;
