import React from 'react';

import {
  Colors,
} from '../../shared/constants';

import {
  HeaderAppContainer,
  HeaderContainer,
  ToolbarContainer,
  CenterContainer,
} from './styled';



export interface IProps {
  absolute?: boolean;
  transparent?: boolean;

  backgroundColor?: string;
  borderBottomColor?: string;

  height?: number;

  leftIcon?: any | null;
  rightIcon?: any | null;
  centerContent?: any;

  style?: any;

  // children?: object;
}



const Toolbar: React.FC<IProps> = (props: IProps) => {
  const className = { ...props } as any;


  return (

    <HeaderAppContainer>
      <HeaderContainer
        className={className?.className}
        absolute={props.absolute}
        transparent={props.transparent}
        backgroundColor={props.backgroundColor || Colors.toolbarBackground}
        borderBottomColor={props.borderBottomColor}
        style={props.style}>

        <ToolbarContainer
          style={{
            backgroundColor: props.backgroundColor,
          }}>

          {props.leftIcon && props.leftIcon}

          <CenterContainer>
            {props.centerContent}
          </CenterContainer>

          {props.rightIcon && props.rightIcon}

        </ToolbarContainer>

      </HeaderContainer>

    </HeaderAppContainer>

  );
};



export default Toolbar;
