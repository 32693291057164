import ApiWS from '../index';



export const GetStopMotivesListAll = async (payload?: any) => {
  const url = '/app/cadastros/motivo-parada/buscar';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PostCreateStopMotives = async (payload: any) => {
  const url = '/app/cadastros/motivo-parada/criar';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PutEditStopMotives = async (idStopMotives: string, payload: any) => {
  const url = `/app/cadastros/motivo-parada/editar/${idStopMotives}`;

  const response = await ApiWS.put(url, payload);
  return response?.data;
};



export const DeleteStopMotives = async (idStopMotives: string) => {
  const url = `/app/cadastros/motivo-parada/deletar/${idStopMotives}`;

  const response = await ApiWS.delete(url);
  return response?.data;
};
