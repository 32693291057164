import React from 'react';

import {
  TableActionButton,
  TableActionText,
} from './styled';



export interface IProps {
  key?: any;
  disabled?: boolean;

  text?: any;
  color?: any;

  onPress?: any;
}



const ButtonTableText: React.FC<IProps> = (props) => {
  return (

    <TableActionButton
      color={props.color}
      onClick={props.onPress}>

      <TableActionText
        color={props.color}>
        {props.text}
      </TableActionText>

    </TableActionButton>

  );
};



export default ButtonTableText;
