const environment = {
  production: false,
  environment: 'DEV',
  title: 'Dev Environment Heading',
  apiUrl: 'http://10.0.0.21:5002',
};



export default environment;
