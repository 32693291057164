import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  GetAllInputClients,
} from '../../../apis/endpoints/clientes.endpoints';

import {
  DeleteCollabs,
  GetCollabsListAll,
} from '../../../apis/endpoints/pessoas-colaboradores.endpoints';

import {
  GetAllInputFuncoesColaboradores,
} from '../../../apis/endpoints/pessoas-funcoes-colaboradores.endpoints';

import AlertMessage from '../../../components/Alerts';
import ButtonTableIcon from '../../../components/Buttons/ButtonTableIcon';
import Content from '../../../components/Content';

import {
  ContentPadder,
} from '../../../components/ContentPadder';

import EmptyContent from '../../../components/Empty';

import {
  FormListInputsView,
  FormFiltersButtonView,
} from '../../../components/Filter/filter-content';

import {
  IconAdd,
  IconEdit,
  IconErase,
  IconFilter,
  IconDelete,
} from '../../../components/Icons';

import InputDropdown from '../../../components/Inputs/InputDropdown';
import InputText from '../../../components/Inputs/InputText';

import {
  IToast,
} from '../../../components/IToast';

import LoadingProgress from '../../../components/Loadings';
import Navegar from '../../../components/Navegar';
import Screen from '../../../components/Screen';
import TableCuston from '../../../components/Table';
import Title from '../../../components/Title';
import Toolbar from '../../../components/Toolbar';

import NameRoutes from '../../../navigation/names';

import {
  AllItems,
  listStatus,
} from '../../../shared/arrays';

import {
  Colors,
  Images,
  Messages,
  Sizes,
  Values,
} from '../../../shared/constants';

import {
  EDrawerMenu,
  EHttpStatusCode,
} from '../../../shared/enums';

import {
  ILightClientes,
  IColaboradores,
  IDropdownItem,
  IFuncoesColaboradores,
  IResponse,
} from '../../../shared/interfaces';

import ColaboradoresFiltroModel from '../../../shared/models/Colaboradores/colaboradores-filtro.model';

import {
  returnErrorObject,
} from '../../../shared/utils/error.utils';

import {
  validateString,
} from '../../../shared/utils/string.utils';

import ColaboradoresFiltroValidation from '../../../shared/validations/Colaboradores/colaboradores-filtro.validations';

import {
  APP_MENU_SELECTED_ACTION,
  APP_MODAL_LOADING_ACTION,
} from '../../../store/reducers/app.store';

import {
  ActionButtonApp,
  FilterButtonApp,
} from './styled';



const ColaboradoresScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  const formik = useRef<FormikProps<typeof ColaboradoresFiltroModel | null>>(null);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Nenhum colaborador aqui!',
    description: 'Não há nenhum colaborador aqui',
  });

  const fullStatusArray = [
    AllItems,
    ...listStatus,
  ];

  const [statusInput, setStatusInput] = useState<IDropdownItem | null>(fullStatusArray[0]);

  const [listFuncoes, setListFuncoes] = useState<Array<IDropdownItem> | null>(null);
  const [funcaoInput, setFuncaoInput] = useState<IDropdownItem | null>(null);

  const [listEmpresas, setListEmpresas] = useState<Array<IDropdownItem> | null>(null);
  const [empresaInput, setEmpresaInput] = useState<IDropdownItem | null>(null);

  const [arrayDataList, setArrayDataList] = useState<Array<IColaboradores>>([]);

  const [openDeleteModal, setOpenDeleteModal] = useState<IColaboradores | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [mouted, setMounted] = useState<boolean>(false);



  async function getClientsFunction() {
    try {
      const response: IResponse = await GetAllInputClients();
      const responseData: ILightClientes = response?.data;

      if (!responseData || !Array.isArray(responseData)) {
        setListEmpresas(null);
        setEmpresaInput(null);
        formik.current?.setFieldValue('clienteEmpresa', null);
        return;
      }

      const newResponseData: Array<IDropdownItem> = responseData?.map((item: ILightClientes) => {
        return {
          key: String(item?.id),
          label: item?.nomeFantasia,
          value: item?.id,
        };
      });

      const newData: Array<IDropdownItem> = [
        AllItems,
        ...newResponseData,
      ];

      setListEmpresas(newData);

      if (newData?.length > 0) {
        setEmpresaInput(newData[0]);
        formik.current?.setFieldValue('clienteEmpresa', newData[0]?.value);
      }
      else {
        setListEmpresas(null);
        setEmpresaInput(null);
        formik.current?.setFieldValue('clienteEmpresa', null);
      }
    }
    catch (error: any) {
      console.error(error);

      setListEmpresas(null);
      setEmpresaInput(null);
      formik.current?.setFieldValue('clienteEmpresa', null);
    }
  };



  async function getFuncoesColabsFunction() {
    try {
      const response: IResponse = await GetAllInputFuncoesColaboradores();
      const responseData: IFuncoesColaboradores = response?.data;

      if (!responseData || !Array.isArray(responseData)) {
        setListFuncoes(null);
        setFuncaoInput(null);
        formik.current?.setFieldValue('funcao', null);
        return;
      }

      const newResponseData: Array<IDropdownItem> = responseData?.map((item: IFuncoesColaboradores) => {
        return {
          key: String(item?.id),
          label: item?.nome,
          value: item?.id,
        };
      });

      const newData: Array<IDropdownItem> = [
        AllItems,
        ...newResponseData,
      ];

      setListFuncoes(newData);

      if (newData?.length > 0) {
        setFuncaoInput(newData[0]);
        formik.current?.setFieldValue('funcao', newData[0]?.value);
      }
      else {
        setListFuncoes(null);
        setFuncaoInput(null);
        formik.current?.setFieldValue('funcao', null);
      }
    }
    catch (error: any) {
      console.error(error);

      setListFuncoes(null);
      setFuncaoInput(null);
      formik.current?.setFieldValue('funcao', null);
    }
  };


  async function getListAllFunction(payloadReceived?: typeof ColaboradoresFiltroModel, openLoadingModal = false) {
    let payload: typeof ColaboradoresFiltroModel | undefined;

    if (payloadReceived) {
      payload = {
        nome: validateString(payloadReceived?.nome) ? payloadReceived?.nome : null,
        cpf: validateString(payloadReceived?.cpf) ? payloadReceived?.cpf : null,
        clienteEmpresa: payloadReceived?.clienteEmpresa,
        funcao: payloadReceived?.funcao,
        isAtivo: payloadReceived?.isAtivo,
      };
    }

    try {
      setLoading(true);

      if (openLoadingModal) {
        dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });
      }

      const response: IResponse = await GetCollabsListAll(payload);
      const responseData: IColaboradores = response?.data;

      if (!responseData || !Array.isArray(responseData)) {
        setArrayDataList([]);
        return;
      }

      setArrayDataList(responseData);
    }
    catch (error: any) {
      console.error(error);
      setArrayDataList([]);

      setEmptyObject(returnErrorObject(error?.response?.status));
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  };


  async function getFirstTimeFunction() {
    await getClientsFunction();
    await getFuncoesColabsFunction();
    await getListAllFunction();
    setMounted(true);
  };


  async function postFilter(values: typeof ColaboradoresFiltroModel) {
    await getListAllFunction(values, true);
  };


  async function deleteItemSelected() {
    setOpenDeleteModal(null);

    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response: IResponse = await DeleteCollabs(String(openDeleteModal));

      if (response?.status !== EHttpStatusCode.OK) {
        console.error('Error', response);
        IToast({
          type: 'error',
          message: 'Erro ao deletar item',
        });
        return;
      }

      IToast({
        type: 'success',
        message: 'Item deletado com sucesso!',
      });
      await getListAllFunction();
    }
    catch (error: any) {
      console.error(error);

      IToast({
        type: 'error',
        message: 'Erro interno',
      });
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function resetForm() {
    formik.current?.resetForm();

    formik.current?.setFieldValue('clienteEmpresa', listEmpresas?.length > 0 ? listEmpresas[0]?.value : null);
    setEmpresaInput(listEmpresas[0]);

    formik.current?.setFieldValue('funcao', listFuncoes?.length > 0 ? listFuncoes[0]?.value : null);
    setFuncaoInput(listFuncoes[0]);

    formik.current?.setFieldValue('isAtivo', fullStatusArray[0]?.value);
    setStatusInput(fullStatusArray[0]);
  };


  function renderLoading() {
    return (

      <LoadingProgress
        type={'SCREEN'}
      />

    );
  }



  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      formatter: (cell: string, _row: IColaboradores) => {
        return cell || '-';
      },
    },
    {
      dataField: 'nome',
      text: 'Nome',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      formatter: (cell: string, _row: IColaboradores) => {
        return cell || '-';
      },
    },
    {
      dataField: 'cpf',
      text: 'CPF',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      formatter: (cell: string, _row: IColaboradores) => {
        return cell || '-';
      },
    },
    {
      dataField: 'telefone',
      text: 'Telefone',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      formatter: (cell: string, _row: IColaboradores) => {
        return cell || '-';
      },
    },
    {
      dataField: 'funcao',
      text: 'Função',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      formatter: (_cell: string, row: IColaboradores) => {
        return row?.funcao?.nome || '-';
      },
    },
    {
      dataField: 'clienteEmpresa',
      text: 'Empresa Cliente',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      formatter: (_cell: string, row: IColaboradores) => {
        return row?.clienteEmpresa?.nomeFantasia || '-';
      },
    },
    {
      dataField: 'isAtivo',
      text: 'Ativo',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      formatter: (cell: number) => {
        return cell ? 'Sim' : 'Não';
      },
    },
    {
      dataField: 'EDIT',
      text: 'Editar',
      csvExport: false,
      headerStyle: () => {
        return { width: '5%' };
      },
      formatter: (_cell: null, row: IColaboradores, index: number): JSX.Element => {
        return (
          <ButtonTableIcon
            key={index}
            tooltip={Messages.EDIT}
            icon={
              <IconEdit
                color={Colors.black}
                size={16}
              />
            }
            onPress={() => {
              history.push({
                pathname: NameRoutes.ColaboradoresFormsScreen,
                state: {
                  edit: true,
                  item: row,
                },
              });
            }}
          />
        );
      },
    },
    {
      dataField: 'REMOVE',
      text: 'Remover',
      csvExport: false,
      headerStyle: () => {
        return { width: '5%' };
      },
      formatter: (_cell: null, row: IColaboradores, index: number): JSX.Element => {
        return (
          <ButtonTableIcon
            key={index}
            tooltip={Messages.REMOVE}
            icon={
              <IconDelete
                color={Colors.danger}
                size={16}
              />
            }
            onPress={() => {
              setOpenDeleteModal(row);
            }}
          />
        );
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: arrayDataList?.length,
  };



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.REGISTER_COLLABORATOR });
    getFirstTimeFunction();
  }, []);



  return (

    <Formik
      enableReinitialize
      validateOnMount
      innerRef={formik}
      initialValues={ColaboradoresFiltroModel}
      validationSchema={ColaboradoresFiltroValidation}
      onSubmit={(values) => {
        postFilter(values);
      }}>
      {({
        errors,
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (

        <Screen>

          <Navegar>

            <Toolbar
              centerContent={
                <Title
                  color={Colors.tertiary}>
                  Colaboradores
                </Title>
              }
              rightIcon={
                <ActionButtonApp
                  title={'Novo colaborador'}
                  backgroundColor={Colors.primaryMedium}
                  iconLeft={
                    <IconAdd
                      color={Colors.white}
                      size={Sizes.iconSize}
                    />
                  }
                  onPress={() => {
                    history.push({
                      pathname: NameRoutes.ColaboradoresFormsScreen,
                    });
                  }}
                />
              }
            />



            {!mouted && renderLoading()}



            {mouted && (
              <Content>
                <ContentPadder>

                  <FormListInputsView
                    auto={true}>

                    <Row>

                      <Col xs={3}>
                        <InputText
                          disabled={loading}
                          autoCorrect
                          autoCapitalize={'words'}
                          type={'TEXT'}
                          labelText={'Nome'}
                          placeholderText={'Nome'}
                          borderColor={errors.nome ? Colors.danger : null}
                          helpText={errors.nome}
                          countLimit={Values.nameItem}
                          value={values.nome}
                          onChange={handleChange('nome')}
                          onBlur={handleBlur('nome')}
                        />
                      </Col>


                      <Col xs={2}>
                        <InputText
                          disabled={loading}
                          type={'CPF'}
                          labelText={'CPF'}
                          placeholderText={'CPF'}
                          helpText={errors.cpf}
                          countLimit={Values.cpfCount}
                          value={values.cpf}
                          onChange={(rawText: string) => {
                            setFieldValue('cpf', rawText);
                          }}
                          onBlur={handleBlur('cpf')}
                        />
                      </Col>


                      <Col xs={2}>
                        <InputDropdown
                          dropArray={listFuncoes}
                          disabled={loading}
                          labelText={'Função'}
                          placeholderText={funcaoInput?.label}
                          value={funcaoInput?.value}
                          onChange={(item: IDropdownItem) => {
                            setFieldValue('funcao', item?.value);
                            setFuncaoInput(item);
                          }}
                          onBlur={handleBlur('funcao')}
                        />
                      </Col>


                      <Col xs={3}>
                        <InputDropdown
                          dropArray={listEmpresas}
                          disabled={loading}
                          labelText={'Cliente Empresa'}
                          placeholderText={empresaInput?.label}
                          value={empresaInput?.value}
                          onChange={(item: IDropdownItem) => {
                            setFieldValue('clienteEmpresa', item?.value);
                            setEmpresaInput(item);
                          }}
                          onBlur={handleBlur('clienteEmpresa')}
                        />
                      </Col>


                      <Col xs={2}>
                        <InputDropdown
                          dropArray={fullStatusArray}
                          disabled={loading}
                          labelText={'Status'}
                          placeholderText={statusInput?.label}
                          value={statusInput?.value}
                          onChange={(item: IDropdownItem) => {
                            setFieldValue('isAtivo', item?.value);
                            setStatusInput(item);
                          }}
                          onBlur={handleBlur('isAtivo')}
                        />
                      </Col>



                      <FormFiltersButtonView>

                        <FilterButtonApp
                          outline
                          disabled={loading}
                          iconLeft={
                            <IconErase
                              color={Colors.primaryMedium}
                              size={16}
                            />
                          }
                          title={'Limpar'}
                          backgroundColor={Colors.primaryMedium}
                          onPress={() => {
                            resetForm();
                          }}
                        />


                        <FilterButtonApp
                          disabled={loading}
                          iconLeft={
                            <IconFilter
                              color={Colors.white}
                              size={16}
                            />
                          }
                          title={'Filtrar'}
                          backgroundColor={Colors.primaryMedium}
                          onPress={() => {
                            handleSubmit();
                          }}
                        />

                      </FormFiltersButtonView>

                    </Row>

                  </FormListInputsView>



                  {arrayDataList?.length === 0 && (
                    <EmptyContent
                      image={emptyObject.image}
                      title={emptyObject.title}
                      description={emptyObject.description}
                    />
                  )}



                  {arrayDataList?.length > 0 && (
                    <PaginationProvider
                      pagination={paginationFactory(options)}>
                      {({
                        paginationProps,
                        paginationTableProps,
                      }) => (
                        <TableCuston
                          data={arrayDataList}
                          columns={columns}
                          paginationProps={paginationProps}
                          paginationTableProps={paginationTableProps}
                        />
                      )}
                    </PaginationProvider>
                  )}

                </ContentPadder>
              </Content>
            )}

          </Navegar>



          <AlertMessage
            visible={Boolean(openDeleteModal)}
            title={'Deseja deletar esse item?'}
            cancelText={'Não'}
            cancelPress={() => {
              setOpenDeleteModal(null);
            }}
            okText={'Sim'}
            okPress={() => {
              deleteItemSelected();
            }}
          />

        </Screen>

      )}

    </Formik>

  );
};



export default ColaboradoresScreen;
