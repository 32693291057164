import React,
{
  useState,
} from 'react';

import {
  Colors,
} from '../../../shared/constants';

import {
  IDropdownItem,
} from '../../../shared/interfaces';

import {
  IconArrowDown,
} from '../../Icons';

import InputHelp from '../InputHelp';
import InputLabel from '../InputLabel';

import {
  InputDrowpdownMaster,
  DropdownSelect,
  DropdownTextStyle,
  InputsContainer,
  InputViewStyle,
  OptionStyled,
  OptionTextStyled,
  SelectArrowView,
  DropdownMenu,
  DropdownToggle,
  DropdownTextToggle,
} from './styled';



export interface IProps {
  disabled?: boolean;
  noShadow?: boolean;
  password?: boolean;

  dropArray: Array<IDropdownItem>;
  selected?: any;

  autoCapitalize?: any;
  autoCorrect?: boolean;
  noWrap?: boolean;
  backgroundColor?: string;
  borderColor?: string | null;
  borderWidth?: number;

  // Props Icon
  iconLeft?: any;
  iconRight?: any;

  iconPasswordOn?: any;
  iconPasswordOff?: any;

  // Placeholder
  placeholderText?: string;
  placeholderTextColor?: string;

  inputTextCenter?: boolean;
  inputTextColor?: string;

  labelText?: string;
  labelColor?: string;

  value?: any;

  noHelp?: boolean;
  helpText?: any;
  helpColor?: string;

  // Props Count Letter
  noCount?: boolean;
  countLimit?: any; // string | number;
  countColor?: string;

  onChange?: any; // onChange?(event: any): any;
  onBlur?: any; // onBlur?(event: any): any;

  lowercase?: boolean;
  children?: any;
  multiline?: boolean;

  style?: any;
}



const InputDropdown: React.FC<IProps> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const opacityValue = props.disabled ? 0.5 : 1;


  // Toggle the dropdown open/close state
  const toggleDropdown = () => {
    if (!props.disabled) {
      setIsOpen(!isOpen);
    }
  };


  // Handle selection of a dropdown option
  const handleSelect = (dropItem: any) => {
    setIsOpen(false);
    if (props.onChange) {
      props.onChange(dropItem);
    }
  };



  return (

    <InputDrowpdownMaster>


      <InputsContainer
        className={props.style?.className}>

        {props.labelText && (
          <InputLabel
            labelText={props.labelText}
            labelStyle={{
              color: props.labelColor,
              opacity: opacityValue,
            }}
          />
        )}



        <InputViewStyle
          noShadow={props.noShadow}

          backgroundColor={props.backgroundColor}
          borderColor={props.borderColor}
          borderWidth={props.borderWidth}
          opacity={opacityValue}>

          {(!props.dropArray || !Array.isArray(props.dropArray) || props.dropArray?.length <= 0) && (
            <DropdownTextStyle>
              Sem opções a escolher
            </DropdownTextStyle>
          )}


          {props.dropArray && Array.isArray(props.dropArray) && props.dropArray?.length > 0 && (
            <DropdownSelect>

              <DropdownToggle
                onClick={() => {
                  toggleDropdown();
                }}>
                <DropdownTextToggle
                  color={props.placeholderText ? Colors.black : Colors.textColor}>
                  {props.placeholderText || 'Escolha um item'}
                </DropdownTextToggle>
              </DropdownToggle>

            </DropdownSelect>
          )}


          <SelectArrowView
            expanded={isOpen}>
            <IconArrowDown
              color={Colors.textColor}
              size={28}
            />
          </SelectArrowView>

        </InputViewStyle>



        <InputHelp
          helpText={props.helpText}
          helpTextStyle={{
            color: props.helpColor,
            opacity: opacityValue,
          }}

          countLimit={'Clique para alterar'}
          helpCountStyle={{
            color: props.countColor,
            opacity: opacityValue,
          }}
        />

      </InputsContainer>



      {isOpen && (
        <DropdownMenu>

          {props.dropArray?.map((dropItem: IDropdownItem, index: number) => (
            <OptionStyled
              key={`dropdown-option-${index}`}
              selected={props.value === dropItem?.value}
              onClick={() => {
                handleSelect(dropItem);
              }}>

              <OptionTextStyled>
                {dropItem?.label || dropItem?.value}
              </OptionTextStyled>

            </OptionStyled>
          ))}

        </DropdownMenu>
      )}

    </InputDrowpdownMaster>

  );
};



InputDropdown.defaultProps = {
  autoCorrect: false,
  noShadow: false,

  autoCapitalize: 'none',
  backgroundColor: 'white',
  inputTextColor: 'black',
  borderWidth: 1,
};



export default InputDropdown;
