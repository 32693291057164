const environment = {
  production: true,
  environment: 'PROD',
  title: 'Production Environment Heading',
  apiUrl: 'http://10.0.0.21:5000',
};



export default environment;
