import Yup from '../base-validation.yup';



const PerfilAcessoFormValidation = Yup.object().shape({
  name: Yup.string()
    .required('Campo obrigatório')
    .min(3, `Precisa conter pelo menos ${3} caracteres`),
});



export default PerfilAcessoFormValidation;
