import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useLocation,
} from 'react-router-dom';

import {
  GetClientesHistoricoListAll,
} from '../../../../apis/endpoints/clientes-historico.endpoints';

import Body from '../../../../components/Body';
import Content from '../../../../components/Content';

import {
  ContentPadder,
} from '../../../../components/ContentPadder';

import {
  ButtonBack,
} from '../../../../components/Controls';

import EmptyContent from '../../../../components/Empty';

import {
  FormListInputsView,
} from '../../../../components/Filter/filter-content';

import LoadingProgress from '../../../../components/Loadings';
import Screen from '../../../../components/Screen';
import SubTitle from '../../../../components/SubTitle';
import Title from '../../../../components/Title';
import Toolbar from '../../../../components/Toolbar';

import {
  Colors,
  Images,
} from '../../../../shared/constants';

import {
  IClientes,
  IResponse,
  IRetornoClientesHistorico,
} from '../../../../shared/interfaces';

import {
  returnErrorObject,
} from '../../../../shared/utils/error.utils';



export interface IRouteLocation {
  item: IClientes;
}



const HistoricoEdicaoClientesScreen: React.FC = () => {
  const routeLocation = useLocation<IRouteLocation>();
  const params = routeLocation?.state;


  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Nenhum dado aqui!',
    description: 'Não há nenhum dado aqui',
  });

  const [titleScreen, setTitleScreen] = useState<string>(params?.item?.nomeFantasia || '-');

  const [arrayDataList, setArrayDataList] = useState<Array<any>>([]);

  const [mouted, setMounted] = useState<boolean>(false);



  async function getFirstTimeFunction() {
    setMounted(false);

    try {
      const response: IResponse = await GetClientesHistoricoListAll();
      const responseData: IRetornoClientesHistorico = response?.data;

      if (!responseData || !Array.isArray(responseData)) {
        setArrayDataList([]);
        return;
      }

      setArrayDataList(responseData?.dados);
      setTitleScreen(responseData?.clienteEmpresa?.nomeFantasia);
    }
    catch (error: any) {
      console.error(error);
      setArrayDataList([]);

      setEmptyObject(returnErrorObject(error?.response?.status));
    }
    finally {
      setMounted(true);
    }
  };


  function renderLoading() {
    return (

      <LoadingProgress
        type={'SCREEN'}
      />

    );
  }



  useEffect(() => {
    getFirstTimeFunction();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Toolbar
        backgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.black}
            size={20}
          />
        }
        centerContent={
          <Body>
            <Title
              color={Colors.tertiary}>
              {titleScreen}
            </Title>

            <SubTitle
              color={Colors.tertiary}>
              Histórico de alterações
            </SubTitle>
          </Body>
        }
      />



      {!mouted && renderLoading()}



      {mouted && arrayDataList?.length === 0 && (
        <EmptyContent
          image={emptyObject.image}
          title={emptyObject.title}
          description={emptyObject.description}
        />
      )}



      {mouted && (
        <Content>
          <ContentPadder>

            <FormListInputsView
              auto={true}>

              <table className="table">
                <thead>
                  <tr>
                    <th colSpan={5} className="head__item--parametro"><h3>- Parâmetro: Recobrança - Multiplicador para Metro Cúbico</h3></th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>Data da Atualização</th>
                    <th>Valor Inserido</th>
                    <th>Nome do Usuário</th>
                    <th>Perfil do Usuário</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>25/07/2023 10:45:30</td>
                    <td>11</td>
                    <td>João Tocantins</td>
                    <td>
                      Usuário ID #30
                    </td>
                  </tr>
                  <tr>
                    <td>16/05/2023 10:20:54</td>
                    <td>0</td>
                    <td>Antonio Gomes</td>
                    <td>
                      Usuário ID #120
                    </td>
                  </tr>
                </tbody>
              </table>



              <table className="table">
                <thead>
                  <tr>
                    <th colSpan={5} className="head__item--parametro"><h3>- Parâmetro: Recobrança - Multiplicador para Metro Linear</h3></th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>Data da Atualização</th>
                    <th>Valor Inserido</th>
                    <th>Nome do Usuário</th>
                    <th>Perfil do Usuário</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>25/07/2023 10:45:30</td>
                    <td>1</td>
                    <td>João Tocantins</td>
                    <td>
                      Usuário ID #30
                    </td>
                  </tr>
                  <tr>
                    <td>16/05/2023 10:20:54</td>
                    <td>0</td>
                    <td>Antonio Gomes</td>
                    <td>
                      Usuário ID #120
                    </td>
                  </tr>
                </tbody>
              </table>



              <table className="table">
                <thead>
                  <tr>
                    <th colSpan={5} className="head__item--parametro"><h3>- Parâmetro: Recobrança - E-mails Destinatários para Notificações sobre Recobranças</h3></th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>Data da Atualização</th>
                    <th>Valor Inserido</th>
                    <th>Nome do Usuário</th>
                    <th>Perfil do Usuário</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>28/09/2023 18:16:22</td>
                    <td>-</td>
                    <td>Automático pelo Sistema</td>
                    <td>
                      -
                    </td>
                  </tr>
                </tbody>
              </table>



              <table className="table">
                <thead>
                  <tr>
                    <th colSpan={5} className="head__item--parametro"><h3>- Parâmetro: Recobrança - Total de Dias antes do Vencimento de Recobrança para Notificar com Lembrete</h3></th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>Data da Atualização</th>
                    <th>Valor Inserido</th>
                    <th>Nome do Usuário</th>
                    <th>Perfil do Usuário</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>28/09/2023 18:16:22</td>
                    <td>5</td>
                    <td>Automático pelo Sistema</td>
                    <td>
                      -
                    </td>
                  </tr>
                </tbody>
              </table>



              <table className="table">
                <thead>
                  <tr>
                    <th colSpan={5} className="head__item--parametro"><h3>- Parâmetro: AQS - E-mails Destinatários para Notificações sobre Gerar AQS</h3></th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>Data da Atualização</th>
                    <th>Valor Inserido</th>
                    <th>Nome do Usuário</th>
                    <th>Perfil do Usuário</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>10/11/2023 13:32:37</td>
                    <td>-</td>
                    <td>Automático pelo Sistema</td>
                    <td>
                      -
                    </td>
                  </tr>
                </tbody>
              </table>

            </FormListInputsView>

          </ContentPadder>
        </Content>
      )}

    </Screen>

  );
};



export default HistoricoEdicaoClientesScreen;
