import ApiWS from '../index';



export const GetAllInputClients = async (payload?: any) => {
  const url = '/app/cadastros/clientes/listar-tudo';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const GetClientsListAll = async (payload?: any) => {
  const url = '/app/cadastros/clientes/buscar';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PostCreateClients = async (payload: any) => {
  const url = '/app/cadastros/clientes/criar';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PutEditClients = async (idClients: string, payload: any) => {
  const url = `/app/cadastros/clientes/editar/${idClients}`;

  const response = await ApiWS.put(url, payload);
  return response?.data;
};



export const DeleteClients = async (idClients: string) => {
  const url = `/app/cadastros/clientes/deletar/${idClients}`;

  const response = await ApiWS.delete(url);
  return response?.data;
};
