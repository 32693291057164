import styled from 'styled-components';

import {
  Colors,
} from '../../shared/constants';



export interface IProps {
  color?: string;
}



const SubTitle = styled.h2`
  margin-top: 3px;
  margin-bottom: 0;
  color: ${(props: IProps) => props.color || Colors.gray};
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
`;



export default SubTitle;
