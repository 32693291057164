import React from 'react';

import {
  LoginFooterContent,
  LoginFooterText,
  LoginFooterTermButton,
} from './styled';



export interface IProps {
  textColor?: string;
}



const FooterAccess: React.FC<IProps> = (props: IProps) => {
  return (

    <LoginFooterContent>

      <LoginFooterText
        color={props.textColor}>
        © KW Brasil. Todos os direitos reservados
        {' '}
        {'|'}
        {' '}
        <LoginFooterTermButton
          color={props.textColor}
          target='_blank'
          href="https://app.kwbrasil.com.br/politicas-de-privacidade">
          Políticas de privacidade
        </LoginFooterTermButton>
      </LoginFooterText>

    </LoginFooterContent>

  );
};



export default FooterAccess;
