import ApiWS from '../index';



export const GetAllInputCategoryMaterials = async (payload?: any) => {
  const url = '/app/materiais-categorias/listar-tudo';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const GetCategoryMaterialsListAll = async (payload?: any) => {
  const url = '/app/materiais-categorias/buscar';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PostCreateCategoryMaterials = async (payload: any) => {
  const url = '/app/materiais-categorias/criar';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PutEditCategoryMaterials = async (idCategoryMaterials: string, payload: any) => {
  const url = `/app/materiais-categorias/editar/${idCategoryMaterials}`;

  const response = await ApiWS.put(url, payload);
  return response?.data;
};



export const DeleteCategoryMaterials = async (idCategoryMaterials: string) => {
  const url = `/app/materiais-categorias/deletar/${idCategoryMaterials}`;

  const response = await ApiWS.delete(url);
  return response?.data;
};
