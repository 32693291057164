export const DRAWER_ROUTES = {
  // Home
  HomeScreen:                       '/inicio',
  MinhaContaScreen:                 '/minha-conta',

  // Andaimes
  AndaimesMontadosScreen:           '/andaimes-montados',

  // Projetos
  ProjetosScreen:                   '/projetos',
  ProjetosMapaScreen:               '/projetos-mapa',
  ProjetosExportsScreen:            '/projetos-exports',

  // Pessoas
  PeopleUsersScreen:                '/usuario',
  ProfileAccessScreen:              '/perfis-acesso',
  ColaboradoresScreen:              '/colaboradores',
  FuncoesColaboradoresScreen:       '/funcoes-colaboradores',

  // Cadastros
  ClientesScreen:                   '/clientes',
  MotivoCancelamentoScreen:         '/motivos-cancelamento',
  MotivoParadaScreen:               '/motivos-parada',
  MotivoRecobrancaScreen:           '/motivos-recobranca',

  // Materiais
  MateriaisScreen:                  '/materiais',
  MateriaisCategoriasScreen:        '/categorias-materiais',
};


export const APP_ROUTES = {
  LoginScreen:                      '/',

  StatusMessageScreen:              '/status',

  RecoveryPasswordScreen:           '/senha/recuperar',
  PasswordChangeScreen:             '/senha/resetar',

  UsersPeopleFormsScreen:           '/usuario/formulario',
  PerfilAcessoFormScreen:           '/perfis-acesso/form',
  ColaboradoresFormsScreen:         '/colaboradores/formulario',
  FuncoesColaboradoresFormsScreen:  '/funcoes-colaboradores/formulario',

  ClientesFormsScreen:              '/clientes/formulario',
  ContratosClientesScreen:          '/clientes/:id_cliente/contratos',
  ContratosClientesFormsScreen:     '/clientes/:id_cliente/contratos/formulario',
  AreasClientesScreen:              '/clientes/:id_cliente/areas',
  AreasClientesFormsScreen:         '/clientes/:id_cliente/areas/formulario',
  HistoricoEdicaoClientesScreen:    '/clientes/:id_cliente/alteracoes',

  MotivoCancelamentoFormsScreen:    '/motivos-cancelamento/formulario',
  MotivoParadaFormsScreen:          '/motivos-parada/formulario',
  MotivoRecobrancaFormScreen:       '/motivos-recobranca/formulario',

  MateriaisFormsScreen:             '/materiais/formulario',
  MateriaisCategoriasFormsScreen:   '/categorias-materiais/formulario',
};



const NameRoutes = {
  ...DRAWER_ROUTES,
  ...APP_ROUTES,
};



export default NameRoutes;
