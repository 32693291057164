export enum EDrawerMenu {
  // Início
  HOME = 'HOME',
  MY_ACCOUNT = 'MY_ACCOUNT',

  // Andaimes
  SCAFF_ASSEMBLED = 'SCAFF_ASSEMBLED',

  // Projetos
  PROJECT_MANAGE = 'PROJECT_MANAGE',
  PROJECT_MAP = 'PROJECT_MAP',
  PROJECT_EXPORT = 'PROJECT_EXPORT',

  // Pessoas
  PEOPLE_USERS = 'PEOPLE_USERS',
  ACCESS_PROFILES = 'ACCESS_PROFILES',
  REGISTER_COLLABORATOR = 'REGISTER_COLLABORATOR',
  FUNCTIONS_COLLABORATOR = 'FUNCTIONS_COLLABORATOR',

  // Cadastros
  REGISTER_CUSTOMER = 'REGISTER_CUSTOMER',
  REGISTER_REASON_CANCEL = 'REGISTER_REASON_CANCEL',
  REGISTER_REASON_STOP = 'REGISTER_REASON_STOP',
  REGISTER_REASON_RECOVERY = 'REGISTER_REASON_RECOVERY',

  // Materiais
  MATERIALS = 'MATERIALS',
  MATERIALS_CATEGORIES = 'MATERIALS_CATEGORIES',

  EXIT = 'EXIT',
}



export default EDrawerMenu;
