import Yup from '../base-validation.yup';



const ColaboradoresFormValidation = Yup.object().shape({
  nome: Yup.string()
    .required('Campo obrigatório')
    .min(3, `Precisa conter pelo menos ${3} caracteres`),


  cpf: Yup.string()
    .required('Campo obrigatório'),


  telefone: Yup.string()
    .nullable(),


  clienteEmpresa: Yup.string()
    .nullable()
    .required('Campo obrigatório'),


  funcao: Yup.string()
    .nullable()
    .required('Campo obrigatório'),


  status: Yup.boolean()
    .nullable(),
});



export default ColaboradoresFormValidation;
