import styled from 'styled-components';

import {
  Sizes,
} from '../../shared/constants';

import {
  PlatformDevices,
} from '../../shared/utils/platforms.utils';



export const ContentPadder = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding-top: ${Sizes.paddingContainer + 3}px;
  padding-left: ${Sizes.paddingContainer}px;
  padding-right: ${Sizes.paddingContainer}px;
  padding-bottom: ${Sizes.paddingContainer + 3}px;

  @media ${PlatformDevices.maxLaptop} {
    padding-top: ${Sizes.paddingFlatList + 3}px;
    padding-left: ${Sizes.paddingFlatList}px;
    padding-right: ${Sizes.paddingFlatList}px;
    padding-bottom: ${Sizes.paddingFlatList + 3}px;
  }
`;

// export const ContentVerticalPadder = styled.div`
//   display: flex;
//   flex-direction: column;
//   flex: 1;
//   width: 100%;
//   padding-top: ${Sizes.paddingContainer + 7}px;
//   padding-bottom: ${Sizes.paddingContainer + 7}px;

//   @media ${PlatformDevices.maxLaptop} {
//     padding-top: ${Sizes.paddingFlatList + 7}px;
//     padding-left: ${Sizes.paddingFlatList}px;
//     padding-right: ${Sizes.paddingFlatList}px;
//     padding-bottom: ${Sizes.paddingFlatList + 7}px;
//   }
// `;



export const ContentRowToColumnPadder = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  padding-top: ${Sizes.paddingContainer + 10}px;
  padding-left: ${Sizes.paddingContainer}px;
  padding-right: ${Sizes.paddingContainer}px;
  padding-bottom: ${Sizes.paddingContainer + 10}px;

  @media ${PlatformDevices.maxLaptop} {
    flex-direction: column;
    padding-top: ${Sizes.paddingFlatList + 7}px;
    padding-left: ${Sizes.paddingFlatList}px;
    padding-right: ${Sizes.paddingFlatList}px;
    padding-bottom: ${Sizes.paddingFlatList + 7}px;
  }
`;



export const ContentPadderTop = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 20px;

  @media ${PlatformDevices.maxTablet} {
    padding-left: 15px;
    padding-right: 15px;
  }
`;



export const ContentButtonForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5px;
`;
