import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
  useLocation,
} from 'react-router-dom';

import {
  Formik,
  FormikProps,
} from 'formik';

import { GetAllInputClients } from '../../../../apis/endpoints/clientes.endpoints';
import { DeleteCollabs, PostCreateCollabs, PutEditCollabs } from '../../../../apis/endpoints/pessoas-colaboradores.endpoints';
import { GetAllInputFuncoesColaboradores } from '../../../../apis/endpoints/pessoas-funcoes-colaboradores.endpoints';
import AlertMessage from '../../../../components/Alerts';
import Checkbox from '../../../../components/Checkbox';
import Container from '../../../../components/Container';
import Content from '../../../../components/Content';

import {
  ButtonBack,
} from '../../../../components/Controls';

import {
  FormContainerView,
  FormButtonAction,
  FormRowEndButtonsContainer,
  FormToolberButtonApp,
  FormContainerPadder,
  FormInputsContainer,
} from '../../../../components/Filter/filter-content';

import {
  IconBack,
  IconCheck,
  IconDelete,
} from '../../../../components/Icons';

import InputDropdown from '../../../../components/Inputs/InputDropdown';
import InputText from '../../../../components/Inputs/InputText';

import {
  IToast,
} from '../../../../components/IToast';

import LoadingProgress from '../../../../components/Loadings';
import Screen from '../../../../components/Screen';
import RequiredText from '../../../../components/TextRequired';
import Title from '../../../../components/Title';
import Toolbar from '../../../../components/Toolbar';
import ToolbarGroup from '../../../../components/ToolbarGroup';

import {
  Colors,
  Sizes,
  Values,
} from '../../../../shared/constants';

import {
  EHttpStatusCode,
} from '../../../../shared/enums';

import {
  ILightClientes,
  IColaboradores,
  IDropdownItem,
  IFuncoesColaboradores,
  IResponse,
} from '../../../../shared/interfaces';

import ColaboradoresFormModel from '../../../../shared/models/Colaboradores/colaboradores-form.model';

import {
  stringWordCapitalize,
} from '../../../../shared/utils/string.utils';

import ColaboradoresFormValidation from '../../../../shared/validations/Colaboradores/colaboradores-form.validations';

import {
  APP_MODAL_LOADING_ACTION,
} from '../../../../store/reducers/app.store';



export interface IRouteLocation {
  edit: boolean;
  item: IColaboradores;
}



const ColaboradoresFormsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const routeLocation = useLocation<IRouteLocation>();
  const params = routeLocation?.state;


  const formik = useRef<FormikProps<typeof ColaboradoresFormModel | null>>(null);

  const editForm = params?.edit;
  const [itemToEdit, setItemToEdit] = useState<IColaboradores>(params?.item);

  const [listFuncoes, setListFuncoes] = useState<Array<IDropdownItem> | null>(null);
  const [funcaoInput, setFuncaoInput] = useState<IDropdownItem | null>(null);

  const [listEmpresas, setListEmpresas] = useState<Array<IDropdownItem> | null>(null);
  const [empresaInput, setEmpresaInput] = useState<IDropdownItem | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);

  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);



  async function getClientsFunction() {
    try {
      const response: IResponse = await GetAllInputClients();
      const responseData: ILightClientes = response?.data;

      if (!responseData || !Array.isArray(responseData)) {
        setListEmpresas(null);
        setEmpresaInput(null);
        return;
      }

      const newData: Array<IDropdownItem> = responseData?.map((item: ILightClientes) => {
        return {
          key: String(item?.id),
          label: item?.nomeFantasia,
          value: item?.id,
        };
      });

      setListEmpresas(newData);
    }
    catch (error: any) {
      console.error(error);

      setListEmpresas(null);
      setEmpresaInput(null);
    }
  };


  async function getFuncoesColabsFunction() {
    try {
      const response: IResponse = await GetAllInputFuncoesColaboradores();
      const responseData: IFuncoesColaboradores = response?.data;

      if (!responseData || !Array.isArray(responseData)) {
        setListFuncoes(null);
        setFuncaoInput(null);
        return;
      }

      const newData: Array<IDropdownItem> = responseData?.map((item: IFuncoesColaboradores) => {
        return {
          key: String(item?.id),
          label: item?.nome,
          value: item?.id,
        };
      });

      setListFuncoes(newData);
    }
    catch (error: any) {
      console.error(error);

      setListFuncoes(null);
      setFuncaoInput(null);
    }
  };


  async function updateForm() {
    setLoading(true);

    await getClientsFunction();
    await getFuncoesColabsFunction();

    if (editForm && formik.current) {
      formik.current?.setFieldValue('nome', itemToEdit?.nome);
      formik.current?.setFieldValue('cpf', itemToEdit?.cpf);
      formik.current?.setFieldValue('clienteEmpresa', itemToEdit?.clienteEmpresa);
      formik.current?.setFieldValue('labelFuncao', itemToEdit?.funcao);
      formik.current?.setFieldValue('telefone', itemToEdit?.telefone);
      formik.current?.setFieldValue('isAtivo', itemToEdit?.isAtivo ? true : false);

      formik.current?.validateForm();
    }

    setTimeout(() => {
      setLoading(false);
      setMounted(true);
    }, Values.mountTime);
  }


  function resetForm() {
    setMounted(false);

    setEmpresaInput(null);
    setFuncaoInput(null);
    formik.current?.resetForm();

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  };


  function mountPayload(values: typeof ColaboradoresFormModel) {
    const payload = {
      nome: stringWordCapitalize(values?.nome) || '',
      cpf: values?.cpf,
      clienteEmpresa: values?.clienteEmpresa,
      funcao: values?.funcao,
      telefone: values?.telefone,
      isAtivo: values?.isAtivo ? 1 : 0,
    } as typeof ColaboradoresFormModel;

    return payload;
  }


  async function createItemFunction(values: typeof ColaboradoresFormModel) {
    const payload = mountPayload(values);

    const response: IResponse = await PostCreateCollabs(payload);
    const responseStatus = response?.status;

    if (responseStatus === EHttpStatusCode.OK) {
      IToast({
        type: 'success',
        message: 'Item salvo com sucesso',
      });
      resetForm();
    }
    else {
      console.error('Error', response);
      throw new Error('Erro ao criar item');
    }
  }


  async function editItemFunction(values: typeof ColaboradoresFormModel) {
    const payload = mountPayload(values);

    const itemToSave = {
      id: itemToEdit?.id,
      ...payload,
    } as typeof ColaboradoresFormModel;

    setItemToEdit(itemToSave);

    const response: IResponse = await PutEditCollabs(String(itemToEdit?.id), payload);
    const responseStatus = response?.status;

    if (responseStatus === EHttpStatusCode.OK) {
      IToast({
        type: 'success',
        message: 'Item salvo com sucesso',
      });
    }
    else {
      console.error('Error', response);
      throw new Error('Erro ao editar item');
    }
  }


  async function saveFormFunction(values: any) {
    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      if (editForm) {
        await editItemFunction(values);
      }
      else {
        await createItemFunction(values);
      }
    }
    catch (error: any) {
      console.error(error);

      IToast({
        type: 'error',
        message: 'Erro interno ao salvar item',
      });
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  async function deleteItemFunction() {
    setDeleteAlert(false);

    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response: IResponse = await DeleteCollabs(String(itemToEdit?.id));
      const responseStatus = response?.status;

      if (responseStatus !== EHttpStatusCode.OK) {
        console.error(response);
        IToast({
          type: 'error',
          message: 'Erro ao deletar item',
        });
        return;
      }

      IToast({
        type: 'success',
        message: 'Item deletado com sucesso',
      });
      history.goBack();
    }
    catch (error: any) {
      console.error(error);

      IToast({
        type: 'error',
        message: 'Erro interno ao deletar item',
      });
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderLoading() {
    return (

      <LoadingProgress
        type={'SCREEN'}
      />

    );
  }



  useEffect(() => {
    updateForm();
  }, [formik]);



  return (

    <Formik
      enableReinitialize
      validateOnMount={true}
      isInitialValid={true}
      innerRef={formik}
      initialValues={ColaboradoresFormModel}
      validationSchema={ColaboradoresFormValidation}
      onSubmit={(values) => {
        saveFormFunction(values);
      }}>
      {({
        errors,
        values,
        dirty,
        isValid,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (

        <Screen
          backgroundColor={Colors.appBackground}>

          <Container>

            <Toolbar
              backgroundColor={Colors.toolbarBackground}
              leftIcon={
                <ButtonBack
                  color={Colors.black}
                  size={20}
                />
              }
              centerContent={
                <Title
                  color={Colors.tertiary}>
                  {editForm
                    ? 'Editar colaborador'
                    : 'Criar colaborador'
                  }
                </Title>
              }
              rightIcon={
                <ToolbarGroup>

                  <FormToolberButtonApp
                    disabled={!dirty || !isValid || loading}
                    title={'Salvar'}
                    backgroundColor={Colors.primaryMedium}
                    iconLeft={
                      <IconCheck
                        color={Colors.white}
                        size={Sizes.iconSize}
                      />
                    }
                    onPress={() => {
                      handleSubmit();
                    }}
                  />


                  {editForm && (
                    <FormToolberButtonApp
                      disabled={loading}
                      title={'Deletar'}
                      backgroundColor={Colors.danger}
                      iconLeft={
                        <IconDelete
                          color={Colors.white}
                          size={Sizes.iconSize}
                        />
                      }
                      onPress={() => {
                        setDeleteAlert(true);
                      }}
                    />
                  )}

                </ToolbarGroup>
              }
            />



            <Content>
              <FormContainerPadder>
                <FormContainerView>

                  {!mounted && renderLoading()}



                  {mounted && (
                    <Container>

                      <FormInputsContainer>

                        <RequiredText>
                          * Campos obrigatórios
                        </RequiredText>



                        <InputText
                          disabled={loading}
                          autoCorrect
                          autoCapitalize={'words'}
                          type={'TEXT'}
                          labelText={'Nome *'}
                          placeholderText={'Nome'}
                          borderColor={errors.nome ? Colors.danger : null}
                          helpText={errors.nome}
                          helpColor={Colors.danger}
                          countLimit={Values.nameItem}
                          value={values.nome}
                          onChange={handleChange('nome')}
                          onBlur={handleBlur('nome')}
                        />



                        <InputText
                          disabled={loading}
                          type={'CPF'}
                          labelText={'CPF'}
                          placeholderText={'CPF'}
                          borderColor={errors.nome ? Colors.danger : null}
                          helpText={errors.cpf}
                          helpColor={Colors.danger}
                          countLimit={Values.cpfCount}
                          value={values.cpf}
                          onChange={(rawText: string) => {
                            setFieldValue('cpf', rawText);
                          }}
                          onBlur={handleBlur('cpf')}
                        />



                        <InputText
                          disabled={loading}
                          type={'PHONE'}
                          options={{
                            maskType: 'BRL',
                            withDDD: true,
                            dddMask: '(99) ',
                          }}
                          labelText={'Telefone *'}
                          placeholderText={'Apenas números'}
                          borderColor={errors.telefone ? Colors.danger : null}
                          helpText={errors.telefone}
                          helpColor={Colors.danger}
                          countLimit={Values.phoneMaxCount}
                          value={values.telefone}
                          onChange={(rawText: string) => {
                            setFieldValue('telefone', rawText);
                          }}
                          onBlur={handleBlur('telefone')}
                        />



                        <InputDropdown
                          dropArray={listEmpresas}
                          disabled={loading}
                          labelText={'Cliente Empresa'}
                          placeholderText={empresaInput?.label}
                          borderColor={errors.clienteEmpresa ? Colors.danger : null}
                          helpText={errors.clienteEmpresa}
                          helpColor={Colors.danger}
                          value={empresaInput?.value}
                          onChange={(item: IDropdownItem) => {
                            setFieldValue('clienteEmpresa', item?.value);
                            setEmpresaInput(item);
                          }}
                          onBlur={handleBlur('clienteEmpresa')}
                        />



                        <InputDropdown
                          dropArray={listFuncoes}
                          disabled={loading}
                          labelText={'Função'}
                          placeholderText={funcaoInput?.label}
                          borderColor={errors.funcao ? Colors.danger : null}
                          helpText={errors.funcao}
                          helpColor={Colors.danger}
                          value={funcaoInput?.value}
                          onChange={(item: IDropdownItem) => {
                            setFieldValue('labelFuncao', item?.value);
                            setFuncaoInput(item);
                          }}
                          onBlur={handleBlur('labelFuncao')}
                        />


                        <Checkbox
                          checked={Boolean(values.isAtivo)}
                          title={'Ativo'}
                          onPress={() => {
                            setFieldValue('isAtivo', !values.isAtivo);
                          }}
                        />

                      </FormInputsContainer>



                      <FormRowEndButtonsContainer>

                        <FormButtonAction
                          outline
                          disabled={loading || !mounted}
                          iconLeft={
                            <IconBack
                              color={Colors.primaryMedium}
                              size={16}
                            />
                          }
                          title={'Voltar'}
                          backgroundColor={Colors.primaryMedium}
                          onPress={() => {
                            history.goBack();
                          }}
                        />



                        <FormButtonAction
                          disabled={!dirty || !isValid || loading || !mounted}
                          iconLeft={
                            <IconCheck
                              color={Colors.white}
                              size={16}
                            />
                          }
                          title={'Salvar'}
                          backgroundColor={Colors.primaryMedium}
                          onPress={() => {
                            handleSubmit();
                          }}
                        />

                      </FormRowEndButtonsContainer>

                    </Container>
                  )}

                </FormContainerView>
              </FormContainerPadder>
            </Content>

          </Container>



          <AlertMessage
            visible={deleteAlert}
            title={'Deletar item?'}
            description={'Gostaria de deletar esse item?'}
            cancelText={'Não'}
            cancelPress={() => {
              setDeleteAlert(false);
            }}
            okText={'Sim'}
            okPress={() => {
              deleteItemFunction();
            }}
          />

        </Screen>

      )}
    </Formik>

  );
};



export default ColaboradoresFormsScreen;
