import {
  createAction,
  createReducer,
} from '@reduxjs/toolkit';

import UserModel from '../../shared/models/Usuarios';



const INITIAL_STATE = {
  loggedIn: false,
  logout: false,

  actualUser: UserModel,
  userToken: '',
};



// Action Types

export const USER_LOGGED = '@user/LOGGED';
export const COMPANY_LOGOUT_STATE = '@company/LOGOUT_ACTION';

export const USER_SET = '@user/SET';
export const USER_TOKEN = '@user/MKS-TOKEN';



// Action Creators

export const userLogged = createAction<object>(USER_LOGGED);
export const logoutAction = createAction<object>(COMPANY_LOGOUT_STATE);

export const userAction = createAction<object>(USER_SET);

export const userTokenAction = createAction<object>(USER_TOKEN);



// Handlers

const setLogged = (state = INITIAL_STATE, action: any) => ({
  ...state,
  loggedIn: action.payload,
});


const logoutReducer = (state = INITIAL_STATE, action: any) => ({
  ...state,
  logout: action.payload,
});


const setUser = (state = INITIAL_STATE, action: any) => ({
  ...state,
  actualUser: action.payload,
});


const setToken = (state = INITIAL_STATE, action: any) => ({
  ...state,
  userToken: action.payload,
});



// Reducers

export default createReducer(INITIAL_STATE, {
  [userLogged.type]: setLogged,
  [logoutAction.type]: logoutReducer,

  [userAction.type]: setUser,
  [userTokenAction.type]: setToken,
});
