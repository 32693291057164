const Usuario = {
  id: null,
  name: '',
  email: '',
  clienteEmpresa: null,
  perfil: null,
  iat: '',
  exp: '',
  photo: '',
};



export default Usuario;
