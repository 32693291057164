import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
  useLocation,
} from 'react-router-dom';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  DeleteCancelMotives,
  PostCreateCancelMotives,
  PutEditCancelMotives,
} from '../../../../../apis/endpoints/cadastros-motivo-cancelamento.endpoints';

import AlertMessage from '../../../../../components/Alerts';
import Checkbox from '../../../../../components/Checkbox';
import Container from '../../../../../components/Container';
import Content from '../../../../../components/Content';

import {
  ButtonBack,
} from '../../../../../components/Controls';

import Epigraph from '../../../../../components/Epigraph';

import {
  FormContainerView,
  FormButtonAction,
  FormRowEndButtonsContainer,
  FormToolberButtonApp,
  FormContainerPadder,
  FormInputsContainer,
  FormsSectionView,
  FormsInputSectionView,
  FooterDescription,
  FormsInputMoreMarginSectionView,
} from '../../../../../components/Filter/filter-content';

import {
  IconAdd,
  IconBack,
  IconCheck,
  IconDelete,
} from '../../../../../components/Icons';

import InputText from '../../../../../components/Inputs/InputText';

import {
  IToast,
} from '../../../../../components/IToast';

import LoadingProgress from '../../../../../components/Loadings';
import Screen from '../../../../../components/Screen';
import RequiredText from '../../../../../components/TextRequired';
import Title from '../../../../../components/Title';
import Toolbar from '../../../../../components/Toolbar';
import ToolbarGroup from '../../../../../components/ToolbarGroup';

import {
  Colors,
  Sizes,
  Values,
} from '../../../../../shared/constants';

import {
  EHttpStatusCode,
} from '../../../../../shared/enums';

import {
  IClientes,
  IResponse,
} from '../../../../../shared/interfaces';

import ClientesFormModel from '../../../../../shared/models/Clientes/clientes-form.model';

import {
  stringCapitalize,
  stringUpperCase,
} from '../../../../../shared/utils/string.utils';

import ClientesFormValidation from '../../../../../shared/validations/Clientes/clientes-form.validations';

import {
  APP_MODAL_LOADING_ACTION,
} from '../../../../../store/reducers/app.store';

import {
  InputRowLineView,
  InputRowLineButton,
} from './styled';



export interface IRouteLocation {
  edit: boolean;
  item: IClientes;
}



const ClientesFormsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const routeLocation = useLocation<IRouteLocation>();
  const params = routeLocation?.state;


  const formik = useRef<FormikProps<typeof ClientesFormModel | null>>(null);

  const editForm = params?.edit;
  const [itemToEdit, setItemToEdit] = useState<IClientes>(params?.item);

  const [sendEmail, setSendEmail] = useState('');
  const [sendEmailList, setSendEmailList] = useState([]);

  const [receiveEmail, setReceiveEmail] = useState('');
  const [receiveEmailList, setReceiveEmailList] = useState([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);

  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);



  function updateForm() {
    setLoading(true);

    if (editForm && formik.current) {
      formik.current?.setFieldValue('nomeFantasia', itemToEdit?.nomeFantasia);
      formik.current?.setFieldValue('cnpj', itemToEdit?.cnpj);
      formik.current?.setFieldValue('razaoSocial', itemToEdit?.razaoSocial);
      formik.current?.setFieldValue('jornada', itemToEdit?.jornadaTrabalho);
      formik.current?.setFieldValue('isAtivo', itemToEdit?.isAtivo);

      formik.current?.validateForm();
    }

    setTimeout(() => {
      setLoading(false);
      setMounted(true);
    }, Values.mountTime);
  }


  function resetForm() {
    setMounted(false);

    formik.current?.resetForm();

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  };


  const handleAddSendEmail = () => {
    if (sendEmail) {
      setSendEmailList([...sendEmailList, sendEmail]);
      setSendEmail(''); // Limpa o input após adicionar
    }
  };


  const handleAddReceiveEmail = () => {
    if (receiveEmail) {
      setReceiveEmailList([...receiveEmailList, receiveEmail]);
      setReceiveEmail(''); // Limpa o input após adicionar
    }
  };


  const handleRemoveSendEmail = (emailToRemove) => {
    setSendEmailList(sendEmailList.filter((email) => email !== emailToRemove));
  };


  const handleRemoveReceiveEmail = (emailToRemove) => {
    setReceiveEmailList(receiveEmailList.filter((email) => email !== emailToRemove));
  };


  function mountPayload(values: typeof ClientesFormModel) {
    const sendEmails = sendEmailList.join(', ');
    const receiveEmails = receiveEmailList.join(', ');

    const payload = {
      nomeFantasia: stringCapitalize(values?.nomeFantasia) || '',
      cnpj: values?.cnpj,
      razaoSocial: stringUpperCase(values?.razaoSocial) || '',
      jornadaTrabalho: values?.jornadaTrabalho,
      isAtivo: values?.isAtivo ? 1 : 0,

      recobranca_emails_noticacao: sendEmails,
      recobranca_emails_aqs: receiveEmails,

    } as typeof ClientesFormModel;

    return payload;
  }


  async function createItemFunction(values: any) {
    const payload = mountPayload(values);

    const response: IResponse = await PostCreateCancelMotives(payload);
    const responseStatus = response?.status;

    if (responseStatus === EHttpStatusCode.OK) {
      IToast({
        type: 'success',
        message: 'Item salvo com sucesso',
      });
      resetForm();
    }
    else {
      console.error('Error', response);
      throw new Error('Erro ao criar item');
    }
  }


  async function editItemFunction(values: any) {
    const payload = mountPayload(values);

    const itemToSave = {
      id: itemToEdit?.id,
      ...payload,
    } as typeof ClientesFormModel;

    setItemToEdit(itemToSave);

    const response: IResponse = await PutEditCancelMotives(String(itemToEdit?.id), payload);
    const responseStatus = response?.status;

    if (responseStatus === EHttpStatusCode.OK) {
      IToast({
        type: 'success',
        message: 'Item salvo com sucesso',
      });
    }
    else {
      console.error('Error', response);
      throw new Error('Erro ao editar item');
    }
  }


  async function saveFormFunction(values: any) {
    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      if (editForm) {
        await editItemFunction(values);
      }
      else {
        await createItemFunction(values);
      }
    }
    catch (error: any) {
      console.error(error);

      IToast({
        type: 'error',
        message: 'Erro interno ao salvar item',
      });
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  async function deleteItemFunction() {
    setDeleteAlert(false);

    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response: IResponse = await DeleteCancelMotives(String(itemToEdit?.id));
      const responseStatus = response?.status;

      if (responseStatus !== EHttpStatusCode.OK) {
        console.error(response);
        IToast({
          type: 'error',
          message: 'Erro ao deletar item',
        });
        return;
      }

      IToast({
        type: 'success',
        message: 'Item deletado com sucesso',
      });
      history.goBack();
    }
    catch (error: any) {
      console.error(error);

      IToast({
        type: 'error',
        message: 'Erro interno ao deletar item',
      });
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderLoading() {
    return (

      <LoadingProgress
        type={'SCREEN'}
      />

    );
  }



  useEffect(() => {
    updateForm();
  }, [formik]);



  return (

    <Formik
      enableReinitialize
      validateOnMount={true}
      isInitialValid={true}
      innerRef={formik}
      initialValues={ClientesFormModel}
      validationSchema={ClientesFormValidation}
      onSubmit={(values) => {
        saveFormFunction(values);
      }}>
      {({
        errors,
        values,
        dirty,
        isValid,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (

        <Screen
          backgroundColor={Colors.appBackground}>

          <Container>

            <Toolbar
              backgroundColor={Colors.toolbarBackground}
              leftIcon={
                <ButtonBack
                  color={Colors.black}
                  size={20}
                />
              }
              centerContent={
                <Title
                  color={Colors.tertiary}>
                  {editForm
                    ? 'Editar cliente'
                    : 'Criar cliente'
                  }
                </Title>
              }
              rightIcon={
                <ToolbarGroup>

                  <FormToolberButtonApp
                    disabled={!dirty || !isValid || loading}
                    title={'Salvar'}
                    backgroundColor={Colors.primaryMedium}
                    iconLeft={
                      <IconCheck
                        color={Colors.white}
                        size={Sizes.iconSize}
                      />
                    }
                    onPress={() => {
                      handleSubmit();
                    }}
                  />


                  {editForm && (
                    <FormToolberButtonApp
                      disabled={loading}
                      title={'Deletar'}
                      backgroundColor={Colors.danger}
                      iconLeft={
                        <IconDelete
                          color={Colors.white}
                          size={Sizes.iconSize}
                        />
                      }
                      onPress={() => {
                        setDeleteAlert(true);
                      }}
                    />
                  )}

                </ToolbarGroup>
              }
            />



            <Content>
              <FormContainerPadder>
                <FormContainerView>

                  {!mounted && renderLoading()}



                  {mounted && (
                    <Container>

                      <FormInputsContainer>

                        <RequiredText>
                          * Campos obrigatórios
                        </RequiredText>



                        <FormsSectionView>

                          <Epigraph
                            text={'Dados do usuário'}
                          />


                          <FormsInputSectionView>

                            <InputText
                              disabled={loading}
                              autoCorrect
                              autoCapitalize={'words'}
                              type={'TEXT'}
                              labelText={'Nome fantasia *'}
                              placeholderText={'Nome fantasia'}
                              borderColor={errors.nomeFantasia ? Colors.danger : null}
                              helpText={errors.nomeFantasia}
                              helpColor={Colors.danger}
                              countLimit={Values.nameItem}
                              value={values.nomeFantasia}
                              onChange={handleChange('nomeFantasia')}
                              onBlur={handleBlur('nomeFantasia')}
                            />



                            <InputText
                              disabled={loading}
                              type={'CNPJ'}
                              labelText={'CNPJ *'}
                              placeholderText={'CNPJ'}
                              borderColor={errors.cnpj ? Colors.danger : null}
                              helpText={errors.cnpj}
                              helpColor={Colors.danger}
                              countLimit={Values.cnpjCount}
                              value={values.cnpj}
                              onChange={(rawText: string) => {
                                setFieldValue('cnpj', rawText);
                              }}
                              onBlur={handleBlur('cnpj')}
                            />



                            <InputText
                              disabled={loading}
                              autoCorrect
                              autoCapitalize={'words'}
                              type={'TEXT'}
                              labelText={'Razão social *'}
                              placeholderText={'Razão social'}
                              borderColor={errors.razaoSocial ? Colors.danger : null}
                              helpText={errors.razaoSocial}
                              helpColor={Colors.danger}
                              countLimit={Values.nameItem}
                              value={values.razaoSocial}
                              onChange={handleChange('razaoSocial')}
                              onBlur={handleBlur('razaoSocial')}
                            />



                            <InputText
                              disabled={loading}
                              autoCorrect
                              autoCapitalize={'words'}
                              type={'TEXT'}
                              labelText={'Jornada de trabalho *'}
                              placeholderText={'Jornada de trabalho'}
                              borderColor={errors.jornadaTrabalho ? Colors.danger : null}
                              helpText={errors.jornadaTrabalho}
                              helpColor={Colors.danger}
                              countLimit={Values.nameItem}
                              value={values.jornadaTrabalho}
                              onChange={handleChange('jornada')}
                              onBlur={handleBlur('jornada')}
                            />



                            <Checkbox
                              checked={Boolean(values.isAtivo)}
                              title={'Ativo'}
                              onPress={() => {
                                setFieldValue('isAtivo', !values.isAtivo);
                              }}
                            />

                          </FormsInputSectionView>

                        </FormsSectionView>



                        <FormsSectionView>

                          <Epigraph
                            text={'Parâmetros de configurações para relatórios e cobranças'}
                          />


                          <FormsInputSectionView>

                            <InputText
                              disabled={loading}
                              autoCorrect
                              autoCapitalize={'words'}
                              type={'TEXT'}
                              labelText={'Recobrança - Multiplicador para Metro Cúbico'}
                              placeholderText={'Multiplicador para Metro Cúbico'}
                              borderColor={errors.recobranca_cubico ? Colors.danger : null}
                              helpText={errors.recobranca_cubico}
                              helpColor={Colors.danger}
                              countLimit={Values.nameItem}
                              value={values.recobranca_cubico}
                              onChange={handleChange('recobranca_cubico')}
                              onBlur={handleBlur('recobranca_cubico')}
                            />


                            <FooterDescription>
                              Valor a ser multiplicado pelo total de metro cúbico para determinar o Custo da Recobrança
                            </FooterDescription>

                          </FormsInputSectionView>



                          <FormsInputMoreMarginSectionView>

                            <InputText
                              disabled={loading}
                              autoCorrect
                              autoCapitalize={'words'}
                              type={'TEXT'}
                              labelText={'Recobrança - Multiplicador para Metro Linear'}
                              placeholderText={'Multiplicador para Metro Linear'}
                              borderColor={errors.recobranca_linear ? Colors.danger : null}
                              helpText={errors.recobranca_linear}
                              helpColor={Colors.danger}
                              countLimit={Values.nameItem}
                              value={values.recobranca_linear}
                              onChange={handleChange('recobranca_linear')}
                              onBlur={handleBlur('recobranca_linear')}
                            />


                            <FooterDescription>
                              Valor a ser multiplicado pelo total de metro linear para determinar o Custo da Recobrança
                            </FooterDescription>

                          </FormsInputMoreMarginSectionView>



                          <FormsInputMoreMarginSectionView>

                            <p>
                              Recobrança - E-mails Destinatários para Notificações sobre Recobranças
                            </p>


                            <div>
                              {sendEmailList?.length <= 0 && <p>Sem emails</p>}

                              {sendEmailList?.length > 0 && sendEmailList.map((email, index) => (
                                <InputRowLineView
                                  key={index}>
                                  {email}
                                  <button onClick={() => handleRemoveSendEmail(email)}>Remover</button>
                                </InputRowLineView>
                              ))}
                            </div>


                            <Row>

                              <Col xs={9}>
                                <InputText
                                  disabled={loading}
                                  autoCorrect
                                  autoCapitalize={'words'}
                                  type={'TEXT'}
                                  placeholderText={'Adicionar email'}
                                  countLimit={Values.emailMaxCount}
                                  value={sendEmail}
                                  onChange={(rayValue: string) => setSendEmail(rayValue)}
                                />
                              </Col>


                              <Col xs={3}>
                                <InputRowLineButton
                                  disabled={loading}
                                  title={'Adicionar'}
                                  backgroundColor={Colors.primary}
                                  iconLeft={
                                    <IconAdd
                                      color={Colors.white}
                                      size={Sizes.iconSize}
                                    />
                                  }
                                  onPress={() => {
                                    handleAddSendEmail();
                                  }}
                                />
                              </Col>

                            </Row>


                            <FooterDescription>
                              Endereços de E-mails que receberão notificações sobre as Recobranças deste Cliente
                            </FooterDescription>

                          </FormsInputMoreMarginSectionView>



                          <FormsInputMoreMarginSectionView>

                            <InputText
                              disabled={loading}
                              autoCorrect
                              autoCapitalize={'words'}
                              type={'TEXT'}
                              labelText={'Recobrança - Total de Dias antes do Vencimento de Recobrança para Notificar com Lembrete'}
                              placeholderText={'Total de Dias antes do Vencimento de Recobrança para Notificar com Lembrete'}
                              borderColor={errors.recobranca_total_dias ? Colors.danger : null}
                              helpText={errors.recobranca_total_dias}
                              helpColor={Colors.danger}
                              countLimit={Values.nameItem}
                              value={values.recobranca_total_dias}
                              onChange={handleChange('recobranca_total_dias')}
                              onBlur={handleBlur('recobranca_total_dias')}
                            />


                            <FooterDescription>
                              A quantos dias do prazo da próxima Recobrança as pessoas do Cliente serão notificadas solicitando decisão
                            </FooterDescription>

                          </FormsInputMoreMarginSectionView>



                          <FormsInputMoreMarginSectionView>

                            <p>
                              AQS - E-mails Destinatários para Notificações sobre Gerar AQS
                            </p>


                            <div>
                              {receiveEmailList?.length <= 0 && <p>Sem emails</p>}

                              {receiveEmailList?.length > 0 && receiveEmailList.map((email, index) => (
                                <InputRowLineView
                                  key={index}>
                                  {email}
                                  <button onClick={() => handleRemoveReceiveEmail(email)}>Remover</button>
                                </InputRowLineView>
                              ))}
                            </div>


                            <Row>

                              <Col xs={9}>
                                <InputText
                                  disabled={loading}
                                  autoCorrect
                                  autoCapitalize={'words'}
                                  type={'TEXT'}
                                  placeholderText={'Adicionar email'}
                                  countLimit={Values.emailMaxCount}
                                  value={receiveEmail}
                                  onChange={(rayValue: string) => setReceiveEmail(rayValue)}
                                />
                              </Col>


                              <Col xs={3}>
                                <InputRowLineButton
                                  disabled={loading}
                                  title={'Adicionar'}
                                  backgroundColor={Colors.primary}
                                  iconLeft={
                                    <IconAdd
                                      color={Colors.white}
                                      size={Sizes.iconSize}
                                    />
                                  }
                                  onPress={() => {
                                    handleAddReceiveEmail();
                                  }}
                                />
                              </Col>

                            </Row>


                            <FooterDescription>
                              Endereços de E-mails que receberão notificações sobre as AQS a serem geradas deste Cliente
                            </FooterDescription>

                          </FormsInputMoreMarginSectionView>

                        </FormsSectionView>

                      </FormInputsContainer>



                      <FormRowEndButtonsContainer>

                        <FormButtonAction
                          outline
                          disabled={loading || !mounted}
                          iconLeft={
                            <IconBack
                              color={Colors.primaryMedium}
                              size={16}
                            />
                          }
                          title={'Voltar'}
                          backgroundColor={Colors.primaryMedium}
                          onPress={() => {
                            history.goBack();
                          }}
                        />



                        <FormButtonAction
                          disabled={!dirty || !isValid || loading || !mounted}
                          iconLeft={
                            <IconCheck
                              color={Colors.white}
                              size={16}
                            />
                          }
                          title={'Salvar'}
                          backgroundColor={Colors.primaryMedium}
                          onPress={() => {
                            handleSubmit();
                          }}
                        />

                      </FormRowEndButtonsContainer>

                    </Container>
                  )}

                </FormContainerView>
              </FormContainerPadder>
            </Content>

          </Container>



          <AlertMessage
            visible={deleteAlert}
            title={'Deletar item?'}
            description={'Gostaria de deletar esse item?'}
            cancelText={'Não'}
            cancelPress={() => {
              setDeleteAlert(false);
            }}
            okText={'Sim'}
            okPress={() => {
              deleteItemFunction();
            }}
          />

        </Screen>

      )}
    </Formik>

  );
};



export default ClientesFormsScreen;
