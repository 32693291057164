import React from 'react';

import {
  Colors,
} from '../../../shared/constants';

import {
  ButtonAppStyle,
  ButtonTextAppStyle,
  ButtonIconLeftApp,
  ButtonIconRightApp,
} from './styled';



export interface IProps {
  disabled?: boolean;
  outline?: boolean;
  title?: string;
  backgroundColor?: string;
  iconLeft?: any;
  iconRight?: any;
  onPress?: any;
}



const ButtonApp: React.FC<IProps> = (props: IProps) => {
  const className: any = { ...props };


  return (

    <ButtonAppStyle
      className={className?.className}

      outline={props.outline}
      disabled={props.disabled}
      backgroundColor={props.disabled
        ? Colors.disabled
        : props.backgroundColor
      }
      onClick={() => {
        if (!props.disabled && props.onPress) {
          props.onPress();
        }
      }}>


      {props.iconLeft && (
        <ButtonIconLeftApp>
          {props.iconLeft}
        </ButtonIconLeftApp>
      )}


      <ButtonTextAppStyle
        marginLeft={Boolean(props.iconLeft)}
        marginRight={Boolean(props.iconRight)}
        outline={props.outline}
        backgroundColor={props.disabled
          ? Colors.disabled
          : props.backgroundColor
        }>
        {props.title ? props.title.toUpperCase() : '-'}
      </ButtonTextAppStyle>


      {props.iconRight && (
        <ButtonIconRightApp>
          {props.iconRight}
        </ButtonIconRightApp>
      )}

    </ButtonAppStyle>

  );
};



export default ButtonApp;
