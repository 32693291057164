import React from 'react';

import {
  LoadingContainer,
  TextLoading,
  AreaIcon,
} from './styled';



export interface IProps {
  type?: 'FOOTER' | 'SCREEN';
  hideText?: boolean;
  textSize?: string;
}



const LoadingProgress: React.FC<IProps> = (props) => {
  return (

    <LoadingContainer
      type={props.type}>

      <AreaIcon />


      {!props.hideText &&
        <TextLoading
          textSize={props.textSize}>
          Aguarde, Carregando.
        </TextLoading>
      }

    </LoadingContainer>

  );
};



LoadingProgress.defaultProps = {
  type: 'FOOTER',
};



export default LoadingProgress;
