import React,
{
  useEffect,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import Navegar from '../../components/Navegar';
import Title from '../../components/Title';
import Toolbar from '../../components/Toolbar';

import {
  Colors,
} from '../../shared/constants';

import {
  EDrawerMenu,
} from '../../shared/enums';

import {
  APP_MENU_SELECTED_ACTION,
} from '../../store/reducers/app.store';

// import { Container } from './styles';



const HomeScreen: React.FC = () => {
  const dispatch = useDispatch();



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.HOME });
  }, []);



  return (

    <Navegar>

      <Toolbar
        centerContent={
          <Title
            color={Colors.tertiary}>
            Início
          </Title>
        }
      />

    </Navegar>

  );
};



export default HomeScreen;
