import ApiWS from '../index';



export const PostRecoveryPassword = async (payload: any) => {
  const url = '/app/password/request';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PostResetPassword = async (cpfReceived: string, codeReceived: string, passwordReceived: string) => {
  const url = '/app/password/reset';

  const payload = {
    cpf: cpfReceived,
    code: codeReceived,
    password: passwordReceived,
  };

  const response = await ApiWS.post(url, payload);
  return response?.data;
};
