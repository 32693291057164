const environment = {
  production: false,
  environment: 'LOCAL',
  title: 'Local Environment Heading',
  apiUrl: 'http://localhost:5000',
};



export default environment;
