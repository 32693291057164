import {
  IDropdownItem,
} from '../../interfaces';



const AllItems: IDropdownItem = {
  key: 'All',
  label: 'Tudo',
  value: null,
};



export { AllItems };
