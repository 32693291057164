const ColaboradoresFormModel = {
  id: null,
  nome: '',
  cpf: '',
  telefone: '',
  clienteEmpresa: null,
  funcao: null,
  isAtivo: 0,
};



export default ColaboradoresFormModel;
