const ClientesFormModel = {
  id: null,
  cnpj: '',
  razaoSocial: '',
  nomeFantasia: '',
  jornadaTrabalho: '',
  isAtivo: 0,

  recobranca_cubico: '',
  recobranca_linear: '',

  recobranca_emails_noticacao: '',
  recobranca_emails_aqs: '',

  recobranca_total_dias: '',
};



export default ClientesFormModel;
