import {
  Values,
} from '../../constants';

import Yup from '../base-validation.yup';



const PassChangeUserValidationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required('Senha obrigatória')
    .min(Values.passwordMinCount, `Deve conter pelo menos ${Values.passwordMinCount} caracteres`)
    .max(Values.passwordMaxCount, `Deve conter até ${Values.passwordMaxCount} caracteres`),

  password: Yup.string()
    .required('Senha obrigatória')
    .min(Values.passwordMinCount, `Deve conter pelo menos ${Values.passwordMinCount} caracteres`)
    .max(Values.passwordMaxCount, `Deve conter até ${Values.passwordMaxCount} caracteres`),

  repassword: Yup.string()
    .required('A Senha deve ser confirmada')
    .min(Values.passwordMinCount, `Deve conter pelo menos ${Values.passwordMinCount} caracteres`)
    .max(Values.passwordMaxCount, `Deve conter até ${Values.passwordMaxCount} caracteres`)
    .oneOf([Yup.ref('password')], 'Certifique-se que as senhas são iguais'),
});



export default PassChangeUserValidationSchema;
