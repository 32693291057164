import ApiWS from '../index';



export const GetClientesContratosListAll = async (payload?: any) => {
  const url = '/app/cadastros/clientes-contratos/buscar';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PostCreateClientesContratos = async (payload: any) => {
  const url = '/app/cadastros/clientes-contratos/criar';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PutEditClientesContratos = async (idContrato: string, payload: any) => {
  const url = `/app/cadastros/clientes-contratos/editar/${idContrato}`;

  const response = await ApiWS.put(url, payload);
  return response?.data;
};



export const DeleteClientesContratos = async (idContrato: string) => {
  const url = `/app/cadastros/clientes-contratos/deletar/${idContrato}`;

  const response = await ApiWS.delete(url);
  return response?.data;
};
