import {
  IDropdownItem,
} from '../../interfaces';



const listStatus: Array<IDropdownItem> = [
  {
    key: '1',
    label: 'Ativo',
    value: 1,
  },
  {
    key: '2',
    label: 'Inativo',
    value: 0,
  },
];



export { listStatus };
