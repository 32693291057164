import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export interface IProps {
  color?: string;
}



export const LoginFooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  width: 100%;
  height: ${Sizes.buttonHeight}px;
  margin-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
`;

export const LoginFooterText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  color: ${(props: IProps) => props.color || Colors.grayLight};
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`;

export const LoginFooterTermButton = styled.a`
  color: ${(props: IProps) => props.color || Colors.grayLight};
  transition: all .15s ease;

  :hover {
    color:${Colors.primaryLight};
  }
`;
