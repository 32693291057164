import ApiWS from '../index';



export const GetCancelMotivesListAll = async (payload?: any) => {
  const url = '/app/cadastros/motivo-cancelamento/buscar';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PostCreateCancelMotives = async (payload: any) => {
  const url = '/app/cadastros/motivo-cancelamento/criar';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PutEditCancelMotives = async (idCancelMotives: string, payload: any) => {
  const url = `/app/cadastros/motivo-cancelamento/editar/${idCancelMotives}`;

  const response = await ApiWS.put(url, payload);
  return response?.data;
};



export const DeleteCancelMotives = async (idCancelMotives: string) => {
  const url = `/app/cadastros/motivo-cancelamento/deletar/${idCancelMotives}`;

  const response = await ApiWS.delete(url);
  return response?.data;
};
