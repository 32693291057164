import Yup from '../base-validation.yup';



const MateriaisFormValidation = Yup.object().shape({
  nome: Yup.string()
    .required('Campo obrigatório')
    .min(3, `Precisa conter pelo menos ${3} caracteres`),


  categoria: Yup.string()
    .required('Campo obrigatório'),


  medida: Yup.string()
    .nullable(),


  status: Yup.boolean()
    .nullable(),
});



export default MateriaisFormValidation;
