import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
} from '../../shared/constants';



interface IProps {
  selected: boolean;

  displayNone?: boolean;
}

const fontSize = '12px';



export const TableNextStyled = styled.div`
  font-size: ${fontSize};
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  background-color: ${Colors.formBackground};
  box-shadow: 0 0 25px rgba(0, 0, 0, .02);
  
  .table,
  table {
    overflow: hidden;
    margin: 0;
    border-left: hidden;
    border-right: hidden;

    .no-style {
      padding: 0 !important;
      margin: 0 !important;
    }


    thead {
      
      tr {
        background-color: ${Colors.primary};
        
        th {
          padding: 6px 4px !important;
          color: white;
          font-size: ${fontSize};
          font-weight: 600;
          text-align: center;
          letter-spacing: -.04rem;
          word-wrap: break-word;
          vertical-align: middle;
        }
      }
    }



    tbody {
      border-top-color: ${Colors.primary} !important;
      border-top-style: solid !important;
      border-top-width: 2px !important;


      .no-horizontal-border {
        border-left-width: 0;
        border-right-width: 0;
      }


      tr {
        border-color: #dee2e6;
        border-style: solid;
        border-width: 1px;


        .td-button {
          color: ${Colors.tertiaryDark};
          cursor: pointer;
        }


        .actions {
          width: 20px;
          align-items: center;
          justify-content: center;
        }


        td {
          padding: 6px 4px !important;
          font-size: ${fontSize};
          font-weight: normal;
          text-align: center;
          vertical-align: middle;
          
          word-wrap: break-word;
        }

        :nth-of-type(odd)>* {
          --bs-table-accent-bg: ${Colors.primaryThin};
        }

        :hover:nth-of-type(odd) > *,
        :hover {
          background-color: ${Colors.primaryLight};
          --bs-table-accent-bg: ${Colors.primaryLight};
        }
      }
    }

  }


  .order, .react-bootstrap-table-sort-order,
  .order-4, .caret-4-asc, .caret-4-desc {
    display: block;
  }

  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 0.375rem dashed;
    border-top: 0.375rem solid ${Colors.white};
    border-right: 0.375rem solid transparent;
    border-left: 0.375rem solid transparent;
  }
  
  .dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 0.375rem dashed;
    border-bottom: 0.375rem solid ${Colors.white};
    content: "";
  }


  .pagination {
    margin: 0; 
    transition: all .15s ease;

    .page-item > a {
      color: ${Colors.primary};
      border-radius: 0px;
    }
    
    .active > a, 
    .active > a:focus, 
    .active > a:active, 
    
    .active > span,
    .active > span:hover, 
    .active > span:focus {
      color: ${Colors.black} !important;
      background-color: ${Colors.white};
      background: linear-gradient(180deg, ${Colors.white} 0, ${Colors.grayLight});
      border-color: ${Colors.gray};
      box-shadow: none;
      font-weight: bold;
      transition: all .15s ease;
    }

    .page-item > a:hover,
    .active > a:hover {
      color: ${Colors.white} !important;
      background-color: ${Colors.tertiaryDark};
      background: ${Colors.tertiaryDark};
      border-color: ${Colors.tertiaryDark};
      transition: all .15s ease;
    }
  }
`;



export const TableControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
`;

export const ButtonNormal = css`
  background-color: ${Colors.white};
  background: ${Colors.white};
  color: ${Colors.primary};
  border: 1px solid rgba(0,0,0,.125) !important;
`;

export const ButtonSelected = css`
  background-color: ${Colors.white};
  background: linear-gradient(180deg, ${Colors.white} 0, ${Colors.grayLight});
  color: ${Colors.black};
  font-weight: bold;
  border: 1px solid ${Colors.gray} !important;
`;

export const TableButtonControl = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 7px;
  ${(props: IProps) => props.selected ? ButtonSelected : ButtonNormal};
  border: 1px solid transparent;
  border-radius: 0px;
  transition: all .15s ease;

  :hover {
    color: ${Colors.white} !important;
    background-color: ${Colors.tertiaryDark};
    background: ${Colors.tertiaryDark};
    border-color: ${Colors.tertiaryDark};
  }
`;

export const TableSpanControl = styled.span`
`;


export const TableFooterControlContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-left: 1rem;
`;

export const TableTotalSpan = styled.span`
  font-size: 1.11rem;
`;
