import React from 'react';

import {
  Colors,
} from '../../shared/constants';

import LoadingProgress from '../Loadings';
import Overlay from '../Overlay';

import {
  LoadingProgressStyled,
} from './styled';



export interface IProps {
  visible?: any;

  overlayBackground?: any;
  backgroundColor?: string;

  borderRadius?: number;

  indicatorColor?: string;
}



const Loader: React.FC<IProps> = (props: IProps) => {
  return (

    <Overlay
      noPress
      showBackground
      visible={props.visible}
      overlayColor={Colors.modalOverlayBackground}>

      <LoadingProgressStyled>
        <LoadingProgress />
      </LoadingProgressStyled>

    </Overlay>

  );
};



export default Loader;
