// Backgrounds
import _backgroundAvatar from '../assets/backgrounds/background-avatar.png';
import _backgroundHome from '../assets/backgrounds/background-home.jpg';
import _backgroundLogin from '../assets/backgrounds/background-login.jpg';

// Success
import _check from '../assets/images/check.png';
import _empty from '../assets/images/empty.png';

// Errors
import _error401 from '../assets/images/error-401.png';
import _other404 from '../assets/images/error-404.png';
import _other500 from '../assets/images/error-500.png';

// Logos
import _logoIconApp from '../assets/logos/icon.png';
import _logoFullColorApp from '../assets/logos/logo-full-color.png';
import _logoFullNegativeApp from '../assets/logos/logo-full-negative.png';
import _logoFullWhiteApp from '../assets/logos/logo-full-white.png';



export const Backgrounds = {
  backgroundAvatar:                 _backgroundAvatar,
  backgroundHome:                   _backgroundHome,
  backgroundLogin:                  _backgroundLogin,
};



export const Errors = {
  empty:                            _empty,
  error401:                         _error401,
  other404:                         _other404,
  other500:                         _other500,
};



export const Success = {
  check:                            _check,
};



export const Logos = {
  logoIconApp:                      _logoIconApp,
  logoFullColorApp:                 _logoFullColorApp,
  logoFullNegativeApp:              _logoFullNegativeApp,
  logoFullWhiteApp:                 _logoFullWhiteApp,
};



const Images = {
  ...Backgrounds,
  ...Errors,
  ...Logos,
  ...Success,
};



export default Images;
