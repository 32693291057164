import React,
{
  useEffect,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import Navegar from '../../../components/Navegar';
import Toolbar from '../../../components/Toolbar';

import {
  EDrawerMenu,
} from '../../../shared/enums';

import {
  APP_MENU_SELECTED_ACTION,
} from '../../../store/reducers/app.store';

// import { Container } from './styles';



const ProjetosScreen: React.FC = () => {
  const dispatch = useDispatch();



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.PROJECT_MANAGE });
  }, []);



  return (

    <Navegar>

      <Toolbar
        centerContent={
          <p>
            Projetos
          </p>
        }
      />

    </Navegar>

  );
};



export default ProjetosScreen;
