import styled from 'styled-components';

import {
  Form,
} from 'react-bootstrap';

import {
  Colors,
} from '../../shared/constants';



export const CheckBoxButton = styled.a`
  display: flex;
  flex-direction: column;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;

  .form-check-input {
    width: 20px;
    min-width: 20px;
    max-width: 20px;
    height: 20px;
    min-height: 20px;
    max-height: 20px;
    border-color: ${Colors.gray};
	  box-shadow: 0 0 10px 1px rgba(0, 0, 0 , 0.1);
    cursor: pointer;
    
    :checked {
      background-color: ${Colors.primary};
      border-color: ${Colors.primaryLight};
    }
    
    :focus {
      box-shadow: 0 0 0 0.25rem ${Colors.primaryLight};
    }
  }
`;

export const CheckBoxInput = styled(Form.Check)`
  margin-top: 0;
  margin-left: 3px;
  margin-right: 8px;
  color: ${Colors.black};
`;

export const CheckBoxLabel = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  color: ${Colors.black};
  font-size: 15px;
`;
