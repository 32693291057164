import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
} from '../../shared/constants';



interface IProps {
  type?: string;
  textColor?: string;
  textSize?: string;
}



export const FooterStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 25px;
`;

export const ScreenStyle = css`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 20vh;
`;

export const LoadingContainer = styled.div`
  ${(props: IProps) => props.type === 'FOOTER' && FooterStyle};
  ${(props: IProps) => props.type === 'SCREEN' && ScreenStyle};
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const AreaIcon = styled.div`
  width: 60px;
  aspect-ratio: 1;
  display: flex;
  animation: l11-0 2s infinite linear;

  ::before,
  ::after {
    content:"";
    flex:1;
    clip-path:polygon(100% 0,100% 100%,0 50%);
    animation: 
      l11-1 1s infinite linear alternate,
      l11-2 2s infinite linear -.5s;
  }

  ::after {
    --s:-1,-1;
  }

  @keyframes l11-0 {
    0%,49.99% {transform: scaleX(1)  rotate(0deg)}
    50%,100%  {transform: scaleX(-1) rotate(-90deg)}
  }

  @keyframes l11-1 {
    0%,
    5%   {transform:scale(var(--s,1)) translate(0px)   perspective(150px) rotateY(0deg) }
    33%  {transform:scale(var(--s,1)) translate(-10px) perspective(150px) rotateX(0deg) }
    66%  {transform:scale(var(--s,1)) translate(-10px) perspective(150px) rotateX(-180deg)}
    95%,
    100% {transform:scale(var(--s,1)) translate(0px)   perspective(150px) rotateX(-180deg)}
  }

  @keyframes l11-2 {
    0%,49.99% {background:${Colors.secondary}}
    50%,100%  {background:${Colors.primary}}
  }
`;


export const TextLoading = styled.div`
  margin-top: 35px;
  color: ${Colors.grayDark};
  font-size: ${(props: IProps) => props.textSize || 16}px;
  font-weight: bold;
  line-height: ${(props: IProps) => props.textSize + 8 || 24}px;
  text-align: center;
`;
