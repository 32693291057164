const MateriaisFormModel = {
  id: null,
  nome: '',
  categoria: null,
  medida: '',
  isAtivo: 0,
};



export default MateriaisFormModel;
