import axios,
{
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

import {
  ETokenAsync,
} from '../shared/enums';

import AppEnvironments from '../shared/environments';

import {
  getLocalStorageItem,
} from '../shared/utils/local-storage.utils';

import store from '../store';

import {
  USER_LOGGED,
  COMPANY_LOGOUT_STATE,
  USER_SET,
} from '../store/reducers/user.store';



function ApiWS() {
  const baseURL = AppEnvironments().apiUrl;

  let token: string | null;


  const axiosAppServer = axios.create({
    baseURL: baseURL,
  });



  axiosAppServer.interceptors.request.use((config: AxiosRequestConfig) => {
    token = getLocalStorageItem(ETokenAsync.USER_TOKEN);

    if (token) {
      if (config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  });



  axiosAppServer.interceptors.response.use(
    (response: AxiosResponse<any>) => {
      return response;
    },
    (error: any) => {
      if (!error?.response) {
        const response = {
          message: 'Internal server error',
          data: error,
        };

        throw response;
      }

      const { status } = error?.response;

      if (status === 401) {
        store.dispatch({ type: USER_SET, payload: {} });
        store.dispatch({ type: USER_LOGGED, payload: false });
        store.dispatch({ type: COMPANY_LOGOUT_STATE, payload: false });

        // Redirect para Login ...
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
      }

      throw error;
    },
  );

  return axiosAppServer;
};



export default ApiWS();
