import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
  useLocation,
} from 'react-router-dom';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  GetAllInputCategoryMaterials,
} from '../../../../apis/endpoints/materiais-categorias.endpoints';

import {
  DeleteMaterials,
  PostCreateMaterials,
  PutEditMaterials,
} from '../../../../apis/endpoints/materiais.endpoints';

import AlertMessage from '../../../../components/Alerts';
import Checkbox from '../../../../components/Checkbox';
import Container from '../../../../components/Container';
import Content from '../../../../components/Content';

import {
  ButtonBack,
} from '../../../../components/Controls';

import {
  FormContainerView,
  FormButtonAction,
  FormRowEndButtonsContainer,
  FormToolberButtonApp,
  FormContainerPadder,
  FormInputsContainer,
} from '../../../../components/Filter/filter-content';

import {
  IconBack,
  IconCheck,
  IconDelete,
} from '../../../../components/Icons';

import InputDropdown from '../../../../components/Inputs/InputDropdown';
import InputText from '../../../../components/Inputs/InputText';

import {
  IToast,
} from '../../../../components/IToast';

import LoadingProgress from '../../../../components/Loadings';
import Screen from '../../../../components/Screen';
import RequiredText from '../../../../components/TextRequired';
import Title from '../../../../components/Title';
import Toolbar from '../../../../components/Toolbar';
import ToolbarGroup from '../../../../components/ToolbarGroup';

import {
  Colors,
  Sizes,
  Values,
} from '../../../../shared/constants';

import {
  EHttpStatusCode,
} from '../../../../shared/enums';

import {
  ICategoriasMateriais,
  IDropdownItem,
  IMateriais,
  IResponse,
} from '../../../../shared/interfaces';

import MateriaisFormModel from '../../../../shared/models/Materiais/materiais-form.model';

import {
  stringCapitalize,
} from '../../../../shared/utils/string.utils';

import MateriaisFormValidation from '../../../../shared/validations/Materiais/materiais-form.validations';

import {
  APP_MODAL_LOADING_ACTION,
} from '../../../../store/reducers/app.store';



export interface IRouteLocation {
  edit: boolean;
  item: IMateriais;
}



const MateriaisFormsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const routeLocation = useLocation<IRouteLocation>();
  const params = routeLocation?.state;


  const formik = useRef<FormikProps<typeof MateriaisFormModel | null>>(null);

  const editForm = params?.edit;
  const [itemToEdit, setItemToEdit] = useState<IMateriais>(params?.item);

  const [listCategorias, setListCategorias] = useState<Array<IDropdownItem> | null>(null);
  const [categoriasInput, setCategoriasInput] = useState<IDropdownItem | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);

  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);



  async function getCategoriasFunction() {
    try {
      const response: IResponse = await GetAllInputCategoryMaterials();
      const responseData: ICategoriasMateriais = response?.data;

      if (!responseData || !Array.isArray(responseData)) {
        setListCategorias(null);
        setCategoriasInput(null);
        return;
      }

      const newData: Array<IDropdownItem> = responseData?.map((item: ICategoriasMateriais) => {
        return {
          key: String(item?.id),
          label: item?.nome,
          value: item?.id,
        };
      });

      setListCategorias(newData);
    }
    catch (error: any) {
      console.error(error);

      setListCategorias(null);
      setCategoriasInput(null);
    }
  };



  async function updateForm() {
    setLoading(true);

    await getCategoriasFunction();

    if (editForm && formik.current) {
      formik.current?.setFieldValue('nome', itemToEdit?.nome);
      formik.current?.setFieldValue('categoria', itemToEdit?.categoria);
      formik.current?.setFieldValue('medida', itemToEdit?.medida);
      formik.current?.setFieldValue('isAtivo', itemToEdit?.isAtivo ? true : false);

      formik.current?.validateForm();
    }

    setTimeout(() => {
      setLoading(false);
      setMounted(true);
    }, Values.mountTime);
  }


  function resetForm() {
    setMounted(false);

    setCategoriasInput(null);
    formik.current?.resetForm();

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  };


  function mountPayload(values: typeof MateriaisFormModel) {
    const payload = {
      nome: stringCapitalize(values?.nome) || '',
      categoria: values?.categoria,
      medida: values?.medida,
      isAtivo: values?.isAtivo ? 1 : 0,
    } as typeof MateriaisFormModel;

    return payload;
  }


  async function createItemFunction(values: typeof MateriaisFormModel) {
    const payload = mountPayload(values);

    const response: IResponse = await PostCreateMaterials(payload);
    const responseStatus = response?.status;

    if (responseStatus === EHttpStatusCode.OK) {
      IToast({
        type: 'success',
        message: 'Item salvo com sucesso',
      });
      resetForm();
    }
    else {
      console.error('Error', response);
      throw new Error('Erro ao criar item');
    }
  }


  async function editItemFunction(values: typeof MateriaisFormModel) {
    const payload = mountPayload(values);

    const itemToSave = {
      id: itemToEdit?.id,
      ...payload,
    } as typeof MateriaisFormModel;

    setItemToEdit(itemToSave);

    const response: IResponse = await PutEditMaterials(String(itemToEdit?.id), payload);
    const responseStatus = response?.status;

    if (responseStatus === EHttpStatusCode.OK) {
      IToast({
        type: 'success',
        message: 'Item salvo com sucesso',
      });
    }
    else {
      console.error('Error', response);
      throw new Error('Erro ao editar item');
    }
  }


  async function saveFormFunction(values: any) {
    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      if (editForm) {
        await editItemFunction(values);
      }
      else {
        await createItemFunction(values);
      }
    }
    catch (error: any) {
      console.error(error);

      IToast({
        type: 'error',
        message: 'Erro interno ao salvar item',
      });
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  async function deleteItemFunction() {
    setDeleteAlert(false);

    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response: IResponse = await DeleteMaterials(String(itemToEdit?.id));
      const responseStatus = response?.status;

      if (responseStatus !== EHttpStatusCode.OK) {
        console.error(response);
        IToast({
          type: 'error',
          message: 'Erro ao deletar item',
        });
        return;
      }

      IToast({
        type: 'success',
        message: 'Item deletado com sucesso',
      });
      history.goBack();
    }
    catch (error: any) {
      console.error(error);

      IToast({
        type: 'error',
        message: 'Erro interno ao deletar item',
      });
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderLoading() {
    return (

      <LoadingProgress
        type={'SCREEN'}
      />

    );
  }



  useEffect(() => {
    updateForm();
  }, [formik]);



  return (

    <Formik
      enableReinitialize
      validateOnMount={true}
      isInitialValid={true}
      innerRef={formik}
      initialValues={MateriaisFormModel}
      validationSchema={MateriaisFormValidation}
      onSubmit={(values) => {
        saveFormFunction(values);
      }}>
      {({
        errors,
        values,
        dirty,
        isValid,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (

        <Screen
          backgroundColor={Colors.appBackground}>

          <Container>

            <Toolbar
              backgroundColor={Colors.toolbarBackground}
              leftIcon={
                <ButtonBack
                  color={Colors.black}
                  size={20}
                />
              }
              centerContent={
                <Title
                  color={Colors.tertiary}>
                  {editForm
                    ? 'Editar materiais'
                    : 'Criar materiais'
                  }
                </Title>
              }
              rightIcon={
                <ToolbarGroup>

                  <FormToolberButtonApp
                    disabled={!dirty || !isValid || loading}
                    title={'Salvar'}
                    backgroundColor={Colors.primaryMedium}
                    iconLeft={
                      <IconCheck
                        color={Colors.white}
                        size={Sizes.iconSize}
                      />
                    }
                    onPress={() => {
                      handleSubmit();
                    }}
                  />


                  {editForm && (
                    <FormToolberButtonApp
                      disabled={loading}
                      title={'Deletar'}
                      backgroundColor={Colors.danger}
                      iconLeft={
                        <IconDelete
                          color={Colors.white}
                          size={Sizes.iconSize}
                        />
                      }
                      onPress={() => {
                        setDeleteAlert(true);
                      }}
                    />
                  )}

                </ToolbarGroup>
              }
            />



            <Content>
              <FormContainerPadder>
                <FormContainerView>

                  {!mounted && renderLoading()}



                  {mounted && (
                    <Container>

                      <FormInputsContainer>

                        <RequiredText>
                          * Campos obrigatórios
                        </RequiredText>



                        <InputText
                          disabled={loading}
                          autoCorrect
                          autoCapitalize={'words'}
                          type={'TEXT'}
                          labelText={'Nome *'}
                          placeholderText={'Nome'}
                          borderColor={errors.nome ? Colors.danger : null}
                          helpText={errors.nome}
                          helpColor={Colors.danger}
                          countLimit={Values.nameItem}
                          value={values.nome}
                          onChange={handleChange('nome')}
                          onBlur={handleBlur('nome')}
                        />



                        <InputDropdown
                          dropArray={listCategorias}
                          disabled={loading}
                          labelText={'Categoria *'}
                          placeholderText={categoriasInput?.label}
                          borderColor={errors.categoria ? Colors.danger : null}
                          helpText={errors.categoria}
                          helpColor={Colors.danger}
                          value={categoriasInput?.value}
                          onChange={(item: IDropdownItem) => {
                            setFieldValue('categoria', item?.value);
                            setCategoriasInput(item);
                          }}
                          onBlur={handleBlur('categoria')}
                        />



                        <InputText
                          disabled={loading}
                          autoCorrect
                          autoCapitalize={'words'}
                          type={'TEXT'}
                          labelText={'Medida (Usada para o cálculo da metragem)'}
                          placeholderText={'Medida'}
                          helpText={errors.medida}
                          countLimit={20}
                          value={values.medida}
                          onChange={handleChange('medida')}
                          onBlur={handleBlur('medida')}
                        />


                        <Checkbox
                          checked={Boolean(values.isAtivo)}
                          title={'Ativo'}
                          onPress={() => {
                            setFieldValue('isAtivo', !values.isAtivo);
                          }}
                        />

                      </FormInputsContainer>



                      <FormRowEndButtonsContainer>

                        <FormButtonAction
                          outline
                          disabled={loading || !mounted}
                          iconLeft={
                            <IconBack
                              color={Colors.primaryMedium}
                              size={16}
                            />
                          }
                          title={'Voltar'}
                          backgroundColor={Colors.primaryMedium}
                          onPress={() => {
                            history.goBack();
                          }}
                        />



                        <FormButtonAction
                          disabled={!dirty || !isValid || loading || !mounted}
                          iconLeft={
                            <IconCheck
                              color={Colors.white}
                              size={16}
                            />
                          }
                          title={'Salvar'}
                          backgroundColor={Colors.primaryMedium}
                          onPress={() => {
                            handleSubmit();
                          }}
                        />

                      </FormRowEndButtonsContainer>

                    </Container>
                  )}

                </FormContainerView>
              </FormContainerPadder>
            </Content>

          </Container>



          <AlertMessage
            visible={deleteAlert}
            title={'Deletar item?'}
            description={'Gostaria de deletar esse item?'}
            cancelText={'Não'}
            cancelPress={() => {
              setDeleteAlert(false);
            }}
            okText={'Sim'}
            okPress={() => {
              deleteItemFunction();
            }}
          />

        </Screen>

      )}
    </Formik>

  );
};



export default MateriaisFormsScreen;
