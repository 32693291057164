import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  Formik,
} from 'formik';

import jwt_decode from 'jwt-decode';
import jwt_encode from 'jwt-encode';

import {
  AccessAppByToken,
} from '../../../apis/endpoints/access-login.endpoints';

import ButtonApp from '../../../components/Buttons/ButtonApp';
import FooterAccess from '../../../components/Footers/FooterAccess';

import {
  IconEmail,
  IconPassword,
} from '../../../components/Icons';

import InputText from '../../../components/Inputs/InputText';

import {
  IToast,
} from '../../../components/IToast';

import LoadingProgress from '../../../components/Loadings';

import NameRoutes from '../../../navigation/names';

import {
  Colors,
  Images,
  Values,
} from '../../../shared/constants';

import {
  IResponse,
} from '../../../shared/interfaces';

import LoginModel from '../../../shared/models/Login/login.model';

import Seo from '../../../shared/seo';

import {
  removeLocalStorageItem,
  setLocalStorageItem,
} from '../../../shared/utils/local-storage.utils';

import LoginValidation from '../../../shared/validations/Login/login.validations';

import {
  RootState,
} from '../../../store';

import {
  USER_LOGGED,
  USER_SET,
  USER_TOKEN,
} from '../../../store/reducers/user.store';

import {
  LoginBody,
  OverlayLogin,
  LoginContainer,
  LoginFormView,
  LoginView,
  LogoImgContent,
  LogoImg,
  LoginTextsContent,
  WelcomeText,
  LoginInputsContent,
  ForgetPassTextStyle,
  ForgetPassButton,
} from './styled';



const LoginScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);


  const [loading, setLoading] = useState(false);



  function clearAll() {
    try {
      dispatch({ type: USER_SET, payload: null });

      removeLocalStorageItem(USER_TOKEN);
      removeLocalStorageItem(USER_SET);
    }
    catch (err) {
      console.log(`Clear Error: ${err}`);
    }
  }


  async function AuthenticateUser(data: any) {
    setLoading(true);

    try {
      const tokenData = jwt_encode(data, process.env.REACT_APP_SECRET_API);
      const payload = { token: tokenData };

      const response: IResponse = await AccessAppByToken(payload);
      const responseData = response?.data;

      if (!responseData?.token) {
        IToast({
          type: 'error',
          message: 'Erro ao receber os dados de acesso.',
        });

        return;
      }

      // Header
      const token = responseData?.token;

      let userDataDecode = {
        id: '',
        email: '',
        name: '',
        clienteEmpresa: 0,
        perfil: 0,
        iat: 0,
        exp: 0,
      };

      userDataDecode = jwt_decode(token);

      const userData = {
        id: userDataDecode.id,
        email: userDataDecode.email,
        name: userDataDecode.name,
        clienteEmpresa: userDataDecode.clienteEmpresa,
        perfil: userDataDecode.perfil,
        iat: userDataDecode.iat,
        exp: userDataDecode.exp,
      };

      const tokenObj = { id: userDataDecode.id, token: token };
      dispatch({ type: USER_TOKEN, payload: tokenObj });
      setLocalStorageItem(USER_TOKEN, tokenObj);

      dispatch({ type: USER_SET, payload: userData });
      dispatch({ type: USER_LOGGED, payload: true });
    }
    catch (error: any) {
      console.error('Error login', error);

      IToast({
        type: 'error',
        message: 'Erro ao entrar!',
      });

      clearAll();
    }
    finally {
      setLoading(false);
    }
  };


  function onOpenHomeScreen() {
    history.replace(NameRoutes.HomeScreen);
  }


  async function redirectToHome() {
    if (loggedIn) {
      onOpenHomeScreen();
    }
  }



  useEffect(() => {
    redirectToHome();
  }, [loggedIn]);


  useEffect(() => {
    redirectToHome();
  }, []);



  return (

    <Formik
      enableReinitialize
      validateOnMount
      initialValues={LoginModel}
      validationSchema={LoginValidation}
      onSubmit={(values) => {
        AuthenticateUser(values);
      }}>
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
      }) => (

        <LoginBody>

          <Helmet>
            <title>
              {`${'Acesso'} - ${Seo.companyName}`}
            </title>
          </Helmet>



          <OverlayLogin />



          <LoginContainer>

            <LoginFormView
              onSubmit={handleSubmit}>

              <LogoImgContent>
                <LogoImg
                  alt={'Logo'}
                  src={Images.logoFullWhiteApp}
                />
              </LogoImgContent>


              {loading && (
                <LoginView>
                  <LoadingProgress
                    type={'SCREEN'}
                  />
                </LoginView>
              )}


              {!loading && (
                <LoginView>

                  <LoginTextsContent>

                    <WelcomeText>
                      Informe seus dados de acesso para prosseguir.
                    </WelcomeText>

                  </LoginTextsContent>


                  <LoginInputsContent>

                    <InputText
                      disabled={loading}
                      autoCorrect={false}
                      type={'EMAIL'}
                      iconLeft={
                        <IconEmail
                          color={Colors.primaryDark}
                          size={18}
                        />
                      }
                      labelText={'E-mail'}
                      placeholderText={'E-mail'}
                      helpText={errors.email}
                      helpColor={Colors.danger}
                      countLimit={Values.emailMaxCount}
                      value={values.email}
                      onChange={handleChange('email')}
                      onBlur={handleBlur('email')}
                    />


                    <InputText
                      password
                      disabled={loading}
                      type={'PASSWORD'}
                      iconLeft={
                        <IconPassword
                          color={Colors.primaryDark}
                          size={18}
                        />
                      }
                      labelText={'Senha'}
                      placeholderText={'Senha'}
                      helpText={errors.password}
                      helpColor={Colors.danger}
                      countLimit={Values.passwordMaxCount}
                      value={values.password}
                      onChange={handleChange('password')}
                      onBlur={handleBlur('password')}
                    />

                  </LoginInputsContent>


                  <ButtonApp
                    title={'Entrar'}
                    backgroundColor={Colors.primary}
                    onPress={() => {
                      handleSubmit();
                    }}
                  />


                  <ForgetPassButton
                    onClick={() => {
                      history.push(NameRoutes.RecoveryPasswordScreen);
                    }}>
                    <ForgetPassTextStyle>
                      Esqueci minha senha
                    </ForgetPassTextStyle>
                  </ForgetPassButton>

                </LoginView>
              )}

            </LoginFormView>



            <FooterAccess
              textColor={Colors.white}
            />

          </LoginContainer>

        </LoginBody>

      )}
    </Formik>

  );
};



export default LoginScreen;
