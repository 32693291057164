import styled from 'styled-components';

import {
  Colors,
} from '../../../../shared/constants';



export const TextChangePassword = styled.span`
  color: ${Colors.primary};
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
`;

export const SettingsHelpText = styled.p`
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0px;
  color: ${Colors.black};
  font-size: 14px;
  text-align: center;
`;

export const SettingsHelpSubText = styled.p`
  margin-top: 25px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  color: ${Colors.black};
  font-size: 14px;
  text-align: center;
`;
