export const METAS = {
  companyName:                      'KW Brasil',
};



const Seo = {
  ...METAS,
};



export default Seo;
