import styled from 'styled-components';

import {
  Colors,
} from '../../shared/constants';



export interface IProps {
  flexDirection?: string;
  width?: number;
  height?: number;
  backgroundColor?: string;
  color?: string;
  borderRadius?: number;
}



export const LoadingProgressStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 220px;
  height: 220px;
  padding-top: 15px;
  padding-left: 50px;
  padding-right: 50px;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.alertBackground};
  box-shadow: rgba(0, 0, 0, 0.6) 0px 2px 5px;
`;
