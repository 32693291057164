import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
  useLocation,
} from 'react-router-dom';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  DeleteClientesContratos,
  GetClientesContratosListAll,
} from '../../../../apis/endpoints/clientes-contratos.endpoints';

import AlertMessage from '../../../../components/Alerts';
import Body from '../../../../components/Body';
import ButtonTableIcon from '../../../../components/Buttons/ButtonTableIcon';
import Container from '../../../../components/Container';
import Content from '../../../../components/Content';

import {
  ContentPadder,
} from '../../../../components/ContentPadder';

import {
  ButtonBack,
} from '../../../../components/Controls';

import EmptyContent from '../../../../components/Empty';

import {
  FormListInputsView,
  FormFiltersButtonView,
} from '../../../../components/Filter/filter-content';

import {
  IconAdd,
  IconEdit,
  IconErase,
  IconFilter,
  IconDelete,
} from '../../../../components/Icons';

import InputDropdown from '../../../../components/Inputs/InputDropdown';
import InputText from '../../../../components/Inputs/InputText';

import {
  IToast,
} from '../../../../components/IToast';

import LoadingProgress from '../../../../components/Loadings';
import Screen from '../../../../components/Screen';
import SubTitle from '../../../../components/SubTitle';
import TableCuston from '../../../../components/Table';
import Title from '../../../../components/Title';
import Toolbar from '../../../../components/Toolbar';

import NameRoutes from '../../../../navigation/names';

import {
  AllItems,
  listStatus,
} from '../../../../shared/arrays';

import {
  Colors,
  Images,
  Messages,
  Sizes,
  Values,
} from '../../../../shared/constants';

import {
  EHttpStatusCode,
} from '../../../../shared/enums';

import {
  IDropdownItem,
  IClientesContratos,
  IResponse,
  IClientes,
  IRetornoClientesContratos,
} from '../../../../shared/interfaces';

import ClientesContratosFiltroModel from '../../../../shared/models/Clientes/clientes-contratos-filtro.model';

import {
  returnErrorObject,
} from '../../../../shared/utils/error.utils';

import {
  validateString,
} from '../../../../shared/utils/string.utils';

import ClientesContratosFiltroValidation from '../../../../shared/validations/Clientes/clientes-contratos-filtro.validations';

import {
  APP_MODAL_LOADING_ACTION,
} from '../../../../store/reducers/app.store';

import {
  ActionButtonApp,
  FilterButtonApp,
} from './styled';



export interface IRouteLocation {
  item: IClientes;
}



const ContratosClientesScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const routeLocation = useLocation<IRouteLocation>();
  const params = routeLocation?.state;


  const formik = useRef<FormikProps<typeof ClientesContratosFiltroModel | null>>(null);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Nenhum contrato aqui!',
    description: 'Não há nenhum contrato aqui',
  });

  const fullStatusArray = [
    AllItems,
    ...listStatus,
  ];

  const [titleScreen, setTitleScreen] = useState<string>(params?.item?.nomeFantasia || '-');

  const [statusInput, setStatusInput] = useState<IDropdownItem | null>(fullStatusArray[0]);

  const [arrayDataList, setArrayDataList] = useState<Array<IClientesContratos>>([]);

  const [openDeleteModal, setOpenDeleteModal] = useState<IClientesContratos | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [mouted, setMounted] = useState<boolean>(false);



  async function getListAllFunction(payloadReceived?: typeof ClientesContratosFiltroModel, openLoadingModal = false) {
    let payload: typeof ClientesContratosFiltroModel | undefined;

    if (payloadReceived) {
      payload = {
        numeroContrato: validateString(payloadReceived?.numeroContrato) ? payloadReceived?.numeroContrato : null,
        isAtivo: payloadReceived?.isAtivo,
      };
    }

    try {
      setLoading(true);

      if (openLoadingModal) {
        dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });
      }

      const response: IResponse = await GetClientesContratosListAll(payload);
      const responseData: IRetornoClientesContratos = response?.data;

      if (!responseData || !Array.isArray(responseData?.lista)) {
        setArrayDataList([]);
        return;
      }

      setArrayDataList(responseData?.lista);
      setTitleScreen(responseData?.clienteEmpresa?.nomeFantasia);
    }
    catch (error: any) {
      console.error(error);
      setArrayDataList([]);

      setEmptyObject(returnErrorObject(error?.response?.status));
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  };


  async function getFirstTimeFunction() {
    await getListAllFunction();
    setMounted(true);
  };


  async function postFilter(values: typeof ClientesContratosFiltroModel) {
    await getListAllFunction(values, true);
  };


  async function deleteItemSelected() {
    setOpenDeleteModal(null);

    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response: IResponse = await DeleteClientesContratos(String(openDeleteModal?.id));

      if (response?.status !== EHttpStatusCode.OK) {
        console.error('Error', response);
        IToast({
          type: 'error',
          message: 'Erro ao deletar item',
        });
        return;
      }

      IToast({
        type: 'success',
        message: 'Item deletado com sucesso!',
      });
      await getListAllFunction();
    }
    catch (error: any) {
      console.error(error);

      IToast({
        type: 'error',
        message: 'Erro interno',
      });
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function resetForm() {
    formik.current?.resetForm();

    formik.current?.setFieldValue('isAtivo', fullStatusArray[0]?.value);
    setStatusInput(fullStatusArray[0]);
  };


  function renderLoading() {
    return (

      <LoadingProgress
        type={'SCREEN'}
      />

    );
  }



  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      formatter: (cell: string) => {
        return cell || '-';
      },
    },
    {
      dataField: 'numeroContrato',
      text: 'Número do Contrato',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      formatter: (cell: string) => {
        return cell || '-';
      },
    },
    {
      dataField: 'isAtivo',
      text: 'Ativo',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      formatter: (cell: number) => {
        return cell ? 'Sim' : 'Não';
      },
    },
    {
      dataField: 'EDIT',
      text: 'Editar',
      csvExport: false,
      headerStyle: () => {
        return { width: '5%' };
      },
      formatter: (_cell: null, row: IClientesContratos, index: number): JSX.Element => {
        return (
          <ButtonTableIcon
            key={index}
            tooltip={Messages.EDIT}
            icon={
              <IconEdit
                color={Colors.black}
                size={16}
              />
            }
            onPress={() => {
              history.push({
                pathname: NameRoutes.ContratosClientesFormsScreen,
                state: {
                  edit: true,
                  item: row,
                },
              });
            }}
          />
        );
      },
    },
    {
      dataField: 'REMOVE',
      text: 'Remover',
      csvExport: false,
      headerStyle: () => {
        return { width: '5%' };
      },
      formatter: (_cell: null, row: IClientesContratos, index: number): JSX.Element => {
        return (
          <ButtonTableIcon
            key={index}
            tooltip={Messages.REMOVE}
            icon={
              <IconDelete
                color={Colors.danger}
                size={16}
              />
            }
            onPress={() => {
              setOpenDeleteModal(row);
            }}
          />
        );
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: arrayDataList?.length,
  };



  useEffect(() => {
    getFirstTimeFunction();
  }, []);



  return (

    <Formik
      enableReinitialize
      validateOnMount
      innerRef={formik}
      initialValues={ClientesContratosFiltroModel}
      validationSchema={ClientesContratosFiltroValidation}
      onSubmit={(values) => {
        postFilter(values);
      }}>
      {({
        errors,
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (

        <Screen
          backgroundColor={Colors.appBackground}>

          <Container>

            <Toolbar
              leftIcon={
                <ButtonBack
                  color={Colors.black}
                  size={20}
                />
              }
              centerContent={
                <Body>
                  <Title
                    color={Colors.tertiary}>
                    {titleScreen}
                  </Title>

                  <SubTitle
                    color={Colors.tertiary}>
                    Contratos
                  </SubTitle>
                </Body>
              }
              rightIcon={
                <ActionButtonApp
                  title={'Novo contrato'}
                  backgroundColor={Colors.primaryMedium}
                  iconLeft={
                    <IconAdd
                      color={Colors.white}
                      size={Sizes.iconSize}
                    />
                  }
                  onPress={() => {
                    history.push({
                      pathname: NameRoutes.ContratosClientesFormsScreen,
                    });
                  }}
                />
              }
            />



            {!mouted && renderLoading()}



            {mouted && (
              <Content>
                <ContentPadder>

                  <FormListInputsView
                    auto={true}>

                    <Row>

                      <Col xs={6}>
                        <InputText
                          disabled={loading}
                          autoCorrect
                          autoCapitalize={'words'}
                          type={'TEXT'}
                          labelText={'Número do contrato'}
                          placeholderText={'Número do contrato'}
                          borderColor={errors.numeroContrato ? Colors.danger : null}
                          helpText={errors.numeroContrato}
                          countLimit={Values.nameItem}
                          value={values.numeroContrato}
                          onChange={handleChange('numeroContrato')}
                          onBlur={handleBlur('numeroContrato')}
                        />
                      </Col>


                      <Col xs={6}>
                        <InputDropdown
                          dropArray={fullStatusArray}
                          disabled={loading}
                          labelText={'Status'}
                          placeholderText={statusInput?.label}
                          value={statusInput?.value}
                          onChange={(item: IDropdownItem) => {
                            setFieldValue('isAtivo', item?.value);
                            setStatusInput(item);
                          }}
                          onBlur={handleBlur('isAtivo')}
                        />
                      </Col>



                      <FormFiltersButtonView>

                        <FilterButtonApp
                          outline
                          disabled={loading}
                          iconLeft={
                            <IconErase
                              color={Colors.primaryMedium}
                              size={16}
                            />
                          }
                          title={'Limpar'}
                          backgroundColor={Colors.primaryMedium}
                          onPress={() => {
                            resetForm();
                          }}
                        />


                        <FilterButtonApp
                          disabled={loading}
                          iconLeft={
                            <IconFilter
                              color={Colors.white}
                              size={16}
                            />
                          }
                          title={'Filtrar'}
                          backgroundColor={Colors.primaryMedium}
                          onPress={() => {
                            handleSubmit();
                          }}
                        />

                      </FormFiltersButtonView>

                    </Row>

                  </FormListInputsView>



                  {arrayDataList?.length === 0 && (
                    <EmptyContent
                      image={emptyObject.image}
                      title={emptyObject.title}
                      description={emptyObject.description}
                    />
                  )}



                  {arrayDataList?.length > 0 && (
                    <PaginationProvider
                      pagination={paginationFactory(options)}>
                      {({
                        paginationProps,
                        paginationTableProps,
                      }) => (
                        <TableCuston
                          data={arrayDataList}
                          columns={columns}
                          paginationProps={paginationProps}
                          paginationTableProps={paginationTableProps}
                        />
                      )}
                    </PaginationProvider>
                  )}

                </ContentPadder>
              </Content>
            )}

          </Container>



          <AlertMessage
            visible={Boolean(openDeleteModal)}
            title={'Deseja deletar esse item?'}
            cancelText={'Não'}
            cancelPress={() => {
              setOpenDeleteModal(null);
            }}
            okText={'Sim'}
            okPress={() => {
              deleteItemSelected();
            }}
          />

        </Screen>

      )}

    </Formik>

  );
};



export default ContratosClientesScreen;
