import styled from 'styled-components';

import {
  Colors,
} from '../../shared/constants';

import {
  PlatformDevices,
} from '../../shared/utils/platforms.utils';

import ButtonApp from '../Buttons/ButtonApp';

import {
  ContentPadder,
} from '../ContentPadder';



export interface IProps {
  auto?: boolean;
}



export const FormFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 15px;
  background-color: ${Colors.formBackground};
  box-shadow: 0 0 25px rgba(0, 0, 0, .02);

  ${(props: IProps) => !props.auto && 'min-height: 310px'};
`;


export const FormFiltersButtonView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 5px;
`;



export const FormContainerPadder = styled(ContentPadder)`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all .15s ease;

  @media ${PlatformDevices.minLaptop} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

export const FormListInputsView = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 15px;
  padding: 15px;
  background-color: ${Colors.formBackground};
  box-shadow: 0 0 25px rgba(0, 0, 0, .02);

  ${(props: IProps) => !props.auto && 'min-height: 310px'};
`;

export const FormContainerView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  width: 100%;
  max-width: 1528px;
  margin-bottom: 0;
  background-color: ${Colors.formBackground};
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 5px;
`;



export const FormInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 15px;
`;

export const FormToolberButtonApp = styled(ButtonApp)`
  height: 100%;
  width: 120px;
  max-width: 120px;
`;

export const FormButtonAction = styled(ButtonApp)`
  min-width: 160px;

  @media ${PlatformDevices.maxTabletM} {
    flex: 1;
    min-width: 120px;
  }
`;


export const FormRowEndButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: 12px;
  padding: 15px;
  background-color: ${Colors.white};
  border-top-color: ${Colors.grayLight};
  border-top-style: solid;
  border-top-width: 1px;
`;



export const FormsSectionView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 6px;
`;

export const FormsInputSectionView = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
`;

export const FormsInputMoreMarginSectionView = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;
`;



export const FooterDescription = styled.p`
  margin-bottom: 15px;
  color: #696E7D;
  font-style: italic;
  font-size: 13px;
`;
