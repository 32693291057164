import styled from 'styled-components';

import {
  Form,
} from 'formik';

import Screen from '../../../components/Screen';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export const LoginBody = styled(Screen)`
  background-color: ${Colors.primaryDark};
`;

export const OverlayLogin = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  right: 0;
  height: 100%;
`;

export const LoginFormView = styled(Form)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 90%;
  max-width: 450px;
  height: 510px;
  background-color: ${Colors.menuBackground};
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
`;

export const LoginView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
`;

export const LogoImgContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 140px;
  background-color: ${Colors.primary};
`;

export const LogoImg = styled.img`
  width: 180px;
  height: 100px;
  object-fit: contain;
`;

export const LoginTextsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
`;

export const WelcomeText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  color: ${Colors.black};
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`;

export const LoginInputsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 15px;
`;

export const ForgetPassTextStyle = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  color: ${Colors.primaryMedium};
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all .15s ease;
`;

export const ForgetPassButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${Sizes.buttonHeight}px;
  margin-top: 10px;

  &:hover ${ForgetPassTextStyle} {
    color:${Colors.tertiary};
  }
`;
