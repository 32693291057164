import styled,
{
  css,
} from 'styled-components';

import {
  Dropdown,
} from 'react-bootstrap';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import Content from '../../Content';



export interface IProps {
  expanded?: boolean;
  selected?: boolean;
  paddingLeft?: boolean;
  paddingRight?: boolean;
  wrapView?: boolean;
  inputTextCenter?: boolean;
  multiline?: boolean;
  backgroundColor?: string;
  color?: string;
  placeholderTextColor?: string;
  borderColor?: string;
  borderWidth?: number;
  opacity?: number;
  noShadow?: boolean;
}



// InputView

export const InputDrowpdownMaster = styled.div`
  display: block;
  position: relative;
`;


export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  z-index: 0;
`;



export const BackgroundStyle = css`
  background-color: ${(props: IProps) => props.backgroundColor || Colors.white};
`;


export const WrapStyle = css`
  flex-wrap: wrap;
`;

export const ShadowStyle = css`
	box-shadow: 0 0 10px 1px rgba(0, 0, 0 , 0.1);
`;

export const InputViewStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  height: ${Sizes.inputHeight}px;
  margin-top: 3px;
  margin-bottom: 2px;
  opacity: ${(props: IProps) => props.opacity};

  border-color: ${(props: IProps) => props.borderColor || Colors.grayLight};
  border-style: solid;
  border-width: ${(props: IProps) => props.borderWidth || 1}px;

  ${(props: IProps) => props.wrapView && WrapStyle};
  ${(props: IProps) => props.backgroundColor && BackgroundStyle};
  ${(props: IProps) => !props.noShadow && ShadowStyle};
`;



// Dropdown

export const DropdownTextStyle = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: 35px;
  color: ${Colors.attentionDark};
  font-size: 14px;
`;

export const DropdownToggle = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-left: 10px;
  padding-right: 35px;
`;

export const DropdownTextToggle = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  color: ${(props: IProps) => props.color || Colors.black};
  font-size: 14px;
`;

export const DropdownMenu = styled(Content)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 72%;
  width: 100%;
  max-height: 175px;
  margin-top: 3px;
  padding-top: 0;
  padding-bottom: 0;
  background-color: ${(props: IProps) => props.backgroundColor || Colors.white};
  border-color: ${(props: IProps) => props.borderColor || Colors.grayLight};
  border-style: solid;
  border-width: ${(props: IProps) => props.borderWidth || 1}px;
  border-radius: 0;
  z-index: 1000;
  
  ${(props: IProps) => !props.noShadow && ShadowStyle};
`;

export const DropdownSelect = styled(Dropdown)`
  width: 100%;
  height: 100%;
  
  .btn-primary {
    border: none;
    color: ${Colors.black};
    background-color: ${(props: IProps) => props.backgroundColor || Colors.white} !important;
    font-size: 14px;
    font-family: "Nunito Sans", sans-serif !important;
    text-align: left;

    :focus{
      color: ${Colors.black};
      box-shadow: none !important;
    }
  }
  
  #dropdown-basic {
    width: 100%;
    height: 100%;
    border-radius: 0;

    :after{
      display: none;
    }
  }

  .dropdown-menu {
    a {
      height: 30px;
      padding-top: 4.5px;
      font-family: "Nunito Sans", sans-serif !important;
    }
  }
`;


export const OptionStyled = styled(Dropdown.Item)`
  color: ${(props: IProps) => props.selected
    ? Colors.white
    : Colors.black
  } !important;

  background-color: ${(props: IProps) => props.selected
    ? Colors.primary
    : Colors.transparent
  };

  :focus{
    color: ${Colors.black};
  }

  :hover {
    color: ${Colors.black};

    background-color: ${(props: IProps) => props.selected
    ? Colors.primary
    : '#e9ecef'
  };
  }
`;

export const OptionTextStyled = styled.span`
  display: block;           // Garantir que o texto seja tratado como um bloco
  max-width: 100%;          // Definir o máximo de largura possível
  white-space: nowrap;      // Impedir quebra de linha
  overflow: hidden;         // Esconder o que passar da largura máxima
  text-overflow: ellipsis;  // Mostrar as reticências
`;

export const IconFlipExpanded = css`
  transform: rotate(180deg);
`;

export const SelectArrowView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 4px;
  width: 30px;
  height: ${Sizes.inputHeight}px;
  pointer-events: none;
  transition: all .15s ease;

  ${(props: IProps) => props.expanded && IconFlipExpanded};
`;
