import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  DeleteCategoryMaterials,
  GetCategoryMaterialsListAll,
} from '../../../apis/endpoints/materiais-categorias.endpoints';

import AlertMessage from '../../../components/Alerts';
import ButtonTableIcon from '../../../components/Buttons/ButtonTableIcon';
import Content from '../../../components/Content';

import {
  ContentPadder,
} from '../../../components/ContentPadder';

import EmptyContent from '../../../components/Empty';

import {
  FormListInputsView,
  FormFiltersButtonView,
} from '../../../components/Filter/filter-content';

import {
  IconAdd,
  IconEdit,
  IconErase,
  IconFilter,
  IconDelete,
} from '../../../components/Icons';

import InputDropdown from '../../../components/Inputs/InputDropdown';
import InputText from '../../../components/Inputs/InputText';

import {
  IToast,
} from '../../../components/IToast';

import LoadingProgress from '../../../components/Loadings';
import Navegar from '../../../components/Navegar';
import Screen from '../../../components/Screen';
import TableCuston from '../../../components/Table';
import Title from '../../../components/Title';
import Toolbar from '../../../components/Toolbar';

import NameRoutes from '../../../navigation/names';

import {
  AllItems,
  listStatus,
} from '../../../shared/arrays';

import {
  Colors,
  Images,
  Messages,
  Sizes,
  Values,
} from '../../../shared/constants';

import {
  EDrawerMenu,
  EHttpStatusCode,
} from '../../../shared/enums';

import {
  ICategoriasMateriais,
  IDropdownItem,
  IResponse,
} from '../../../shared/interfaces';

import CategoriasMateriaisFiltroModel from '../../../shared/models/Materiais/categorias-materiais-filtro.model';

import {
  returnErrorObject,
} from '../../../shared/utils/error.utils';

import {
  validateString,
} from '../../../shared/utils/string.utils';

import CategoriasMateriaisFiltroValidation from '../../../shared/validations/Materiais/categorias-materiais-filtro.validations';

import {
  APP_MENU_SELECTED_ACTION,
  APP_MODAL_LOADING_ACTION,
} from '../../../store/reducers/app.store';

import {
  ActionButtonApp,
  FilterButtonApp,
} from './styled';



const MateriaisCategoriasScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  const formik = useRef<FormikProps<typeof CategoriasMateriaisFiltroModel | null>>(null);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Nenhuma categoria de material aqui!',
    description: 'Não há nenhuma categoria de material aqui',
  });

  const fullStatusArray = [
    AllItems,
    ...listStatus,
  ];

  const [statusInput, setStatusInput] = useState<IDropdownItem | null>(fullStatusArray[0]);

  const [arrayDataList, setArrayDataList] = useState<Array<ICategoriasMateriais>>([]);

  const [openDeleteModal, setOpenDeleteModal] = useState<ICategoriasMateriais | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [mouted, setMounted] = useState<boolean>(false);



  async function getListAllFunction(payloadReceived?: typeof CategoriasMateriaisFiltroModel, openLoadingModal = false) {
    let payload: typeof CategoriasMateriaisFiltroModel | undefined;

    if (payloadReceived) {
      payload = {
        nome: validateString(payloadReceived?.nome) ? payloadReceived?.nome : null,
        isAtivo: payloadReceived?.isAtivo,
      };
    }

    try {
      setLoading(true);

      if (openLoadingModal) {
        dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });
      }

      const response: IResponse = await GetCategoryMaterialsListAll(payload);
      const responseData: ICategoriasMateriais = response?.data;

      if (!responseData || !Array.isArray(responseData)) {
        setArrayDataList([]);
        return;
      }

      setArrayDataList(responseData);
    }
    catch (error: any) {
      console.error(error);
      setArrayDataList([]);

      setEmptyObject(returnErrorObject(error?.response?.status));
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  };


  async function getFirstTimeFunction() {
    await getListAllFunction();
    setMounted(true);
  };


  async function postFilter(values: typeof CategoriasMateriaisFiltroModel) {
    await getListAllFunction(values, true);
  };


  async function deleteItemSelected() {
    setOpenDeleteModal(null);

    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response: IResponse = await DeleteCategoryMaterials(String(openDeleteModal));

      if (response?.status !== EHttpStatusCode.OK) {
        console.error('Error', response);
        IToast({
          type: 'error',
          message: 'Erro ao deletar item',
        });
        return;
      }

      IToast({
        type: 'success',
        message: 'Item deletado com sucesso!',
      });
      await getListAllFunction();
    }
    catch (error: any) {
      console.error(error);

      IToast({
        type: 'error',
        message: 'Erro interno',
      });
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function resetForm() {
    formik.current?.resetForm();

    formik.current?.setFieldValue('isAtivo', fullStatusArray[0]?.value);
    setStatusInput(fullStatusArray[0]);
  };


  function renderLoading() {
    return (

      <LoadingProgress
        type={'SCREEN'}
      />

    );
  }



  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      formatter: (cell: string, _row: ICategoriasMateriais) => {
        return cell || '-';
      },
    },
    {
      dataField: 'nome',
      text: 'Nome',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      formatter: (cell: string, _row: ICategoriasMateriais) => {
        return cell || '-';
      },
    },
    {
      dataField: 'useRdc',
      text: '	Usar no cálculo de metragem do RDC?',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      formatter: (cell: boolean, _row: ICategoriasMateriais) => {
        return cell ? 'Sim' : 'Não';
      },
    },
    {
      dataField: 'isAtivo',
      text: 'Ativo',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      formatter: (cell: number) => {
        return cell ? 'Sim' : 'Não';
      },
    },
    {
      dataField: 'EDIT',
      text: 'Editar',
      csvExport: false,
      headerStyle: () => {
        return { width: '5%' };
      },
      formatter: (_cell: null, row: ICategoriasMateriais, index: number): JSX.Element => {
        return (
          <ButtonTableIcon
            key={index}
            tooltip={Messages.EDIT}
            icon={
              <IconEdit
                color={Colors.black}
                size={16}
              />
            }
            onPress={() => {
              history.push({
                pathname: NameRoutes.MateriaisCategoriasFormsScreen,
                state: {
                  edit: true,
                  item: row,
                },
              });
            }}
          />
        );
      },
    },
    {
      dataField: 'REMOVE',
      text: 'Remover',
      csvExport: false,
      headerStyle: () => {
        return { width: '5%' };
      },
      formatter: (_cell: null, row: ICategoriasMateriais, index: number): JSX.Element => {
        return (
          <ButtonTableIcon
            key={index}
            tooltip={Messages.REMOVE}
            icon={
              <IconDelete
                color={Colors.danger}
                size={16}
              />
            }
            onPress={() => {
              setOpenDeleteModal(row);
            }}
          />
        );
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: arrayDataList?.length,
  };



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.MATERIALS_CATEGORIES });
    getFirstTimeFunction();
  }, []);



  return (

    <Formik
      enableReinitialize
      validateOnMount
      innerRef={formik}
      initialValues={CategoriasMateriaisFiltroModel}
      validationSchema={CategoriasMateriaisFiltroValidation}
      onSubmit={(values) => {
        postFilter(values);
      }}>
      {({
        errors,
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (

        <Screen>

          <Navegar>

            <Toolbar
              centerContent={
                <Title
                  color={Colors.tertiary}>
                  Categorias de materiais
                </Title>
              }
              rightIcon={
                <ActionButtonApp
                  title={'Nova categoria'}
                  backgroundColor={Colors.primaryMedium}
                  iconLeft={
                    <IconAdd
                      color={Colors.white}
                      size={Sizes.iconSize}
                    />
                  }
                  onPress={() => {
                    history.push({
                      pathname: NameRoutes.MateriaisCategoriasFormsScreen,
                    });
                  }}
                />
              }
            />



            {!mouted && renderLoading()}



            {mouted && (
              <Content>
                <ContentPadder>

                  <FormListInputsView
                    auto={true}>

                    <Row>

                      <Col xs={6}>
                        <InputText
                          disabled={loading}
                          autoCorrect
                          autoCapitalize={'words'}
                          type={'TEXT'}
                          labelText={'Nome'}
                          placeholderText={'Nome'}
                          borderColor={errors.nome ? Colors.danger : null}
                          helpText={errors.nome}
                          countLimit={Values.nameItem}
                          value={values.nome}
                          onChange={handleChange('nome')}
                          onBlur={handleBlur('nome')}
                        />
                      </Col>


                      <Col xs={6}>
                        <InputDropdown
                          dropArray={fullStatusArray}
                          disabled={loading}
                          labelText={'Status'}
                          placeholderText={statusInput?.label}
                          value={statusInput?.value}
                          onChange={(item: IDropdownItem) => {
                            setFieldValue('isAtivo', item?.value);
                            setStatusInput(item);
                          }}
                          onBlur={handleBlur('isAtivo')}
                        />
                      </Col>



                      <FormFiltersButtonView>

                        <FilterButtonApp
                          outline
                          disabled={loading}
                          iconLeft={
                            <IconErase
                              color={Colors.primaryMedium}
                              size={16}
                            />
                          }
                          title={'Limpar'}
                          backgroundColor={Colors.primaryMedium}
                          onPress={() => {
                            resetForm();
                          }}
                        />


                        <FilterButtonApp
                          disabled={loading}
                          iconLeft={
                            <IconFilter
                              color={Colors.white}
                              size={16}
                            />
                          }
                          title={'Filtrar'}
                          backgroundColor={Colors.primaryMedium}
                          onPress={() => {
                            handleSubmit();
                          }}
                        />

                      </FormFiltersButtonView>

                    </Row>

                  </FormListInputsView>



                  {arrayDataList?.length === 0 && (
                    <EmptyContent
                      image={emptyObject.image}
                      title={emptyObject.title}
                      description={emptyObject.description}
                    />
                  )}



                  {arrayDataList?.length > 0 && (
                    <PaginationProvider
                      pagination={paginationFactory(options)}>
                      {({
                        paginationProps,
                        paginationTableProps,
                      }) => (
                        <TableCuston
                          data={arrayDataList}
                          columns={columns}
                          paginationProps={paginationProps}
                          paginationTableProps={paginationTableProps}
                        />
                      )}
                    </PaginationProvider>
                  )}

                </ContentPadder>
              </Content>
            )}

          </Navegar>



          <AlertMessage
            visible={Boolean(openDeleteModal)}
            title={'Deseja deletar esse item?'}
            cancelText={'Não'}
            cancelPress={() => {
              setOpenDeleteModal(null);
            }}
            okText={'Sim'}
            okPress={() => {
              deleteItemSelected();
            }}
          />

        </Screen>

      )}

    </Formik>

  );
};



export default MateriaisCategoriasScreen;
