import styled from 'styled-components';

import {
  Colors,
} from '../../../../shared/constants';



export const CompanyClientTextObs = styled.p`
  margin-top: 8px;
  margin-left: 3px;
  margin-bottom: 10px;
  color: ${Colors.black};
  font-size: 14px;
`;

export const CompanyClientTextSpanObs = styled.span`
  font-weight: bold;
`;
