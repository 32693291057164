import styled,
{
  css,
} from 'styled-components';

import Content from '../../components/Content';

import {
  Colors,
  Sizes,
} from '../../shared/constants';

import {
  PlatformDevices,
} from '../../shared/utils/platforms.utils';


const widthDrawer = 230;


export interface IProps {
  disabled?: boolean;
  selected?: boolean;
  color?: string;
}



export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${widthDrawer}px;
  height: 100%;
  /* border-right: 1px solid hsla(203, 50%, 30%, 0.15);
  box-shadow: 5px 0 20px rgba(0, 53, 62, 0.15) !important; */
`;

export const MenuDrawerContent = styled(Content)`
  overflow-x: hidden;
  background-color: ${Colors.menuBackground};
`;


export const MenuHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.primaryDark};
  padding-top: 30px;

  :after {
    content: "";
    height: 0;
    border-color: ${Colors.primaryDark} ${Colors.primaryDark} ${Colors.menuBackground};
    border-style: solid;
    border-width: 0 0 20px ${widthDrawer}px;
}
`;

export const LogoImgContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 20px;
`;

export const LogoImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const MenuInfoCompanyView = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  padding-left: 13px;
  padding-right: 13px;
`;


export const MenuUserView = styled.p`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  
  :after {
    content: "";
    display: block;
    margin-top: 6px;
    width: 50px;
    height: 2px;
    background-color: ${Colors.secondary};
  }
`;

export const MenuNameUserText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  color: ${Colors.black};
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const MenuEmailUserText = styled.p`
  margin-top: 3px;
  margin-bottom: 0;
  color: ${Colors.gray};
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const DrawerClearFindButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;



export const MenuListContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;


export const MenuItemList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;


export const MenuEpigraphSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 3px;
`;



export const ItemMenuInfoText = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 5;
`;

export const ItemMenuText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  color: ${(props: IProps) => props.color || Colors.black};
  font-size: 14px;
  font-weight: ${(props: IProps) => props.selected
    ? 'bold'
    : 'normal'
  };
  line-height: 18px;
  transition: color 0.25s ease;
`;

export const ItemMenuDisabledText = styled.p`
  margin-top: 1px;
  margin-bottom: 0;
  color: ${(props: IProps) => props.color || Colors.attentionDark};
  font-size: 12px;
  line-height: 13px;
  transition: color 0.25s ease;
`;

export const ItemMenuIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${Sizes.menuContent}px !important;
  width: ${Sizes.menuContent}px !important;
  min-width: ${Sizes.menuContent}px !important;
  max-width: ${Sizes.menuContent}px !important;
  margin-right: 6px;
  z-index: 5;
`;


export const ItemMenuEffect = css`
  
`;

export const ItemMenuContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  height: 48px;
  padding-top: 8px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 8px;
  background-color: ${(props: IProps) => props.disabled
    ? Colors.grayLight
    : props.selected
      ? Colors.secondary
      : Colors.transparent
  };
  z-index: 0;

  @media ${PlatformDevices.minLaptop} {
    ${(props: IProps) => (!props.selected && !props.disabled) && ItemMenuEffect}
  }
`;
