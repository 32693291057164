import styled from 'styled-components';

import {
  Colors,
} from '../../shared/constants';



export const RequiredText = styled.p`
  margin-bottom: 5px;
  color: ${Colors.buttonCancel};
  font-size: 11px;
  text-align: right;
`;



export default RequiredText;
