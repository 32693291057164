import Yup from '../base-validation.yup';



const MotivosCancelamentoFiltroValidation = Yup.object().shape({
  motivo: Yup.string()
    .nullable()
    .min(3, `Precisa conter pelo menos ${3} caracteres`),


  isAtivo: Yup.boolean()
    .nullable(),
});



export default MotivosCancelamentoFiltroValidation;
