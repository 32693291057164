const ColaboradoresFiltroModel = {
  nome: '',
  cpf: '',
  clienteEmpresa: null,
  funcao: null,
  isAtivo: null,
};



export default ColaboradoresFiltroModel;
