import ApiWS from '../index';



export const GetMaterialsListAll = async (payload?: any) => {
  const url = '/app/materiais/buscar';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PostCreateMaterials = async (payload: any) => {
  const url = '/app/materiais/criar';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PutEditMaterials = async (idMaterials: string, payload: any) => {
  const url = `/app/materiais/editar/${idMaterials}`;

  const response = await ApiWS.put(url, payload);
  return response?.data;
};



export const DeleteMaterials = async (idMaterials: string) => {
  const url = `/app/materiais/deletar/${idMaterials}`;

  const response = await ApiWS.delete(url);
  return response?.data;
};
