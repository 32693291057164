import React from 'react';

import {
  OverlayTrigger,
} from 'react-bootstrap';

import {
  TableActionButton,
  TableToltip,
} from './styled';



export interface IProps {
  disabled?: boolean;
  backgroundColor?: any;

  tooltip?: any;
  icon?: any;
  placement?: any;
  trigger?: any;
  overlay?: any;

  onPress?: any;
}



const ButtonTableIcon: React.FC<IProps> = (props) => {
  return (

    <OverlayTrigger
      placement={props.placement || 'left'}
      trigger={props.trigger || ['hover', 'hover']}
      overlay={props.overlay ||
        <TableToltip className="d-print-none">
          {props.tooltip}
        </TableToltip>
      }>

      <TableActionButton
        backgroundColor={props.backgroundColor}
        onClick={props.onPress}>

        {props.icon}

      </TableActionButton>

    </OverlayTrigger>

  );
};



export default ButtonTableIcon;
