import React from 'react';

import {
  CheckBoxButton,
  CheckBoxContainer,
  CheckBoxInput,
  CheckBoxLabel,
} from './styled';



export interface IProps {
  disabled?: boolean;
  checked?: boolean;

  title?: any;

  onPress?: any;
}



const Checkbox: React.FC<IProps> = (props: IProps) => {
  return (

    <CheckBoxButton
      onClick={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}>

      <CheckBoxContainer>

        <CheckBoxInput
          disabled={props.disabled}
          checked={props.checked}
          type={'checkbox'}
        />

        {props.title && (
          <CheckBoxLabel>
            {props.title || '-'}
          </CheckBoxLabel>
        )}

      </CheckBoxContainer>

    </CheckBoxButton>

  );
};



export default Checkbox;
