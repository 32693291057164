import React,
{
  useEffect,
} from 'react';
import './App.css';

import {
  Provider,
} from 'react-redux';

import {
  ToastContainer,
} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

import {
  persistStore,
} from 'redux-persist';

import {
  PersistGate,
} from 'redux-persist/integration/react';

import PackageInfo from '../package.json';

import AppRoutes from './navigation/app.routes';

import store from './store';

const persistor = persistStore(store);



const App: React.FC = () => {
  useEffect(() => {
    console.log(`KWS Intranet Version: ${PackageInfo.version}`);
  }, []);



  return (

    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppRoutes />
        <ToastContainer />
      </PersistGate>
    </Provider>

  );
};



export default App;
