import ApiWS from '../index';



export const GetRecoveryMotivesListAll = async (payload?: any) => {
  const url = '/app/cadastros/motivo-recobranca/buscar';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PostCreateRecoveryMotives = async (payload: any) => {
  const url = '/app/cadastros/motivo-recobranca/criar';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PutEditRecoveryMotives = async (idRecoveryMotives: string, payload: any) => {
  const url = `/app/cadastros/motivo-recobranca/editar/${idRecoveryMotives}`;

  const response = await ApiWS.put(url, payload);
  return response?.data;
};



export const DeleteRecoveryMotives = async (idRecoveryMotives: string) => {
  const url = `/app/cadastros/motivo-recobranca/deletar/${idRecoveryMotives}`;

  const response = await ApiWS.delete(url);
  return response?.data;
};
