import Yup from '../base-validation.yup';



const UsuariosFormValidation = Yup.object().shape({
  nome: Yup.string()
    .required('Campo obrigatório')
    .min(3, `Precisa conter pelo menos ${3} caracteres`),


  email: Yup.string()
    .required('Campo obrigatório'),


  senha: Yup.string()
    .required('Campo obrigatório'),


  clienteEmpresa: Yup.string()
    .required('Campo obrigatório'),


  status: Yup.boolean()
    .nullable(),


  type: Yup.string()
    .nullable(),
});



export default UsuariosFormValidation;
