export const APP = {
  paddingContainer:                 16,
  paddingFlatList:                  12,

  opacityOn:                        1,
  opacityOff:                       0.4,
};


export const BUTTONS = {
  buttonHeight:                     40,
  cardRadius:                       5,
};



export const ICONS = {
  iconSize:                         24,
  iconHomeSize:                     55,
  iconDashboardSize:                180,
};



export const INPUTS = {
  inputHeight: 40,
  inputHeightGrid: 20,
  inputRadius: 5,
};



export const MENU = {
  menuContent: 35,
};


export const MESSAGES = {
  messageAutoClose: 3000,
  messageAutoCloseDouble: 6000,
};



export const TABLES = {
  paginationPage: 10,
};


export const TEXTFIELD = {
  input_bm: 6,
  input_centroCusto: 7,
  input_cnpj: 14,
  input_contrato: 15,
  input_descAnexo: 30,
  input_descComp: 30,
  input_filial: 2,
  input_op: 11,
  input_pedido: 6,
  input_planilha: 6,
  input_rev: 3,
  input_sme: 6,
  input_solicitante: 24,
};

export const FONTSIZE = {
  fontSizeDefault: 0.938,
  fontSizePFFSm: 11,
  fontSizePFFLg: 15,
};



const Sizes = {
  ...APP,
  ...BUTTONS,
  ...ICONS,
  ...INPUTS,
  ...MENU,
  ...MESSAGES,
  ...TABLES,
  ...TEXTFIELD,
  ...FONTSIZE,
};



export default Sizes;
