import React from 'react';

import DrawerMenu from '../../navigation/drawer';

import {
  Colors,
} from '../../shared/constants';

import Container from '../Container';

import {
  NavegarScreenStyle,
} from './styled';



export interface IProps {
  children?: any;
}



const Navegar: React.FC<IProps> = (props: IProps) => {
  return (

    <NavegarScreenStyle
      backgroundColor={Colors.appBackground}>

      <DrawerMenu />


      <Container>
        {props.children}
      </Container>

    </NavegarScreenStyle>

  );
};



export default Navegar;
