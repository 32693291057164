import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export interface IProps {
  marginLeft?: boolean;
  marginRight?: boolean;
  disabled?: boolean;
  outline?: boolean;
  backgroundColor?: string;
}



export const ButtonTextAppStyle = styled.p`
  margin-top: 0;
  margin-left: ${(props: IProps) => props.marginLeft ? 8 : 0}px;
  margin-right: ${(props: IProps) => props.marginRight ? 8 : 0}px;
  margin-bottom: 0;
  color: ${(props: IProps) => props.outline ? props.backgroundColor : Colors.white};
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
`;



export const ButtonOutlineStyle = css`
  background-color: ${Colors.transparent};
  border-color: ${(props: IProps) => props.backgroundColor || Colors.primary};
  border-style: solid;
  border-width: 2px;

  :hover {
    border-color: ${Colors.tertiary};
  }

  &:hover ${ButtonTextAppStyle} {
    color: ${Colors.white};
  }
`;

export const ButtonAppStyle = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  height: ${Sizes.buttonHeight}px;
  padding: 7.5px 11.5px;
  background-color: ${(props: IProps) => props.backgroundColor || Colors.primary};
  opacity: ${(props: IProps) => props.disabled ? Sizes.opacityOff : Sizes.opacityOn};
  transition: all .15s ease;

  :active {
    background-color: ${(props: IProps) => props.backgroundColor || Colors.primary};
  }

  :hover {
    background-color: ${Colors.tertiary};
  }

  ${(props: IProps) => props.outline && ButtonOutlineStyle};
`;


export const ButtonIconLeftApp = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2px;
  
  ${ButtonAppStyle}:hover && {
    svg {
      color: ${Colors.white} !important;
      fill: ${Colors.white} !important;
    }
  }
`;


export const ButtonIconRightApp = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2px;
  
  ${ButtonAppStyle}:hover && {
    svg {
      color: ${Colors.white} !important;
      fill: ${Colors.white} !important;
    }
  }
`;
