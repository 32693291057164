import styled from 'styled-components';



export interface IProps {
  center?: boolean;
  color?: string;
  fontWeight?: boolean;
}



export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const AlertContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 16px;
  padding: 16px;
  background-color: #FFFFFF;
  min-width: 300px;
  min-height: 50px;
  border-radius: 3px;
`;

export const AlertHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
`;



// Button

export const ButtonComponent = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  cursor: pointer;

  ${(props: IProps) => props.center && `
    flex: 1;
  `}
`;

export const ButtonComponentText = styled.p`
  color: ${(props: IProps) => props.color || '#000000'};
  font-size: 14px;
  font-weight: ${(props: IProps) => props.fontWeight ? '600' : 'normal'};
  text-align: center;
`;


export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
`;



export const DialogTitle = styled.h2`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 4px;
  color: ${(props: IProps) => props.color || '#000000'};
  font-size: 18px;
  font-weight: 500;

  ${(props: IProps) => props.center && `
    text-align: center;
  `}
`;



export const DialogDescription = styled.p`
  margin-top: 12px;
  margin-bottom: 1px;
  color: ${(props: IProps) => props.color || '#33383D'};
  font-size: 16px;

  ${(props: IProps) => props.center && `
    text-align: center;
  `}
`;



export const  DialogText = styled.p`
  margin-top: 10px;
  color: ${(props: IProps) => props.color || '#595e64'};
  font-size: 12px;

  ${(props: IProps) => props.center && `
    text-align: center;
  `}
`;



export const DialogFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 4px;
`;

