import Yup from '../base-validation.yup';



const MateriaisFiltroValidation = Yup.object().shape({
  nome: Yup.string()
    .nullable()
    .min(3, `Precisa conter pelo menos ${3} caracteres`),

  categoria: Yup.string()
    .nullable(),

  status: Yup.boolean()
    .nullable(),
});



export default MateriaisFiltroValidation;
