import React,
{
  useState,
} from 'react';

import {
  useSelector,
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import Epigraph from '../../components/Epigraph';

import {
  IconErase,
  IconExit,
  IconHome,
} from '../../components/Icons';

import InputText from '../../components/Inputs/InputText';

import {
  Colors,
  Images,
} from '../../shared/constants';

import {
  EDrawerMenu,
} from '../../shared/enums';

import {
  normalizeRawText,
} from '../../shared/utils/string.utils';

import {
  RootState,
} from '../../store';

import {
  APP_DRAWER_ACTION,
  APP_MENU_SELECTED_ACTION,
  APP_MODAL_MODULE_DISABLE_ACTION,
} from '../../store/reducers/app.store';

import {
  COMPANY_LOGOUT_STATE,
} from '../../store/reducers/user.store';

import NameRoutes from '../names';

import {
  MenuContainer,
  MenuDrawerContent,
  MenuHeaderContainer,
  LogoImgContent,
  LogoImg,
  MenuInfoCompanyView,
  MenuUserView,
  MenuNameUserText,
  MenuEmailUserText,
  DrawerClearFindButton,
  MenuListContent,
  MenuItemList,
  MenuEpigraphSection,
  ItemMenuInfoText,
  ItemMenuText,
  ItemMenuDisabledText,
  ItemMenuIcon,
  ItemMenuContainer,
} from './styled';



const DrawerMenu: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  const selectedIndex = useSelector((state: RootState) => state.app.menuSelected);

  const user = useSelector((state: RootState) => state.user.actualUser);


  const selectedColor = Colors.black;
  const normalColor = Colors.menuItem;
  const messageColor = Colors.menuSubitem;

  const [filterListItem, setFilterListItem] = useState('');



  const routesHighlights = [
    {
      index: EDrawerMenu.HOME,
      icon: (
        <IconHome
          size={24}
          color={
            selectedIndex === EDrawerMenu.HOME ? selectedColor : normalColor
          }
        />
      ),
      name: 'Início',
      router: NameRoutes.HomeScreen,
      visible: true,
      enable: true,
      enableMessage: '',
    },
    {
      index: EDrawerMenu.MY_ACCOUNT,
      icon: (
        <IconHome
          size={24}
          color={
            selectedIndex === EDrawerMenu.MY_ACCOUNT ? selectedColor : normalColor
          }
        />
      ),
      name: 'Minha conta',
      router: NameRoutes.MinhaContaScreen,
      visible: true,
      enable: true,
      enableMessage: '',
    },
  ];


  const routesScaffolding = [
    {
      index: EDrawerMenu.SCAFF_ASSEMBLED,
      icon: (
        <IconHome
          size={24}
          color={
            selectedIndex === EDrawerMenu.SCAFF_ASSEMBLED ? selectedColor : normalColor
          }
        />
      ),
      name: 'Montados',
      router: NameRoutes.AndaimesMontadosScreen,
      visible: true,
      enable: true,
      enableMessage: 'Andaimes',
    },
  ];


  const routesProjects = [
    {
      index: EDrawerMenu.PROJECT_MANAGE,
      icon: (
        <IconHome
          size={24}
          color={
            selectedIndex === EDrawerMenu.PROJECT_MANAGE ? selectedColor : normalColor
          }
        />
      ),
      name: 'Gerenciar',
      router: NameRoutes.ProjetosScreen,
      visible: true,
      enable: true,
      enableMessage: 'Projetos',
    },
    {
      index: EDrawerMenu.PROJECT_MAP,
      icon: (
        <IconHome
          size={24}
          color={
            selectedIndex === EDrawerMenu.PROJECT_MAP ? selectedColor : normalColor
          }
        />
      ),
      name: 'Mapa',
      router: NameRoutes.ProjetosMapaScreen,
      visible: true,
      enable: true,
      enableMessage: 'Projetos',
    },
    {
      index: EDrawerMenu.PROJECT_EXPORT,
      icon: (
        <IconHome
          size={24}
          color={
            selectedIndex === EDrawerMenu.PROJECT_EXPORT ? selectedColor : normalColor
          }
        />
      ),
      name: 'Exportar resultados',
      router: NameRoutes.ProjetosExportsScreen,
      visible: true,
      enable: true,
      enableMessage: 'Projetos',
    },
  ];


  const routesPeople = [
    {
      index: EDrawerMenu.PEOPLE_USERS,
      icon: (
        <IconHome
          size={24}
          color={
            selectedIndex === EDrawerMenu.PEOPLE_USERS ? selectedColor : normalColor
          }
        />
      ),
      name: 'Usuários do sistema',
      router: NameRoutes.PeopleUsersScreen,
      visible: true,
      enable: true,
      enableMessage: '',
    },
    {
      index: EDrawerMenu.ACCESS_PROFILES,
      icon: (
        <IconHome
          size={24}
          color={
            selectedIndex === EDrawerMenu.ACCESS_PROFILES ? selectedColor : normalColor
          }
        />
      ),
      name: 'Perfis de acesso',
      router: NameRoutes.ProfileAccessScreen,
      visible: true,
      enable: true,
      enableMessage: '',
    },
    {
      index: EDrawerMenu.REGISTER_COLLABORATOR,
      icon: (
        <IconHome
          size={24}
          color={
            selectedIndex === EDrawerMenu.REGISTER_COLLABORATOR ? selectedColor : normalColor
          }
        />
      ),
      name: 'Colaboradores',
      router: NameRoutes.ColaboradoresScreen,
      visible: true,
      enable: true,
      enableMessage: '',
    },
    {
      index: EDrawerMenu.FUNCTIONS_COLLABORATOR,
      icon: (
        <IconHome
          size={24}
          color={
            selectedIndex === EDrawerMenu.FUNCTIONS_COLLABORATOR ? selectedColor : normalColor
          }
        />
      ),
      name: 'Funções dos colaboradores',
      router: NameRoutes.FuncoesColaboradoresScreen,
      visible: true,
      enable: true,
      enableMessage: '',
    },
  ];


  const routesRegistrations = [
    {
      index: EDrawerMenu.REGISTER_CUSTOMER,
      icon: (
        <IconHome
          size={24}
          color={
            selectedIndex === EDrawerMenu.REGISTER_CUSTOMER ? selectedColor : normalColor
          }
        />
      ),
      name: 'Clientes',
      router: NameRoutes.ClientesScreen,
      visible: true,
      enable: true,
      enableMessage: '',
    },
    {
      index: EDrawerMenu.REGISTER_REASON_CANCEL,
      icon: (
        <IconHome
          size={24}
          color={
            selectedIndex === EDrawerMenu.REGISTER_REASON_CANCEL ? selectedColor : normalColor
          }
        />
      ),
      name: 'Motivos de cancelamento',
      router: NameRoutes.MotivoCancelamentoScreen,
      visible: true,
      enable: true,
      enableMessage: '',
    },
    {
      index: EDrawerMenu.REGISTER_REASON_STOP,
      icon: (
        <IconHome
          size={24}
          color={
            selectedIndex === EDrawerMenu.REGISTER_REASON_STOP ? selectedColor : normalColor
          }
        />
      ),
      name: 'Motivos de parada',
      router: NameRoutes.MotivoParadaScreen,
      visible: true,
      enable: true,
      enableMessage: '',
    },
    {
      index: EDrawerMenu.REGISTER_REASON_RECOVERY,
      icon: (
        <IconHome
          size={24}
          color={
            selectedIndex === EDrawerMenu.REGISTER_REASON_RECOVERY ? selectedColor : normalColor
          }
        />
      ),
      name: 'Motivos de recobrança',
      router: NameRoutes.MotivoRecobrancaScreen,
      visible: true,
      enable: true,
      enableMessage: '',
    },
  ];


  const routesMaterials = [
    {
      index: EDrawerMenu.MATERIALS,
      icon: (
        <IconHome
          size={24}
          color={
            selectedIndex === EDrawerMenu.MATERIALS ? selectedColor : normalColor
          }
        />
      ),
      name: 'Materiais',
      router: NameRoutes.MateriaisScreen,
      visible: true,
      enable: true,
      enableMessage: '',
    },
    {
      index: EDrawerMenu.MATERIALS_CATEGORIES,
      icon: (
        <IconHome
          size={24}
          color={
            selectedIndex === EDrawerMenu.MATERIALS_CATEGORIES ? selectedColor : normalColor
          }
        />
      ),
      name: 'Categorias',
      router: NameRoutes.MateriaisCategoriasScreen,
      visible: true,
      enable: true,
      enableMessage: 'Materiais',
    },
  ];


  const routesOptions = [
    {
      index: EDrawerMenu.EXIT,
      icon: (
        <IconExit
          size={24}
          color={
            selectedIndex === EDrawerMenu.EXIT ? selectedColor : normalColor
          }
        />
      ),
      name: 'Sair',
      router: NameRoutes.LoginScreen,
      visible: true,
      enable: true,
      enableMessage: '',
    },
  ];



  const handleListItemClick = (index: EDrawerMenu) => {
    dispatch({ type: APP_DRAWER_ACTION, payload: false });
    if (index !== EDrawerMenu.EXIT) {
      dispatch({ type: APP_MENU_SELECTED_ACTION, payload: index });
    }
  };


  function pushButtonCLick(route: any) {
    if (route === NameRoutes.LoginScreen) {
      dispatch({ type: COMPANY_LOGOUT_STATE, payload: true });
    }
    else {
      history.push(route);
    }
  }


  function renderItem(item: any) {
    if (!item?.visible) {
      return;
    }


    return (

      <ItemMenuContainer
        key={item?.index}
        id={item?.index}
        disabled={!item?.enable}
        selected={selectedIndex === item?.index}
        onClick={() => {
          if (!item?.enable) {
            dispatch({ type: APP_MODAL_MODULE_DISABLE_ACTION, payload: true });
            return;
          }

          if (item?.enable) {
            pushButtonCLick(item?.router);
            handleListItemClick(item?.index);
          }
        }}>

        <ItemMenuIcon>
          {item?.icon}
        </ItemMenuIcon>


        <ItemMenuInfoText>

          <ItemMenuText
            selected={selectedIndex === item?.index}
            color={selectedIndex === item?.index ? selectedColor : normalColor}>
            {item?.name}
          </ItemMenuText>


          <ItemMenuDisabledText
            color={selectedIndex === item?.index ? selectedColor : messageColor}>
            {item?.enableMessage}
          </ItemMenuDisabledText>

        </ItemMenuInfoText>

      </ItemMenuContainer>

    );
  };


  // Função de filtro
  const filterRoutes = (routes: Array<any>, filter: string) => {
    const normalizedFilter = normalizeRawText(filter.toLowerCase());

    return routes.filter((item) => {
      const normalizedName = normalizeRawText(item?.name.toLowerCase());
      return (
        item?.visible &&
        normalizedName &&
        normalizedName.includes(normalizedFilter || '')
      );
    });
  };


  // Função para renderizar módulos filtrados
  const renderFilteredModule = (sectionTitle: string, routes: Array<any>, filter: string) => {
    const filteredRoutes = filterRoutes(routes, filter);

    if (filteredRoutes.length === 0) {
      // Oculta o módulo se não houver resultados filtrados
      return null;
    }

    return (

      <MenuItemList>

        <MenuEpigraphSection>
          <Epigraph
            text={sectionTitle.toUpperCase()}
            textColor={Colors.primary}
            borderBottomColor={Colors.primary}
          />
        </MenuEpigraphSection>


        {filteredRoutes.map((item) => renderItem(item))}

      </MenuItemList>

    );
  };



  return (

    <MenuContainer>

      <MenuDrawerContent>

        <MenuHeaderContainer>

          <LogoImgContent>
            <LogoImg
              alt={'Logo'}
              src={Images.logoFullWhiteApp}
            />
          </LogoImgContent>

        </MenuHeaderContainer>



        <MenuInfoCompanyView>

          <MenuUserView>
            <MenuNameUserText>
              {user?.name || '-'}
            </MenuNameUserText>

            <MenuEmailUserText>
              {user?.email || '-'}
            </MenuEmailUserText>
          </MenuUserView>


          <InputText
            type={'TEXT'}
            iconRight={
              filterListItem === '' ? null : (
                <DrawerClearFindButton
                  onClick={() => {
                    setFilterListItem('');
                  }}>
                  <IconErase
                    color={Colors.black}
                    size={22}
                  />
                </DrawerClearFindButton>
              )
            }
            placeholderText={'Digite o que busca'}
            countLimit={40}
            countColor={messageColor}
            value={filterListItem}
            onChange={(rawText: string) => {
              setFilterListItem(rawText);
            }}
          />

        </MenuInfoCompanyView>



        <MenuListContent>

          {renderFilteredModule('Destaques', routesHighlights, filterListItem)}


          {renderFilteredModule('Andaimes', routesScaffolding, filterListItem)}


          {renderFilteredModule('Projetos', routesProjects, filterListItem)}


          {renderFilteredModule('Pessoas', routesPeople, filterListItem)}


          {renderFilteredModule('Cadastros', routesRegistrations, filterListItem)}


          {renderFilteredModule('Materiais', routesMaterials, filterListItem)}


          {renderFilteredModule('Opções', routesOptions, filterListItem)}

        </MenuListContent>

      </MenuDrawerContent>

    </MenuContainer>

  );
};



export default DrawerMenu;
