import React from 'react';

import {
  EmptyContentView,
  EmptyImage,
  EmptyTitle,
  EmptyDescription,
} from './styled';



export interface IProps {
  image?: any;

  title?: string;
  titleSize?: number;

  description?: string;
  descriptionSize?: number;

  onClick?: any;
}



const EmptyContent: React.FC<IProps> = (props) => {
  return (

    <EmptyContentView
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        };
      }}>


      {Boolean(props.image) && (
        <EmptyImage
          alt={'Empty Image'}
          src={props.image}
        />
      )}


      {Boolean(props.title) && (
        <EmptyTitle
          fontSize={props.titleSize || 14}
          lineHeight={props.titleSize + 4 || 18}>
          {props.title}
        </EmptyTitle>
      )}


      {Boolean(props.description) && (
        <EmptyDescription
          fontSize={props.descriptionSize || 14}
          lineHeight={props.descriptionSize + 4 || 18}>
          {props.description}
        </EmptyDescription>
      )}

    </EmptyContentView>

  );
};



export default EmptyContent;
