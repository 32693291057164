import ApiWS from '../index';



export const GetClientesAreasListAll = async (payload?: any) => {
  const url = '/app/cadastros/clientes-areas/buscar';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PostCreateClientesAreas = async (payload: any) => {
  const url = '/app/cadastros/clientes-areas/criar';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PutEditClientesAreas = async (idArea: string, payload: any) => {
  const url = `/app/cadastros/clientes-areas/editar/${idArea}`;

  const response = await ApiWS.put(url, payload);
  return response?.data;
};



export const DeleteClientesAreas = async (idArea: string) => {
  const url = `/app/cadastros/clientes-areas/deletar/${idArea}`;

  const response = await ApiWS.delete(url);
  return response?.data;
};
