export const PlatformSizes = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 600,
  tabletM: 768,
  tabletL: 920,
  laptop: 1024,
  laptopM: 1220,
  laptopL: 1440,
  desktop: 1920,
  desktopL: 2560,
  n4k: 3840,
  n4kL: 4840,
};


const minorValue = 0.01;


export const PlatformDevices = {
  minMobileS: `(min-width: ${PlatformSizes.mobileS}px)`,
  maxMobileS: `(max-width: ${PlatformSizes.mobileS - minorValue}px)`,

  minMobileM: `(min-width: ${PlatformSizes.mobileM}px)`,
  maxMobileM: `(max-width: ${PlatformSizes.mobileM - minorValue}px)`,

  minMobileL: `(min-width: ${PlatformSizes.mobileL}px)`,
  maxMobileL: `(max-width: ${PlatformSizes.mobileL - minorValue}px)`,

  minTablet: `(min-width: ${PlatformSizes.tablet}px)`,
  maxTablet: `(max-width: ${PlatformSizes.tablet - minorValue}px)`,

  minTabletM: `(min-width: ${PlatformSizes.tabletM}px)`,
  maxTabletM: `(max-width: ${PlatformSizes.tabletM - minorValue}px)`,

  minTabletL: `(min-width: ${PlatformSizes.tabletL}px)`,
  maxTabletL: `(max-width: ${PlatformSizes.tabletL - minorValue}px)`,

  minLaptop: `(min-width: ${PlatformSizes.laptop}px)`,
  maxLaptop: `(max-width: ${PlatformSizes.laptop - minorValue}px)`,

  minLaptopM: `(min-width: ${PlatformSizes.laptopM}px)`,
  maxLaptopM: `(max-width: ${PlatformSizes.laptopM - minorValue}px)`,

  minLaptopL: `(min-width: ${PlatformSizes.laptopL}px)`,
  maxLaptopL: `(max-width: ${PlatformSizes.laptopL - minorValue}px)`,

  minDesktop: `(min-width: ${PlatformSizes.desktop}px)`,
  maxDesktop: `(max-width: ${PlatformSizes.desktop - minorValue}px)`,

  minDesktopL: `(min-width: ${PlatformSizes.n4k}px)`,
  maxDesktopL: `(max-width: ${PlatformSizes.n4k - minorValue}px)`,

  minN4k: `(min-width: ${PlatformSizes.desktopL}px)`,
  maxN4k: `(max-width: ${PlatformSizes.desktopL - minorValue}px)`,

  minN4kL: `(min-width: ${PlatformSizes.n4kL}px)`,
  maxN4kL: `(max-width: ${PlatformSizes.n4kL - minorValue}px)`,
};
