const MessageStatusModel = {
  hideUser: true,
  showToolbar: true,
  type: '',
  title: '',
  description: '',
  image: '',
};



export default MessageStatusModel;
