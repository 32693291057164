import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
  useLocation,
} from 'react-router-dom';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  GetAllInputClients,
} from '../../../../apis/endpoints/clientes.endpoints';

import {
  DeleteUser,
  PostCreateUser,
  PutEditUser,
} from '../../../../apis/endpoints/pessoas-usuarios.endpoints';

import AlertMessage from '../../../../components/Alerts';
import Checkbox from '../../../../components/Checkbox';
import Container from '../../../../components/Container';
import Content from '../../../../components/Content';

import {
  ButtonBack,
} from '../../../../components/Controls';

import Epigraph from '../../../../components/Epigraph';
import {
  FormContainerView,
  FormButtonAction,
  FormRowEndButtonsContainer,
  FormToolberButtonApp,
  FormContainerPadder,
  FormInputsContainer,
  FormsSectionView,
  FormsInputSectionView,
} from '../../../../components/Filter/filter-content';

import {
  IconBack,
  IconCheck,
  IconDelete,
} from '../../../../components/Icons';

import InputDropdown from '../../../../components/Inputs/InputDropdown';
import InputText from '../../../../components/Inputs/InputText';

import {
  IToast,
} from '../../../../components/IToast';

import LoadingProgress from '../../../../components/Loadings';
import Screen from '../../../../components/Screen';
import RequiredText from '../../../../components/TextRequired';
import Title from '../../../../components/Title';
import Toolbar from '../../../../components/Toolbar';
import ToolbarGroup from '../../../../components/ToolbarGroup';

import {
  Colors,
  Sizes,
  Values,
} from '../../../../shared/constants';

import {
  EHttpStatusCode,
} from '../../../../shared/enums';

import {
  ILightClientes,
  IDropdownItem,
  IResponse,
  IUsuarios,
} from '../../../../shared/interfaces';

import UsuariosFormModel from '../../../../shared/models/Usuarios/usuario-form.model';

import {
  stringWordCapitalize,
} from '../../../../shared/utils/string.utils';

import UsuariosFormValidation from '../../../../shared/validations/Usuarios/usuarios-form.validations';

import {
  APP_MODAL_LOADING_ACTION,
} from '../../../../store/reducers/app.store';

import {
  CompanyClientTextObs,
  CompanyClientTextSpanObs,
} from './styled';



export interface IRouteLocation {
  edit: boolean;
  item: IUsuarios;
}



const UsersPeopleFormsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const routeLocation = useLocation<IRouteLocation>();
  const params = routeLocation?.state;


  const formik = useRef<FormikProps<typeof UsuariosFormModel | null>>(null);

  const editForm = params?.edit;
  const [itemToEdit, setItemToEdit] = useState<IUsuarios>(params?.item);

  const [listEmpresas, setListEmpresas] = useState<Array<IDropdownItem> | null>(null);
  const [empresaInput, setEmpresaInput] = useState<IDropdownItem | null>(null);;

  const [loading, setLoading] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);

  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);



  async function getClientesFunction() {
    try {
      const response: IResponse = await GetAllInputClients();
      const responseData: ILightClientes = response?.data;

      if (!responseData || !Array.isArray(responseData)) {
        setListEmpresas(null);
        setEmpresaInput(null);
        return;
      }

      const newData: Array<IDropdownItem> = responseData?.map((item: ILightClientes) => {
        return {
          key: String(item?.id),
          label: item?.nomeFantasia,
          value: item?.id,
        };
      });

      setListEmpresas(newData);
    }
    catch (error: any) {
      console.error(error);

      setListEmpresas(null);
      setEmpresaInput(null);
    }
  };


  async function updateForm() {
    setLoading(true);

    await getClientesFunction();

    if (editForm && formik.current) {
      formik.current?.setFieldValue('nome', itemToEdit?.name);
      formik.current?.setFieldValue('email', itemToEdit?.email);
      formik.current?.setFieldValue('senha', itemToEdit?.senha);
      formik.current?.setFieldValue('clienteEmpresa', itemToEdit?.clienteEmpresa);
      formik.current?.setFieldValue('isAtivo', itemToEdit?.isAtivo ? true : false);

      formik.current?.validateForm();
    }

    setTimeout(() => {
      setLoading(false);
      setMounted(true);
    }, Values.mountTime);
  }


  function resetForm() {
    setMounted(false);

    setEmpresaInput(null);
    formik.current?.resetForm();

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  };


  function mountPayload(values: typeof UsuariosFormModel) {
    const payload = {
      name: stringWordCapitalize(values?.name) || '',
      email: values?.email,
      senha: values?.senha,
      tipoPerfil: values?.tipoPerfil,
      clienteEmpresa: values?.clienteEmpresa,
      isAtivo: values?.isAtivo ? 1 : 0,
    } as typeof UsuariosFormModel;

    return payload;
  }


  async function createItemFunction(values: typeof UsuariosFormModel) {
    const payload = mountPayload(values);

    const response: IResponse = await PostCreateUser(payload);
    const responseStatus = response?.status;

    if (responseStatus === EHttpStatusCode.OK) {
      IToast({
        type: 'success',
        message: 'Item salvo com sucesso',
      });
      resetForm();
    }
    else {
      console.error('Error', response);
      throw new Error('Erro ao criar item');
    }
  }


  async function editItemFunction(values: typeof UsuariosFormModel) {
    const payload = mountPayload(values);

    const itemToSave = {
      id: itemToEdit?.id,
      ...payload,
    } as typeof UsuariosFormModel;

    setItemToEdit(itemToSave);

    const response: IResponse = await PutEditUser(String(itemToEdit?.id), payload);
    const responseStatus = response?.status;

    if (responseStatus === EHttpStatusCode.OK) {
      IToast({
        type: 'success',
        message: 'Item salvo com sucesso',
      });
    }
    else {
      console.error('Error', response);
      throw new Error('Erro ao editar item');
    }
  }


  async function saveFormFunction(values: any) {
    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      if (editForm) {
        await editItemFunction(values);
      }
      else {
        await createItemFunction(values);
      }
    }
    catch (error: any) {
      console.error(error);

      IToast({
        type: 'error',
        message: 'Erro interno ao salvar item',
      });
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  async function deleteItemFunction() {
    setDeleteAlert(false);

    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response: IResponse = await DeleteUser(String(itemToEdit?.id));
      const responseStatus = response?.status;

      if (responseStatus !== EHttpStatusCode.OK) {
        console.error(response);
        IToast({
          type: 'error',
          message: 'Erro ao deletar item',
        });
        return;
      }

      IToast({
        type: 'success',
        message: 'Item deletado com sucesso',
      });
      history.goBack();
    }
    catch (error: any) {
      console.error(error);

      IToast({
        type: 'error',
        message: 'Erro interno ao deletar item',
      });
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderLoading() {
    return (

      <LoadingProgress
        type={'SCREEN'}
      />

    );
  }



  useEffect(() => {
    updateForm();
  }, [formik]);



  return (

    <Formik
      enableReinitialize
      validateOnMount={true}
      isInitialValid={true}
      innerRef={formik}
      initialValues={UsuariosFormModel}
      validationSchema={UsuariosFormValidation}
      onSubmit={(values) => {
        saveFormFunction(values);
      }}>
      {({
        errors,
        values,
        dirty,
        isValid,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (

        <Screen
          backgroundColor={Colors.appBackground}>

          <Container>

            <Toolbar
              backgroundColor={Colors.toolbarBackground}
              leftIcon={
                <ButtonBack
                  color={Colors.black}
                  size={20}
                />
              }
              centerContent={
                <Title
                  color={Colors.tertiary}>
                  {editForm
                    ? 'Editar usuário'
                    : 'Criar usuário'
                  }
                </Title>
              }
              rightIcon={
                <ToolbarGroup>

                  <FormToolberButtonApp
                    disabled={!dirty || !isValid || loading}
                    title={'Salvar'}
                    backgroundColor={Colors.primaryMedium}
                    iconLeft={
                      <IconCheck
                        color={Colors.white}
                        size={Sizes.iconSize}
                      />
                    }
                    onPress={() => {
                      handleSubmit();
                    }}
                  />


                  {editForm && (
                    <FormToolberButtonApp
                      disabled={loading}
                      title={'Deletar'}
                      backgroundColor={Colors.danger}
                      iconLeft={
                        <IconDelete
                          color={Colors.white}
                          size={Sizes.iconSize}
                        />
                      }
                      onPress={() => {
                        setDeleteAlert(true);
                      }}
                    />
                  )}

                </ToolbarGroup>
              }
            />



            <Content>
              <FormContainerPadder>
                <FormContainerView>

                  {!mounted && renderLoading()}



                  {mounted && (
                    <Container>

                      <FormInputsContainer>

                        <RequiredText>
                          * Campos obrigatórios
                        </RequiredText>



                        <FormsSectionView>

                          <Epigraph
                            text={'Dados do usuário'}
                          />


                          <FormsInputSectionView>

                            <InputText
                              disabled={loading}
                              autoCorrect
                              autoCapitalize={'words'}
                              type={'TEXT'}
                              labelText={'Nome *'}
                              placeholderText={'Nome'}
                              borderColor={errors.name ? Colors.danger : null}
                              helpText={errors.name}
                              helpColor={Colors.danger}
                              countLimit={Values.nameItem}
                              value={values.name}
                              onChange={handleChange('nome')}
                              onBlur={handleBlur('nome')}
                            />



                            <InputText
                              disabled={loading}
                              autoCorrect
                              autoCapitalize={'words'}
                              type={'TEXT'}
                              labelText={'E-mail *'}
                              placeholderText={'E-mail'}
                              borderColor={errors.email ? Colors.danger : null}
                              helpText={errors.email}
                              helpColor={Colors.danger}
                              countLimit={Values.emailMaxCount}
                              value={values.email}
                              onChange={handleChange('email')}
                              onBlur={handleBlur('email')}
                            />



                            <InputText
                              disabled={loading}
                              autoCorrect
                              autoCapitalize={'words'}
                              type={'TEXT'}
                              labelText={'Senha *'}
                              placeholderText={'Senha'}
                              borderColor={errors.senha ? Colors.danger : null}
                              helpText={errors.senha}
                              helpColor={Colors.danger}
                              countLimit={Values.nameItem}
                              value={values.senha}
                              onChange={handleChange('senha')}
                              onBlur={handleBlur('senha')}
                            />


                            <Checkbox
                              checked={Boolean(values.isAtivo)}
                              title={'Ativo'}
                              onPress={() => {
                                setFieldValue('isAtivo', !values.isAtivo);
                              }}
                            />

                          </FormsInputSectionView>

                        </FormsSectionView>



                        <FormsSectionView>

                          <Epigraph
                            text={'Vínculo'}
                          />


                          <FormsInputSectionView>

                            <InputDropdown
                              dropArray={listEmpresas}
                              disabled={loading}
                              labelText={'Empresa Cliente *'}
                              placeholderText={empresaInput?.label}
                              borderColor={errors.clienteEmpresa ? Colors.danger : null}
                              helpText={errors.clienteEmpresa}
                              helpColor={Colors.danger}
                              value={empresaInput?.value}
                              onChange={(item: IDropdownItem) => {
                                setFieldValue('clienteEmpresa', item?.value);
                                setEmpresaInput(item);
                              }}
                              onBlur={handleBlur('clienteEmpresa')}
                            />


                            <CompanyClientTextObs>
                              O campo <CompanyClientTextSpanObs>Empresa Cliente</CompanyClientTextSpanObs> é obrigatório para usuários <CompanyClientTextSpanObs>com permissões</CompanyClientTextSpanObs> Cliente ou Fiscal.
                            </CompanyClientTextObs>

                          </FormsInputSectionView>

                        </FormsSectionView>



                        <FormsSectionView>

                          <Epigraph
                            text={'Permissões'}
                          />


                          <FormsInputSectionView>

                            {/* TODO */}


                            <p>
                              Permissões Web
                            </p>


                            <p>
                              Permissões Mobile
                            </p>

                          </FormsInputSectionView>

                        </FormsSectionView>

                      </FormInputsContainer>



                      <FormRowEndButtonsContainer>

                        <FormButtonAction
                          outline
                          disabled={loading || !mounted}
                          iconLeft={
                            <IconBack
                              color={Colors.primaryMedium}
                              size={16}
                            />
                          }
                          title={'Voltar'}
                          backgroundColor={Colors.primaryMedium}
                          onPress={() => {
                            history.goBack();
                          }}
                        />



                        <FormButtonAction
                          disabled={!dirty || !isValid || loading || !mounted}
                          iconLeft={
                            <IconCheck
                              color={Colors.white}
                              size={16}
                            />
                          }
                          title={'Salvar'}
                          backgroundColor={Colors.primaryMedium}
                          onPress={() => {
                            handleSubmit();
                          }}
                        />

                      </FormRowEndButtonsContainer>

                    </Container>
                  )}

                </FormContainerView>
              </FormContainerPadder>
            </Content>

          </Container>



          <AlertMessage
            visible={deleteAlert}
            title={'Deletar item?'}
            description={'Gostaria de deletar esse item?'}
            cancelText={'Não'}
            cancelPress={() => {
              setDeleteAlert(false);
            }}
            okText={'Sim'}
            okPress={() => {
              deleteItemFunction();
            }}
          />

        </Screen>

      )
      }
    </Formik >

  );
};



export default UsersPeopleFormsScreen;
