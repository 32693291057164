import Yup from '../base-validation.yup';



const PerfilAcessoFiltroValidation = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .min(3, `Precisa conter pelo menos ${3} caracteres`),
});



export default PerfilAcessoFiltroValidation;
