import styled from 'styled-components';

import {
  Colors,
} from '../../shared/constants';



export interface IProps {
  fontSize?: number;
  lineHeight?: number;
}



export const EmptyContentView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
`;

export const EmptyImage = styled.img`
  height: 170px;
  width: 170px;
  object-fit: cover;
  border-radius: 5px;
`;

export const EmptyTitle = styled.p`
  margin-top: 40px;
  margin-bottom: 0;
  color: ${Colors.black};
  font-size: ${(props: IProps) => props.fontSize || 14}px;
  line-height: ${(props: IProps) => props.lineHeight + 4 || 18}px;
  font-weight: bold;
  text-align: center;
`;

export const EmptyDescription = styled.p`
  margin-top: 20px;
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 0;
  color: ${Colors.black};
  font-size: ${(props: IProps) => props.fontSize || 13.5}px;
  line-height: ${(props: IProps) => props.lineHeight + 4 || 17.5}px;
  font-weight: 500;
  text-align: center;
`;
