import {
  Images,
  Messages,
} from '../constants';



export function returnErrorObject(error: number) {
  switch (error) {
    case 401:
      return {
        image: Images.error401,
        title: Messages.Error401,
        description: null,
      };

    case 500:
      return {
        image: Images.other500,
        title: Messages.Other500,
        description: 'Tente novamente! Se persistir, contate osAdministrador do sistema!',
      };

    default:
      return {
        image: Images.other404,
        title: 'Erro ao trazer dados do servidor',
        description: 'Contate o administrador do sistema!',
      };
  }
};
