import ApiWS from '../index';



export const EditarUsuario = async (userId: any, data: any) => {
  const url = `/app/usuario/editar/${userId}`;

  const response = await ApiWS.put(url, data);
  return response?.data;
};
