import Yup from '../base-validation.yup';



const RecuperarSenhaValidation = Yup.object().shape({
  email: Yup.string()
    .required('O E-mail é obrigatório')
    .min(3, `Precisa conter pelo menos ${3} caracteres`),
});



export default RecuperarSenhaValidation;
