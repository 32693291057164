import ApiWS from '../index';



export const GetAllInputPerfis = async (payload?: any) => {
  const url = '/app/perfis-acessos/listar-tudo';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const GetPerfisAcessoListAll = async (payload?: any) => {
  const url = '/app/perfis-acessos/buscar';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PostCreatePerfisAcesso = async (payload: any) => {
  const url = '/app/perfis-acessos/criar';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PutEditPerfisAcesso = async (idPerfil: string, payload: any) => {
  const url = `/app/perfis-acessos/editar/${idPerfil}`;

  const response = await ApiWS.put(url, payload);
  return response?.data;
};



export const DeletePerfisAcesso = async (idPerfil: string) => {
  const url = `/app/perfis-acessos/deletar/${idPerfil}`;

  const response = await ApiWS.delete(url);
  return response?.data;
};
