import ApiWS from '../index';



export const GetClientesHistoricoListAll = async (payload?: any) => {
  const url = '/app/cadastros/clientes-historico';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};
