import React,
{
  useEffect,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import Content from '../../components/Content';

import {
  FormContainerPadder,
  FormContainerView,
} from '../../components/Filter/filter-content';

import {
  IconChat,
  IconPassword,
} from '../../components/Icons';

import Navegar from '../../components/Navegar';
import Title from '../../components/Title';
import Toolbar from '../../components/Toolbar';

import NameRoutes from '../../navigation/names';

import {
  Colors,
  Images,
} from '../../shared/constants';

import {
  EDrawerMenu,
} from '../../shared/enums';

import {
  RootState,
} from '../../store';

import {
  APP_MENU_SELECTED_ACTION,
} from '../../store/reducers/app.store';

import {
  HeaderUserContainer,
  ContaUserImage,
  UserInfoHeaderView,
  HeaderUserName,
  UserInfoDomainContent,
  HeaderDomainText,
  TextBold,
  ContaLogoImage,
  MinhaContaContent,
  ContaButtonAction,
} from './styled';



const MinhaContaScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  const user = useSelector((state: RootState) => state.user?.actualUser);
  const firstNameUser = user?.name ? user?.name?.split(' ')[0] : '-';



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.MY_ACCOUNT });
  }, []);



  return (

    <Navegar>

      <Toolbar
        centerContent={
          <Title
            color={Colors.tertiary}>
            Minha conta
          </Title>
        }
      />



      <Content>
        <FormContainerPadder>
          <FormContainerView>

            <HeaderUserContainer>

              <ContaUserImage
                alt={user?.name}
                src={Images.backgroundAvatar}
              />



              <UserInfoHeaderView>
                <HeaderUserName>
                  {firstNameUser}
                </HeaderUserName>



                <UserInfoDomainContent>

                  <HeaderDomainText>
                    ID:
                    &nbsp;
                    <TextBold>
                      {user?.id || '-'}
                    </TextBold>
                  </HeaderDomainText>



                  <HeaderDomainText>
                    Nome completo:
                    &nbsp;
                    <TextBold>
                      {user?.name || '-'}
                    </TextBold>
                  </HeaderDomainText>



                  <HeaderDomainText>
                    Empresa cliente:
                    &nbsp;
                    <TextBold>
                      {user?.clienteEmpresa?.nomeFantasia || '-'}
                    </TextBold>
                  </HeaderDomainText>



                  <HeaderDomainText>
                    Função:
                    &nbsp;
                    <TextBold>
                      {user?.perfil?.name || '-'}
                    </TextBold>
                  </HeaderDomainText>


                  <HeaderDomainText>
                    Email:
                    &nbsp;
                    <TextBold>
                      {user?.email || '-'}
                    </TextBold>
                  </HeaderDomainText>

                </UserInfoDomainContent>

              </UserInfoHeaderView>



              <ContaLogoImage
                alt={user?.name}
                src={Images.logoFullColorApp}
              />

            </HeaderUserContainer>



            <MinhaContaContent>

              <ContaButtonAction
                iconLeft={
                  <IconPassword
                    color={Colors.white}
                    size={16}
                  />
                }
                title={'Trocar senha'}
                backgroundColor={Colors.primaryMedium}
                onPress={() => {
                  history.push({
                    pathname: NameRoutes.PasswordChangeScreen,
                    state: {
                      oldPassword: true,
                    },
                  });
                }}
              />


              <ContaButtonAction
                disabled
                iconLeft={
                  <IconChat
                    color={Colors.white}
                    size={16}
                  />
                }
                title={'Entrar em contato'}
                backgroundColor={Colors.primaryMedium}
                onPress={() => {
                  history.push({
                    pathname: NameRoutes.PasswordChangeScreen,
                    state: {
                      oldPassword: true,
                    },
                  });
                }}
              />

            </MinhaContaContent>

          </FormContainerView>
        </FormContainerPadder>
      </Content>

    </Navegar>

  );
};



export default MinhaContaScreen;
