import styled from 'styled-components';

import {
  Tooltip,
} from 'react-bootstrap';

import {
  Colors,
} from '../../../shared/constants';



interface IProps {
  backgroundColor?: string;
}


export const TableActionButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  margin: 0 auto;
  background-color: transparent;
  border-radius: 3px;
  transition: all .15s ease;
  cursor: pointer;

  :hover {
    background-color: ${(props: IProps) => props.backgroundColor || Colors.tertiary};
    
    svg {
      color: ${Colors.white} !important;
      fill: ${Colors.white} !important;
    }
  }
`;


export const TableToltip = styled(Tooltip)`
  font-size: 1.063rem;
`;
