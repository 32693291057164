// Error
export const RETURNS = {

  // Sucesso
  Success201:                       'Criado',
  Success202:                       'Aceito',

  // Erros
  Error400:                         'Requisição inválida. O pedido não pôde ser entregue devido à sintaxe incorreta',
  Error401:                         'Não autorizado',
  Error403:                         'Proibido',
  Error404:                         'Não encontrado',
  Error405:                         'Método não permitido',

  // Outros erros
  Other500:                         'Erro interno do servidor',
  Other501:                         'Não implementado',
  Other502:                         'Bad Gateway',
  Other503:                         'Serviço indisponível',
  Other504:                         'Gateway Time-Out',
};



export const TextTooltip = {
  ACESSLESS: 'Não Autorizado',
  ADD: 'Adicionar',
  ADD_MATRICULATION: 'Adicionar próxima Matrícula',
  ADD_PENDENCY: 'Adicionar próxima Pendência',
  ATTACHMENT: 'Anexos',
  APPROVALS: 'Aprovadores',
  
  CANCEL: 'Cancelar',
  COMPLEMENTARY_DATA: 'Dados Complementares',
  COPY: 'Copia',

  DOWNLOAD: 'Download',
  DETAILS: 'Detalhes',

  EDIT: 'Editar',
  EDIT_PHOTO: 'Clique para selecionar Foto de Perfil',
  EXPORT: 'Exportar',
  
  HELP: 'Caso precise de ajuda, clique aqui!',
  HISTORIC: 'Histórico',
  
  INTERNAL_SERVICE: 'Atendimento Interno',
  ITEMS: 'Itens',
  ITEMS_RETRACT: 'Ocultar Itens',
  ITEMS_SHOW: 'Exibir Itens',
  
  LESS_NF: 'Sem NF\'s',
  LESS_ATTACHMENT: 'Sem Anexos',
  LESS_QUOTATION: 'Sem Cotação',
  
  MONTHLY_ORDER: 'Pedido Mensal',
  NF_S: 'NF\'s',
  NO_OBSERVATION: 'Sem Observações',
  NORMAL_PROCESS: 'Processo Normal',
  
  OBSERVATION: 'Observação',
  ORIENTATION: 'Orientação',
  CHANGE_ORIENTATION: 'Alter. Orientação',
  
  PRINTER: 'Imprimir',
  PRINTER_PC: 'Imprimir PC',
  
  QUOTATION: 'Cotação',
  
  REGULARIZATION: 'Regularização',
  REMOVE: 'Remover',
  
  SAVE: 'Salvar',
  SAVE_PHOTO: 'Clique para Salvar',
  SME_NEW: 'Nova SME',
  SME_COPY: 'Copia',
  
  URGENT_BUY: 'Compra Urgente',

  WRITE_DOWN: 'Abrir Anotações',
  
};



const Messages = {
  ...RETURNS,
  ...TextTooltip,
};



export default Messages;
