import React from 'react';

import {
  useHistory,
  useLocation,
} from 'react-router-dom';

import Content from '../../components/Content';

import {
  ButtonBack,
} from '../../components/Controls';

import EmptyContent from '../../components/Empty';

import {
  FormButtonAction,
  FormContainerPadder,
  FormContainerView,
  FormInputsContainer,
  FormRowEndButtonsContainer,
} from '../../components/Filter/filter-content';

import Screen from '../../components/Screen';
import Title from '../../components/Title';
import Toolbar from '../../components/Toolbar';

import {
  Colors,
} from '../../shared/constants';

import MessageStatusModel from '../../shared/models/Status/status.model';



export interface IRouteParams {
  status: typeof MessageStatusModel;
}



const StatusMessageScreen: React.FC = () => {
  const history = useHistory();

  const routeLocation = useLocation<IRouteParams>();
  const params = routeLocation?.state;


  const itemStatus: typeof MessageStatusModel = params?.status || MessageStatusModel;


  function renderStatusTitle(): string {
    if (itemStatus?.type === 'success') {
      return 'Sucesso';
    }

    if (itemStatus?.type === 'error') {
      return 'Erro';
    }

    return '-';
  }


  function onCloseScreen() {
    history.goBack();
  }



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Toolbar
        leftIcon={
          <ButtonBack
            color={Colors.black}
            onPress={() => {
              onCloseScreen();
            }}
          />
        }
        centerContent={
          <Title
            color={Colors.tertiary}>
            {renderStatusTitle()}
          </Title>
        }
      />



      <Content>
        <FormContainerPadder>
          <FormContainerView>

            <FormInputsContainer>

              <EmptyContent
                image={itemStatus?.image}
                title={itemStatus?.title}
                titleSize={24}
                description={itemStatus?.description}
                descriptionSize={15}
              />

            </FormInputsContainer>



            <FormRowEndButtonsContainer>

              <FormButtonAction
                title={'Fechar'}
                backgroundColor={Colors.primary}
                onPress={() => {
                  onCloseScreen();
                }}
              />

            </FormRowEndButtonsContainer>

          </FormContainerView>
        </FormContainerPadder>
      </Content>

    </Screen>

  );
};



export default StatusMessageScreen;
