const ClientesFiltroModel = {
  nomeFantasia: '',
  cnpj: '',
  razaoSocial: '',
  isAtivo: null,
};



export default ClientesFiltroModel;
