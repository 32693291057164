import styled from 'styled-components';

import {
  Form,
} from 'formik';

import ButtonApp from '../../../../components/Buttons/ButtonApp';
import Screen from '../../../../components/Screen';

import {
  Colors,
} from '../../../../shared/constants';



export const LoginBody = styled(Screen)`
  background-color: ${Colors.appBackground};
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  right: 0;
  height: 100%;
`;

export const LoginFormView = styled(Form)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 90%;
  max-width: 450px;
  background-color: ${Colors.menuBackground};
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 5px;
`;

export const LoginView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 25px;
`;

export const LogoImgContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 140px;
  background-color: ${Colors.white};
  border-bottom-color: ${Colors.grayLight};
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

export const LogoImg = styled.img`
  width: 180px;
  height: 100px;
  object-fit: contain;
`;

export const LoginTextsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
`;

export const WelcomeTitle = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  color: ${Colors.tertiary};
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
`;

export const WelcomeText = styled.p`
  margin-top: 7px;
  margin-bottom: 0;
  color: ${Colors.black};
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`;

export const LoginInputsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 20px;
`;

export const ForgetPassBackButton = styled(ButtonApp)`
  margin-top: 10px;
`;
