import React from 'react';

import {
  Colors,
} from '../../shared/constants';

import {
  ContainerSection,
  TitleSectionView,
  IconView,
  TitleComponent,
  IconContentView,
} from './styled';



export interface IProps {
  expanded?: boolean;
  // elipsing?: boolean;

  text: string;
  textColor?: string;
  textSize?: number;

  noBorderBottom?: boolean;
  borderBottomColor?: string;
  borderBottomWidth?: number;

  leftIcon?: any;
  rightIcon?: any;

  style?: any;
}



const Epigraph: React.FC<IProps> = (props) => {
  return (

    <ContainerSection
      noBorderBottom={props.noBorderBottom}
      borderBottomColor={props.borderBottomColor || Colors.primary}
      borderBottomWidth={props.borderBottomWidth}
      style={props.style}>


      <TitleSectionView>
        {props.leftIcon && (
          <IconView>
            {props.leftIcon}
          </IconView>
        )}

        <TitleComponent
          textColor={props.textColor || Colors.primary}
          textSize={props.textSize}>
          {props.text?.toUpperCase()}
        </TitleComponent>
      </TitleSectionView>


      {props.rightIcon && (
        <IconContentView>
          {props.rightIcon}
        </IconContentView>
      )}

    </ContainerSection>

  );
};



export default Epigraph;
