import React from 'react';

import AndaimesMontadosScreen from '../../screens/Andaimes/Montados';

import ClientesScreen from '../../screens/Cadastros/Clientes/Lista';
import MotivoCancelamentoScreen from '../../screens/Cadastros/MotivosCancelamento';
import MotivoParadaScreen from '../../screens/Cadastros/MotivosParada';
import MotivoRecobrancaScreen from '../../screens/Cadastros/MotivosRecobranca';

import HomeScreen from '../../screens/Home';

import MateriaisCategoriasScreen from '../../screens/Materiais/Categorias';
import MateriaisScreen from '../../screens/Materiais/Itens';

import MinhaContaScreen from '../../screens/MinhaConta';

import ColaboradoresScreen from '../../screens/Pessoas/Colaboradores';
import FuncoesColaboradoresScreen from '../../screens/Pessoas/FuncoesColaboradores';
import ProfileAccessScreen from '../../screens/Pessoas/PerfilsDeAcesso';
import PeopleUsersScreen from '../../screens/Pessoas/Usuarios';

import ProjetosExportsScreen from '../../screens/Projetos/Exports';
import ProjetosScreen from '../../screens/Projetos/Manage';
import ProjetosMapaScreen from '../../screens/Projetos/Map';


import NameRoutes from '../names';



type Route = {
  path: string;
  exact?: boolean;
  isPublic?: boolean;
  page: React.FC;
};



const DrawerRoutes: Route[] = [

  // Início

  {
    path: NameRoutes.HomeScreen,
    page: HomeScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.MinhaContaScreen,
    page: MinhaContaScreen,
    isPublic: false,
    exact: true,
  },



  // Andaimes

  {
    path: NameRoutes.AndaimesMontadosScreen,
    page: AndaimesMontadosScreen,
    isPublic: false,
    exact: true,
  },



  // Projetos

  {
    path: NameRoutes.ProjetosScreen,
    page: ProjetosScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ProjetosMapaScreen,
    page: ProjetosMapaScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ProjetosExportsScreen,
    page: ProjetosExportsScreen,
    isPublic: false,
    exact: true,
  },



  // Pessoas

  {
    path: NameRoutes.PeopleUsersScreen,
    page: PeopleUsersScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ProfileAccessScreen,
    page: ProfileAccessScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ColaboradoresScreen,
    page: ColaboradoresScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.FuncoesColaboradoresScreen,
    page: FuncoesColaboradoresScreen,
    isPublic: false,
    exact: true,
  },



  // Cadastros

  {
    path: NameRoutes.ClientesScreen,
    page: ClientesScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.MotivoCancelamentoScreen,
    page: MotivoCancelamentoScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.MotivoParadaScreen,
    page: MotivoParadaScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.MotivoRecobrancaScreen,
    page: MotivoRecobrancaScreen,
    isPublic: false,
    exact: true,
  },



  // Materiais

  {
    path: NameRoutes.MateriaisScreen,
    page: MateriaisScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.MateriaisCategoriasScreen,
    page: MateriaisCategoriasScreen,
    isPublic: false,
    exact: true,
  },
];



export default DrawerRoutes;
