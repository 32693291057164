import React from 'react';

import {
  Colors,
} from '../../shared/constants';

import Overlay from '../Overlay';

import {
  AlertContent,
  AlertHeaderContent,
  ButtonComponent,
  ButtonComponentText,
  ContainerModal,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogText,
  DialogTitle,
} from './styled';



export interface IProps {
  visible?: boolean;
  disableOverlayPress?: boolean;

  title: string;
  description?: string;
  text?: string;

  cancelText?: string;
  cancelColor?: string;
  cancelPress?: any;

  okText: string;
  okColor?: string;
  okPress: any;
}



const AlertMessage: React.FC<IProps> = (props) => {
  function dialogButton(label, color, onPress) {
    return (

      <ButtonComponent
        // disabled={disabled}
        // activeOpacity={activeOpacityDialog || 0.2}
        center
        onClick={() => {
          if (onPress) {
            onPress();
          }
        }}>

        <ButtonComponentText
          {...props}
          fontWeight
          color={color}>
          {label ? label.toUpperCase() : '-'}
        </ButtonComponentText>

      </ButtonComponent>

    );
  };



  return (

    <Overlay
      showBackground
      noPress={props.disableOverlayPress}
      visible={props.visible}
      onOverlayPress={() => {
        if (props.cancelPress) {
          props.cancelPress();
        }
      }}
      overlayColor={Colors.modalOverlayBackground}>

      <ContainerModal>

        <AlertContent>

          {/* {Platform.OS === 'ios' && blurComponentIOS} */}

          {/* {Platform.OS === 'ios' && !blurComponentIOS && (
            <div style={[styles.blurModal, blurStyle]} />
          )} */}


          <AlertHeaderContent>

            <DialogTitle>
              {props.title}
            </DialogTitle>


            <DialogContent>
              {props.description && (
                <DialogDescription>
                  {props.description}
                </DialogDescription>
              )}

              {props.text && (
                <DialogText>
                  {props.text}
                </DialogText>
              )}
            </DialogContent>

          </AlertHeaderContent>


          <DialogFooter>

            {(props.cancelText || props.cancelPress) && dialogButton(
              props.cancelText,
              props.cancelColor,
              props.cancelPress,
            )}

            {dialogButton(
              props.okText,
              props.okColor,
              props.okPress,
            )}

          </DialogFooter>

        </AlertContent>

      </ContainerModal>

    </Overlay>

  );
};



export default AlertMessage;
