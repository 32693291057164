import styled from 'styled-components';



const ToolbarGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;



export default ToolbarGroup;
