import React,
{
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  Formik,
} from 'formik';

import {
  PostRecoveryPassword,
} from '../../../../apis/endpoints/access-password.endpoints';

import ButtonApp from '../../../../components/Buttons/ButtonApp';

import {
  ButtonBack,
} from '../../../../components/Controls';

import FooterAccess from '../../../../components/Footers/FooterAccess';

import {
  IconEmail,
} from '../../../../components/Icons';

import InputText from '../../../../components/Inputs/InputText';

import {
  IToast,
} from '../../../../components/IToast';

import LoadingProgress from '../../../../components/Loadings';

import Title from '../../../../components/Title';
import Toolbar from '../../../../components/Toolbar';
import NameRoutes from '../../../../navigation/names';

import {
  Colors,
  Images,
  Values,
} from '../../../../shared/constants';

import {
  IResponse,
} from '../../../../shared/interfaces';

import RecuperarSenhaModel from '../../../../shared/models/Senha/senha-recuperar.model';
import MessageStatusModel from '../../../../shared/models/Status/status.model';
import RecuperarSenhaValidation from '../../../../shared/validations/Login/recuperar-senha.validations';

import {
  APP_MODAL_LOADING_ACTION,
} from '../../../../store/reducers/app.store';

import {
  LoginBody,
  LoginContainer,
  LoginFormView,
  LoginView,
  LogoImgContent,
  LogoImg,
  LoginTextsContent,
  WelcomeTitle,
  WelcomeText,
  LoginInputsContent,
  ForgetPassBackButton,
} from './styled';



const RecoveryPasswordScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);

  const SUCCESS_PASSWORD_RECOVER = 'Pedido de recuperar senha feito com sucesso!';
  const SUCCESS_PASSWORD_RECOVER_TEXT = 'Enviamos um e-mail de confirmação para {user_email}. Abra o e-mail, clique no link e você poderá mudar sua senha.';



  async function AuthenticateUser(values: typeof RecuperarSenhaModel) {
    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const payload = {
        email: values.email,
      };

      const response: IResponse = await PostRecoveryPassword(payload);

      if (!response) {
        IToast({
          type: 'error',
          message: 'Erro ao buscar conta!',
        });
        return;
      }

      history.replace({
        pathname: NameRoutes.StatusMessageScreen,
        state: {
          status: {
            type: 'success',
            title: SUCCESS_PASSWORD_RECOVER,
            description: SUCCESS_PASSWORD_RECOVER_TEXT.replace(
              '{user_email}',
              values.email,
            ),
            image: Images.check,
          } as typeof MessageStatusModel,
        },
      });
    }
    catch (error: any) {
      console.error(error);

      IToast({
        type: 'error',
        message: 'Erro ao recuperar conta!',
      });
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  };



  return (

    <Formik
      enableReinitialize
      validateOnMount
      initialValues={RecuperarSenhaModel}
      validationSchema={RecuperarSenhaValidation}
      onSubmit={(values) => {
        AuthenticateUser(values);
      }}>
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
      }) => (

        <LoginBody>

          <Helmet>
            <title>
              {`${'Redefinição de Senha'} - ${'KW Brasil'}`}
            </title>
          </Helmet>



          <Toolbar
            backgroundColor={Colors.toolbarBackground}
            leftIcon={
              <ButtonBack
                color={Colors.black}
              />
            }
            centerContent={
              <Title
                color={Colors.tertiary}>
                {'Recuperar senha'}
              </Title>
            }
          />



          <LoginContainer>

            <LoginFormView
              onSubmit={handleSubmit}>

              <LogoImgContent>
                <LogoImg
                  alt={'Logo'}
                  src={Images.logoFullColorApp}
                />
              </LogoImgContent>


              {loading && (
                <LoginView>
                  <LoadingProgress
                    type={'SCREEN'}
                  />
                </LoginView>
              )}


              {!loading && (
                <LoginView>

                  <LoginTextsContent>

                    <WelcomeTitle>
                      Redefinição de Senha
                    </WelcomeTitle>


                    <WelcomeText>
                      Informe seu e-mail para receber um link de redefinição.
                    </WelcomeText>

                  </LoginTextsContent>


                  <LoginInputsContent>

                    <InputText
                      disabled={loading}
                      autoCorrect={false}
                      type={'EMAIL'}
                      iconLeft={
                        <IconEmail
                          color={Colors.primaryDark}
                          size={18}
                        />
                      }
                      labelText={'E-mail'}
                      placeholderText={'E-mail'}
                      helpText={errors.email}
                      helpColor={Colors.danger}
                      countLimit={Values.emailMaxCount}
                      value={values.email}
                      onChange={handleChange('email')}
                      onBlur={handleBlur('email')}
                    />

                  </LoginInputsContent>


                  <ButtonApp
                    title={'Enviar'}
                    backgroundColor={Colors.primary}
                    onPress={() => {
                      handleSubmit();
                    }}
                  />


                  <ForgetPassBackButton
                    outline
                    title={'Voltar'}
                    backgroundColor={Colors.primary}
                    onPress={() => {
                      history.goBack();
                    }}
                  />

                </LoginView>
              )}

            </LoginFormView>



            <FooterAccess
              textColor={Colors.black}
            />

          </LoginContainer>

        </LoginBody>

      )}
    </Formik>

  );
};



export default RecoveryPasswordScreen;
