export const APP_COLORS = {
  primaryThin:                      '#c7e1d6',
  primaryLight:                     '#00d681',
  primaryMedium:                    '#00a865',
  primary:                          '#00572b',
  primaryDark:                      '#083020',

  secondaryThin:                    '#ffde85',
  secondaryLight:                   '#ffc935',
  secondary:                        '#e98d0c ',
  secondaryDark:                    '#b26c0a',

  tertiaryThin:                     '#7169c9',
  tertiaryLight:                    '#423a9c',
  tertiary:                         '#332f62',
  tertiaryDark:                     '#211e48',
};


export const APP_DEFAULT = {
  black:                            '#000000',
  white:                            '#FFFFFF',
  grayLight:                        '#c7c7c7',
  gray:                             '#898989',
  grayDark:                         '#666666',
  muted:                            '#9FA5AA',
  shadow:                           '#292929',
  transparent:                      'transparent',
  neutral:                          'rgba(255,255,255, 0.65)',
};


export const APP_STATUS = {
  danger:                           '#F51E1D',
  attentionLight:                   '#ffa729',
  attention:                        '#FF7D35',
  attentionDark:                    '#ee5e11',
  acceptLight:                      '#13f662',
  accept:                           '#16b14c',
  disabled:                         '#949494',
  sketch:                           '#d829ff',
  // muted:                            '#9FA5AA',
};



export const APPITEMS = {
  appBackground:                    '#e8e8e8',
};



export const APPLICATIONS = {
  excel:                            '#008000',
};



export const BORDERS = {
  borderColor:                      '#ced4da',
};



export const BUTTONS = {
  buttonOk:                         '#008000',
  buttonCancel:                     '#900000',
  buttonDisabled:                   '#84878E',
};


export const LINK = {
  hoverLink:                        '#ffc0cb',
};


export const FORMS = {
  formBackground:                   '#f8f8f8',
  formBackgroundSecondary:          '#ffffff',
};



export const GRIDHOME = {
  gridGreen:                        '#008000',
  gridBlue:                         '#00008b',
  gridOrange:                       '#ffa500',
  gridDodger:                       '#1e90ff',
  gridCoral:                        '#ff7f50',
  gridGray:                         '#595e66',
};



export const INPUTS = {
  inputBackground:                  '#FFFFFF',
  inputDisabled:                    '#e9ecef',
};


export const MENU = {
  menuOverlay:                      'rgba(0, 0, 0, 0.8)',
  menuBackground:                   '#f0f0f0',
  menuLine:                         '#162F40',
  menuSeparator:                    '#878787',
  menuItem:                         '#404040',
  menuSubitem:                      '#969696',
};



export const MODALS = {
  alertBackground:                  '#ffffff',
  modalOverlayBackground:           'rgba(0, 0, 0, 0.6)',
};



export const TEXTS = {
  labelColor:                       '#212529',
  textColor:                        '#808080',
  textActiveColor:                  '#000000',
};


export const TOOLBAR_TOP = {
  toolbarBackground:                '#FFFFFF',
  tabBackground:                    '#FFFFFF',
  toolbarLine:                      '#DDDDE4',
};



const Colors = {
  ...APP_COLORS,
  ...APP_DEFAULT,
  ...APP_STATUS,
  ...APPITEMS,
  ...APPLICATIONS,
  ...BORDERS,
  ...BUTTONS,
  ...LINK,
  ...FORMS,
  ...GRIDHOME,
  ...INPUTS,
  ...MENU,
  ...MODALS,
  ...TEXTS,
  ...TOOLBAR_TOP,
};



export default Colors;
