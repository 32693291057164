import Yup from '../base-validation.yup';



const ClientesFiltroValidation = Yup.object().shape({
  nomeFantasia: Yup.string()
    .nullable()
    .min(3, `Precisa conter pelo menos ${3} caracteres`),


  cnpj: Yup.string()
    .nullable()
    .min(3, `Precisa conter pelo menos ${3} caracteres`),


  razaoSocial: Yup.string()
    .nullable()
    .min(3, `Precisa conter pelo menos ${3} caracteres`),


  status: Yup.boolean()
    .nullable(),
});



export default ClientesFiltroValidation;
