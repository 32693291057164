import styled from 'styled-components';

import ButtonApp from '../../components/Buttons/ButtonApp';

import {
  Colors,
  Sizes,
} from '../../shared/constants';

import {
  PlatformDevices,
} from '../../shared/utils/platforms.utils';



export const HeaderUserContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const ContaUserImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: ${Sizes.cardRadius}px;
  object-fit: cover;
`;



export const UserInfoHeaderView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const HeaderUserName = styled.h2`
  margin-top: 0;
  margin-bottom: 15;
  color: ${Colors.black};
  font-size: 28px;
  font-weight: bold;
`;

export const UserInfoDomainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  margin-top: 5px;
`;


export const HeaderDomainText = styled.p`
  display: flex;
  flex: 1;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
`;

export const TextBold = styled.span`
  font-weight: bold;
`;


export const ContaLogoImage = styled.img`
  width: 120px;
  height: 80px;
  border-radius: ${Sizes.cardRadius}px;
  object-fit: contain;
`;



export const MinhaContaContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  gap: 15px;
`;

export const ContaButtonAction = styled(ButtonApp)`
  min-width: 210px;

  @media ${PlatformDevices.maxTabletM} {
    flex: 1;
    min-width: 170px;
  }
`;
