import ApiWS from '../index';



export const GetCollabsListAll = async (payload?: any) => {
  const url = '/app/colaboradores/buscar';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PostCreateCollabs = async (payload: any) => {
  const url = '/app/colaboradores/criar';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PutEditCollabs = async (idCollabs: string, payload: any) => {
  const url = `/app/colaboradores/editar/${idCollabs}`;

  const response = await ApiWS.put(url, payload);
  return response?.data;
};



export const DeleteCollabs = async (idCollabs: string) => {
  const url = `/app/colaboradores/deletar/${idCollabs}`;

  const response = await ApiWS.delete(url);
  return response?.data;
};
