import styled from 'styled-components';

import ButtonApp from '../../../../../components/Buttons/ButtonApp';

import {
  Sizes,
} from '../../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../../shared/utils/platforms.utils';



export const InputRowLineView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;


export const InputRowLineButton = styled(ButtonApp)`
  margin-top: 8px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  height: ${Sizes.buttonHeight}px;

  @media ${PlatformDevices.maxLaptop} {
    p {
      display: none;
    }
  }
`;
