const UsuariosFormModel = {
  id: null,
  name: '',
  email: '',
  senha: '',
  tipoPerfil: null,
  clienteEmpresa: null,
  isAtivo: 0,
};



export default UsuariosFormModel;
