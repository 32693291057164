import Yup from '../base-validation.yup';



const ClientesAresFormValidation = Yup.object().shape({
  nome: Yup.string()
    .required('Campo obrigatório')
    .min(3, `Precisa conter pelo menos ${3} caracteres`),


  isAtivo: Yup.boolean()
    .nullable(),
});



export default ClientesAresFormValidation;
