import React from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  IconBack,
  IconClose,
  IconDrawer,
  IconEdit,
  IconRefresh,
} from '../../components/Icons';

import NameRoutes from '../../navigation/names';

import {
  APP_DRAWER_ACTION,
} from '../../store/reducers/app.store';

import ButtonControl from '../Buttons/ButtonControl';



export interface IProps {
  close?: boolean;
  expanded?: boolean;

  waiters?: any[];

  color?: string;
  size?: number;

  onPress?: any;
}



export const ButtonBack: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();

  function chooseIcon() {
    if (props.close) {
      return (
        <IconClose
          color={props.color}
          size={props.size}
        />
      );
    }
    else {
      return (
        <IconBack
          color={props.color}
          size={props.size}
        />
      );
    }
  }

  function haveOnPress() {
    if (props.onPress) {
      props.onPress();
      return;
    }

    if (history.length > 1) {
      history.goBack();
      return;
    }

    history.replace(NameRoutes.HomeScreen);
  }


  return (

    <ButtonControl
      onClick={() => {
        haveOnPress();
      }}>

      {chooseIcon()}

    </ButtonControl>
  );
};



export const ButtonDrawer: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();

  function openMenuPage() {
    dispatch({ type: APP_DRAWER_ACTION, payload: true });
  }

  return (

    <ButtonControl
      onClick={() => {
        openMenuPage();
      }}>

      <IconDrawer
        color={props.color}
        size={props.size}
      />

    </ButtonControl>
  );
};



export const ButtonEdit: React.FC<IProps> = (props: IProps) => {
  return (

    <ButtonControl
      onClick={props.onPress}>

      <IconEdit
        color={props.color}
        size={props.size}
      />

    </ButtonControl>
  );
};



export const ButtonRefresh: React.FC<IProps> = (props: IProps) => {
  return (

    <ButtonControl
      onClick={props.onPress}>

      <IconRefresh
        color={props.color}
        size={props.size}
      />

    </ButtonControl>
  );
};
