import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



interface IProps {
  color?: string;
}


export const TableActionButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 26px;
  margin: 0 auto;
  background-color: transparent;
  border-radius: 3px;
  border-color: ${(props: IProps) => props.color || Colors.tertiary};
  border-style: solid;
  border-width: 1px;
  transition: all .15s ease;
  cursor: pointer;

  :hover {
    color: ${Colors.white} !important;
    background-color: ${Colors.tertiary};
    border-color: ${Colors.tertiary};
    
    svg {
      color: ${Colors.white} !important;
      fill: ${Colors.white} !important;
    }
  }
`;


export const TableActionText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  color: ${(props: IProps) => props.color || Colors.tertiary};
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  transition: all .15s ease;

  ${TableActionButton}:hover && {
    color: ${Colors.white} !important;
  }
`;
