import styled from 'styled-components';

import Screen from '../Screen';



export const NavegarScreenStyle = styled(Screen)`
  display: flex;
  flex-direction: row;
`;
