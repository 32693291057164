import styled,
{
  css,
} from 'styled-components';



export interface IProps {
  absolute?: boolean;
  transparent?: boolean;
  backgroundColor?: string;
  borderBottomColor?: string;
}



export const ShadowToolbar = css`
  box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
`;

export const AbsoluteToolbar = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
`;

export const BorderBottomToolbar = css`
  border-bottom-color: ${(props: IProps) => props.borderBottomColor || 'transparent'};
  border-bottom-width: 1;
`;


export const HeaderAppContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: transparent;
  z-index: 40;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${(props: IProps) => props.absolute && AbsoluteToolbar};
  ${(props: IProps) => !props.transparent && ShadowToolbar};
  ${(props: IProps) => props.borderBottomColor && BorderBottomToolbar};
  background-color: ${(props: IProps) => props.transparent ? 'transparent' : props.backgroundColor};
`;



export const ToolbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  padding-left: 8px;
`;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 8px;
  padding-right: 8px;
`;


// export const ToolbarContainer = styled(Toolbar)`
//   height: ${Sizes.TOOLBAR_WEB}px !important;
//   min-height: ${Sizes.TOOLBAR_WEB}px !important;
//   background-color: ${(props: IToolbarProps) => props.backgroundColor || 'transparent'};

//   @media (min-width:320px) {
//     height: ${Sizes.TOOLBAR_ANDROID}px !important;
//     min-height: ${Sizes.TOOLBAR_ANDROID}px !important;
//   }

//   @media (min-width:480px) {
//     height: ${Sizes.TOOLBAR_ANDROID}px !important;
//     min-height: ${Sizes.TOOLBAR_ANDROID}px !important;
//   }

//   @media (min-width:600px) {
//     height: ${Sizes.TOOLBAR_WEB}px !important;
//     min-height: ${Sizes.TOOLBAR_WEB}px !important;
//   }

//   @media (min-width:801px) {
//     height: ${Sizes.TOOLBAR_WEB}px !important;
//     min-height: ${Sizes.TOOLBAR_WEB}px !important;
//   }

//   @media (min-width:1025px) {
//     height: ${Sizes.TOOLBAR_WEB}px !important;
//     min-height: ${Sizes.TOOLBAR_WEB}px !important;
//   }

//   @media (min-width:1281px) {
//     height: ${Sizes.TOOLBAR_WEB}px !important;
//     min-height: ${Sizes.TOOLBAR_WEB}px !important;
//   }
// `;
