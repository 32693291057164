import styled from 'styled-components';

import {
  Colors,
} from '../../shared/constants';



export interface IProps {
  color?: string;
  numberOfLines?: number;
  ellipsizeMode?: string;
}



const Title = styled.h1`
  margin-top: 1px;
  margin-bottom: 0;
  color: ${(props: IProps) => props.color || Colors.white};
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
  text-transform: uppercase;

  display: -webkit-box;
  -webkit-line-clamp: ${(props: IProps) => props.numberOfLines || 1};
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
`;



export default Title;
