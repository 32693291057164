import Yup from '../base-validation.yup';



const UsuariosFiltroValidation = Yup.object().shape({
  nome: Yup.string()
    .nullable()
    .min(3, `Precisa conter pelo menos ${3} caracteres`),

  email: Yup.string()
    .nullable()
    .min(3, `Precisa conter pelo menos ${3} caracteres`),

  cliente_empresa: Yup.string()
    .nullable(),

  status: Yup.boolean()
    .nullable(),
});



export default UsuariosFiltroValidation;
