import styled,
{
  css,
} from 'styled-components';

import { Dropdown } from 'react-bootstrap';

import {
  Colors,
  Sizes,
} from '../../shared/constants';



export interface IProps {
  paddingLeft?: boolean;
  paddingRight?: boolean;
  wrapView?: boolean;
  inputTextCenter?: boolean;
  multiline?: boolean;
  backgroundColor?: string;
  color?: string;
  placeholderTextColor?: string;
  borderColor?: string;
  borderWidth?: number;
  opacity?: number;
  noShadow?: boolean;
}



// InputView

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  /* z-index: 0; */
`;



// InputLabel

export const LabelStyle = styled.p`
  margin-top: 1px;
  margin-left: 3px;
  margin-bottom: 2px;
  color: ${(props: IProps) => props.color || '#000'};
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
`;



// InputText

export const MultilineStyle = css`
  padding-top: 4px;
  padding-bottom: 4px;
`;

export const BackgroundStyle = css`
  background-color: ${(props: IProps) => props.backgroundColor || Colors.white};
`;


export const BorderNone = css`
  border-color: transparent;
  border-style: solid;
  border-width: 0;
`;

export const WrapStyle = css`
  flex-wrap: wrap;
`;

export const ShadowStyle = css`
	box-shadow: 0 0 10px 1px rgba(0, 0, 0 , 0.1);
`;

export const InputViewStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: ${Sizes.inputHeight}px;
  margin-top: 3px;
  margin-bottom: 2px;
  opacity: ${(props: IProps) => props.opacity};

  border-color: ${(props: IProps) => props.borderColor || Colors.grayLight};
  border-style: solid;
  border-width: ${(props: IProps) => props.borderWidth || 1}px;

  ${(props: IProps) => props.wrapView && WrapStyle};
  ${(props: IProps) => props.multiline && MultilineStyle};
  ${(props: IProps) => props.backgroundColor && BackgroundStyle};
  ${(props: IProps) => !props.noShadow && ShadowStyle};
`;


export const IconContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  margin-right: 6px;
  min-height: 30px;
  min-width: 30px;
`;

export const ShowPasswordStyle = styled.a`
  display: flex;
  flex-direction: column;
  height: 35px;
  width: 35px;
  margin-left: 6px;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;



// TextInput Component Style

export const TextAlignStyle = css`
  text-align: center;
`;

export const PaddingLeftStyle = css`
  padding-left: 10px;
`;

export const PaddingRightStyle = css`
  padding-right: 10px;
`;

export const AllPaddingLeftStyle = css`
  ${PaddingLeftStyle};
  ${PaddingRightStyle};
`;

export const TextInputStyle = styled.input`
  display: flex;
  flex: 1;
  width: 100%;
  background-color: transparent;
  font-size: 13px;
  border: none;

  text-decoration-color: transparent;

  outline: none;

  ${(props: IProps) => !props.paddingLeft && !props.paddingRight && AllPaddingLeftStyle};
  ${(props: IProps) => props.paddingLeft && PaddingLeftStyle};
  ${(props: IProps) => props.paddingRight && PaddingRightStyle};

  ${(props: IProps) => props.inputTextCenter && TextAlignStyle};

  ::placeholder {
    color: ${(props: IProps) => props.placeholderTextColor || Colors.textColor};
  }
`;



// Dropdown

export const DropdownTextStyle = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: 35px;
  color: ${Colors.attentionDark};
  font-size: 14px;
`;

export const DropdownSelect = styled(Dropdown)`
  width: 100%;
  height: 100%;
  
  .btn-primary {
    border: none;
    color: ${Colors.black};
    background-color: ${(props: IProps) => props.backgroundColor || Colors.white} !important;
    font-size: 14px;
    font-family: "Nunito Sans", sans-serif !important;
    text-align: left;

    :focus{
      color: ${Colors.black};
      box-shadow: none !important;
    }
  }
  
  #dropdown-basic {
    width: 100%;
    height: 100%;
    border-radius: 0;

    :after{
      display: none;
    }
  }

  .dropdown-menu {
    width: 100%;
    margin-top: 3px;
    padding-top: 0;
    padding-bottom: 0;
    background-color: ${Colors.white};
    border-color: ${(props: IProps) => props.borderColor || Colors.grayLight};
    border-style: solid;
    border-width: ${(props: IProps) => props.borderWidth || 1}px;
    border-radius: 0;
    ${(props: IProps) => !props.noShadow && ShadowStyle};

    a {
      height: 30px;

      :hover {
        color: #1e2125;
        background-color: #e9ecef;
      }
    }

    a:nth-of-type(even) {
      background-color: ${Colors.primaryThin};
    }
  }
`;

export const SelectArrowView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute !important;
  right: 8px;
  width: 48px;
  height: ${Sizes.inputHeight}px;
  pointer-events: none;
`;



// Help Contents

export const HelpContainerViewStyle = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 2px;
`;

export const HelpTextViewStyle = styled.div`
  display: flex;
  flex: 1;
`;

export const HelpTextComponentStyle = styled.p`
  display: flex;
  flex: 1;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
  font-style: italic;
`;

export const HelpCountTextStyle = styled.p`
  margin-top: 0;
  margin-left: 5px;
  margin-bottom: 0;
  font-size: 12px;
`;
