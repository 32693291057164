const UsuarioFiltroModel = {
  name: '',
  email: '',
  tipoPerfil: null,
  clienteEmpresa: null,
  isAtivo: null,
};



export default UsuarioFiltroModel;
