const CategoriasMateriaisFormModel = {
  id: null,
  nome: '',
  useRdc: false,
  isAtivo: 0,
};



export default CategoriasMateriaisFormModel;
