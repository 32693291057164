import React,
{
  useRef,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
  useLocation,
} from 'react-router-dom';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  PostResetPassword,
} from '../../../../apis/endpoints/access-password.endpoints';

import {
  EditarUsuario,
} from '../../../../apis/endpoints/usuarios.endpoints';

import Content from '../../../../components/Content';

import {
  ButtonBack,
} from '../../../../components/Controls';

import {
  FormButtonAction,
  FormContainerPadder,
  FormContainerView,
  FormInputsContainer,
  FormRowEndButtonsContainer,
} from '../../../../components/Filter/filter-content';

import {
  IconBack,
  IconCheck,
} from '../../../../components/Icons';

import InputText from '../../../../components/Inputs/InputText';

import {
  IToast,
} from '../../../../components/IToast';

import Screen from '../../../../components/Screen';
import RequiredText from '../../../../components/TextRequired';
import Title from '../../../../components/Title';
import Toolbar from '../../../../components/Toolbar';
import NameRoutes from '../../../../navigation/names';

import {
  Colors,
  Values,
} from '../../../../shared/constants';

import PasswordChangeModel from '../../../../shared/models/Senha/senha-trocar.model';

import PassChangeNoUserValidationSchema from '../../../../shared/validations/Senha/senha-trocar-sem-user.validation';
import PassChangeUserValidationSchema from '../../../../shared/validations/Senha/senha-trocar-user.validation';

import {
  RootState,
} from '../../../../store';

import {
  APP_MODAL_LOADING_ACTION,
} from '../../../../store/reducers/app.store';

import {
  SettingsHelpText,
  TextChangePassword,
  SettingsHelpSubText,
} from './styled';



export interface IRouteParams {
  oldPassword: boolean;
  cpf: any;
  code: any;
}



const PasswordChangeScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const routeLocation = useLocation<IRouteParams>();
  const params = routeLocation?.state;


  const user = useSelector((state: RootState) => state.user.actualUser);


  const showOldPassword = params?.oldPassword;

  const formik = useRef<FormikProps<typeof PasswordChangeModel>>(null);

  const [loading, setLoading] = useState<boolean>(false);



  function resetPasswordForm() {
    if (formik.current) {
      formik.current?.resetForm();
    }
  }


  async function changeValues(values: typeof PasswordChangeModel) {
    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      let response: any;

      if (showOldPassword) {
        response = await EditarUsuario(user?.id, values);
      }
      else {
        response = await PostResetPassword(params.cpf, params.code, values.password);
      }

      if (response) {
        IToast({
          type: 'success',
          message: 'Atualização realizada com sucesso',
        });
        resetPasswordForm();

        if (showOldPassword) {
          history.goBack();
        }
        else {
          history.push(NameRoutes.LoginScreen);
        }
      }
      else {
        IToast({
          type: 'error',
          message: 'Erro ao atualizar',
        });
      }
    }
    catch (error: any) {
      console.error(error);

      IToast({
        type: 'error',
        message: 'Erro interno',
      });
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  return (

    <Formik
      enableReinitialize
      validateOnMount={false}
      innerRef={formik}
      initialValues={PasswordChangeModel}
      validationSchema={showOldPassword ? PassChangeUserValidationSchema : PassChangeNoUserValidationSchema}
      onSubmit={(values) => {
        // KeyboardModal.dismiss();
        changeValues(values);
      }}>
      {({
        dirty,
        isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
      }) => (

        <Screen
          backgroundColor={Colors.appBackground}>

          <Toolbar
            backgroundColor={Colors.toolbarBackground}
            leftIcon={
              <ButtonBack
                color={Colors.black}
              />
            }
            centerContent={
              <Title
                color={Colors.tertiary}>
                {'Alterar senha'}
              </Title>
            }
          />


          <Content>
            <FormContainerPadder>
              <FormContainerView>

                <FormInputsContainer>

                  <SettingsHelpText>
                    {showOldPassword
                      ? 'Insira sua senha atual para que possamos confirmar que é você, logo depois insira e confirme sua nova senha'
                      : 'Insira e confirme sua nova senha, é a partir dela que você poderá acessar a sua conta'
                    }
                  </SettingsHelpText>


                  {showOldPassword && (
                    <SettingsHelpSubText>
                      {'Caso não lembre sua senha atual você precisará'}
                      {' '}
                      <TextChangePassword
                        onClick={() => {
                          history.replace(NameRoutes.RecoveryPasswordScreen);
                        }}>
                        {'redefini-la'}.
                      </TextChangePassword>
                    </SettingsHelpSubText>
                  )}



                  <RequiredText>
                    * {'Campos obrigatórios'}
                  </RequiredText>



                  {showOldPassword && (
                    <InputText
                      password
                      disabled={loading}
                      autoCorrect={false}
                      type={'PASSWORD'}
                      labelText={`${'Senha atual'} *`}
                      placeholderText={'Senha atual'}
                      helpText={errors.oldPassword}
                      countLimit={Values.passwordMaxCount}
                      value={values.oldPassword}
                      onChange={handleChange('oldPassword')}
                      onBlur={handleBlur('oldPassword')}
                    />
                  )}


                  <InputText
                    password
                    disabled={loading}
                    autoCorrect={false}
                    type={'PASSWORD'}
                    labelText={`${'Nova senha'} *`}
                    placeholderText={'Nova senha'}
                    helpText={errors.password}
                    countLimit={Values.passwordMaxCount}
                    value={values.password}
                    onChange={handleChange('password')}
                    onBlur={handleBlur('password')}
                  />


                  <InputText
                    password
                    disabled={loading}
                    autoCorrect={false}
                    type={'PASSWORD'}
                    labelText={`${'Repita a nova senha'} *`}
                    placeholderText={'Repita a nova senha'}
                    helpText={errors.repassword}
                    countLimit={Values.passwordMaxCount}
                    value={values.repassword}
                    onChange={handleChange('repassword')}
                    onBlur={handleBlur('repassword')}
                  />

                </FormInputsContainer>



                <FormRowEndButtonsContainer>

                  <FormButtonAction
                    outline
                    disabled={loading}
                    iconLeft={
                      <IconBack
                        color={Colors.primaryMedium}
                        size={16}
                      />
                    }
                    title={'Voltar'}
                    backgroundColor={Colors.primaryMedium}
                    onPress={() => {
                      history.goBack();
                    }}
                  />



                  <FormButtonAction
                    disabled={!dirty || !isValid || loading}
                    iconLeft={
                      <IconCheck
                        color={Colors.white}
                        size={16}
                      />
                    }
                    title={'Salvar'}
                    backgroundColor={Colors.primaryMedium}
                    onPress={() => {
                      handleSubmit();
                    }}
                  />

                </FormRowEndButtonsContainer>

              </FormContainerView>
            </FormContainerPadder>
          </Content>

        </Screen>

      )}
    </Formik>

  );
};



export default PasswordChangeScreen;
