enum ETokenAsync {
  USER_TOKEN = 'KWS-TOKEN',
  USER_ID = 'KWS-ID',
  USER_CLIENT_NUMBER = 'KWS-CLIENT-NUMBER',
  USER_DATA = 'KWS-USER',
}



export default ETokenAsync;
