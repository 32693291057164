import ApiWS from '../index';



export const GetAllInputFuncoesColaboradores = async (payload?: any) => {
  const url = '/app/funcoes-colaboradores/listar-tudo';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const GetFuncoesColaboradoresListAll = async (payload?: any) => {
  const url = '/app/funcoes-colaboradores/buscar';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PostCreateFuncoesColaboradores = async (payload: any) => {
  const url = '/app/funcoes-colaboradores/criar';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PutEditFuncoesColaboradores = async (idFuncaoColaboradores: string, payload: any) => {
  const url = `/app/funcoes-colaboradores/editar/${idFuncaoColaboradores}`;

  const response = await ApiWS.put(url, payload);
  return response?.data;
};



export const DeleteFuncoesColaboradores = async (idFuncaoColaboradores: string) => {
  const url = `/app/funcoes-colaboradores/deletar/${idFuncaoColaboradores}`;

  const response = await ApiWS.delete(url);
  return response?.data;
};
