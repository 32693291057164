import ApiWS from '../index';



export const GetUserListAll = async (payload?: any) => {
  const url = '/app/usuarios/buscar';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PostCreateUser = async (payload: any) => {
  const url = '/app/usuarios/criar';

  const response = await ApiWS.post(url, payload);
  return response?.data;
};



export const PutEditUser = async (idUser: string, payload: any) => {
  const url = `/app/usuarios/editar/${idUser}`;

  const response = await ApiWS.put(url, payload);
  return response?.data;
};



export const DeleteUser = async (idUser: string) => {
  const url = `/app/usuarios/deletar/${idUser}`;

  const response = await ApiWS.delete(url);
  return response?.data;
};
