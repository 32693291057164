import styled from 'styled-components';

import ButtonApp from '../../../components/Buttons/ButtonApp';



export const ActionButtonApp = styled(ButtonApp)`
  height: 100%;
  width: 240px;
  max-width: 240px;
`;

export const FilterButtonApp = styled(ButtonApp)`
  margin-left: 10px;
  height: 100%;
  width: 140px;
  max-width: 140px;
`;
